import { FunctionComponent, useEffect, useState } from "react";
import { useFormik } from "formik";
import * as yup from "yup";
import Project from "../interfaces/Project";
import "../css/newProject.css";
import { useNavigate } from "react-router-dom";
import { addProject } from "../services/projectServices";
import { successMsg } from "../services/feedbacks";
import { getManagerById, getManagerProfile, updateManager } from "../services/managerServices";
import Manager from "../interfaces/Manager";
import ListOfManagers from "./ListOfManagers";
import ListOfManagerByMonth from "./ListOfManagerByMonth";

interface NewProjectProps {

}

const NewProject: FunctionComponent<NewProjectProps> = () => {
    const navigate = useNavigate();
    const [change, setChange] = useState<boolean>(false);
    const [list, setList] = useState<number>(1);
    const [postId, setPostId] = useState<string>("");
    const [postName, setPostName] = useState<string>("");
    const [manager, setManager] = useState<Manager | null>(null);
    const [selectedManager, setSelectedManager] = useState<Manager | null>(null);
    const [projectsInfo, setProjectsInfo] = useState<{ [key: string]: string }>({});
    const [selectedShotef, setSelectedShotef] = useState<number | null>(null);


    const handleAddClick = (selectedManager: Manager) => {
        setPostName(selectedManager.name as string);
        setPostId(selectedManager.id as string);
        setSelectedManager(selectedManager);
        setChange(!change);
    };

    const handleNewProjectClick = () => {
        if (selectedManager) {
            if (selectedManager.projectName) {
                updateManagerWithNewProject(postId, formik.values.name, 'nextProject');
            } else {
                updateManagerWithNewProject(postId, formik.values.name, 'projectName');
                addProjectAndNavigate();
            }
        }
    };

    const addProjectAndNavigate = () => {
        addProject({
            ...formik.values,
            mainProducer: manager?.name as string,
            postName: postName,
            postId: postId,
            avgWage: "0",
            currentEp: 0,
            totalEditShifts: 0,
            customItems: [],
            shotef: selectedShotef as number
        })
            .then((res) => {
                navigate("/mainDashboard");
                successMsg("Project added successfully");
                setProjectsInfo((prevProjectsInfo) => ({
                    ...prevProjectsInfo,
                    [postId]: formik.values.name,
                }));
            })
            .catch((err) => console.log(err));
    };

    const updateManagerWithNewProject = (postId: string, projectName: string, fieldToUpdate: 'nextProject' | 'projectName') => {
        getManagerById(postId)
            .then((res) => {
                const updatedManager: Manager = { ...res.data, status: "לא פנוי/ה" };

                if (fieldToUpdate === 'projectName' && !updatedManager.projectName) {
                    updatedManager[fieldToUpdate] = projectName;
                    updateManager(updatedManager)
                        .then((res) => {
                            setChange(!change);
                            setProjectsInfo((prevProjectsInfo) => ({
                                ...prevProjectsInfo,
                                [postId]: projectName,
                            }));
                        })
                        .catch((error) => console.log(error));
                } else if (fieldToUpdate === 'nextProject' && updatedManager.projectName) {
                    updatedManager[fieldToUpdate] = projectName;
                    updateManager(updatedManager)
                        .then((res) => {
                            setChange(!change);
                            // עדכון המידע בסטייט באופן המתאים
                            setProjectsInfo((prevProjectsInfo) => ({
                                ...prevProjectsInfo,
                                [postId]: projectName,
                            }));
                        })
                        .catch((error) => console.log(error));
                }
            })
            .catch((error) => console.log(error));
    };

    const formik = useFormik({
        initialValues: { name: "", mainProducer: manager?.name as string, postName: "", startDate: "", endDate: "", episodes: 0, shiftsForEp: 0, budget: 0, postId: "" },
        validationSchema: yup.object({
            name: yup.string().required().min(2),
            mainProducer: yup.string(),
            postName: yup.string(),
            postId: yup.string(),
            startDate: yup.string().required(),
            endDate: yup.string().required(),
            episodes: yup.number().required(),
            budget: yup.number().required(),
            avgWage: yup.string(),
            currentEp: yup.number(),
            totalEditShifts: yup.number(),
            shiftsForEp: yup.number(),
        }),
        onSubmit: (values: Project) => {
            const proName = values.name;
            addProjectAndNavigate();
            updateManagerWithNewProject(postId, proName, 'projectName');
        },
    });

    useEffect(() => {
        getManagerProfile()
            .then((res) => {
                setManager(res.data);
            })
            .catch((error) => console.log(error));
    }, [change]);

    const refresh = () => {
        setChange(!change);
    };

    return (
        <div className="newProjectPanel">
            <div className="row newProjectHeadline">
                <img src="/Monica_Logo@0.25x.png" alt="Logo" style={{ height: '60px', width: '200px' }} />
                <h1>פרויקט חדש</h1>
            </div>
            <div className="row">
                <div className="col-1"></div>
                <div className="form col-8 col-md-8 col-lg-6 mt-4">
                    <form onSubmit={formik.handleSubmit}>
                        <div className="form-floating row mb-3">
                            <div className="col-4 pt-2">
                                <label htmlFor="floatingName">שם פרויקט:</label>
                                {formik.touched.name && formik.errors.name && (
                                    <small className="text-danger">{formik.errors.name}</small>
                                )}
                            </div>
                            <div className="col-8">
                                <input type="text" className="form-control" id="floatingName" name="name"
                                    value={formik.values.name}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                />
                            </div>
                        </div>
                        <div className="form-floating row mb-3">
                            <div className="col-4 pt-2">
                                <label htmlFor="floatingPost">מפיק פוסט:</label>
                            </div>
                            <div className="col-8">
                                <input type="text" className="form-control" id="floatingPost" name="postName"
                                    value={postName}
                                />
                            </div>
                        </div>
                        <div className="form-floating row mb-3">
                            <div className="col-4 pt-2">
                                <label htmlFor="floatingStartDate">תאריך התחלה:</label>
                                {formik.touched.startDate && formik.errors.startDate && (
                                    <small className="text-danger">{formik.errors.startDate}</small>
                                )}
                            </div>
                            <div className="col-8">
                                <input type="date" className="form-control" id="floatingStartDate" name="startDate"
                                    value={formik.values.startDate}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                />
                            </div>
                        </div>
                        <div className="form-floating row mb-3">
                            <div className="col-4 pt-2">
                                <label htmlFor="floatingEndDate">תאריך סיום:</label>
                                {formik.touched.endDate && formik.errors.endDate && (
                                    <small className="text-danger">{formik.errors.endDate}</small>
                                )}
                            </div>
                            <div className="col-8">
                                <input type="date" className="form-control" id="floatingEndDate" name="endDate"
                                    value={formik.values.endDate}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                />
                            </div>
                        </div>
                        <div className="form-floating row mb-3">
                            <div className="col-4 pt-2">
                                <label htmlFor="floatingEpisodes">מספר פרקים:</label>
                                {formik.touched.episodes && formik.errors.episodes && (
                                    <small className="text-danger">{formik.errors.episodes}</small>
                                )}
                            </div>
                            <div className="col-8">
                                <input type="number" className="form-control" id="floatingEpisodes" name="episodes"
                                    value={formik.values.episodes}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                />
                            </div>
                        </div>
                        <div className="form-floating row mb-3">
                            <div className="col-4 pt-2">
                                <label htmlFor="floatingEpisodes">צפי משמרות לפרק:</label>
                                {formik.touched.shiftsForEp && formik.errors.shiftsForEp && (
                                    <small className="text-danger">{formik.errors.shiftsForEp}</small>
                                )}
                            </div>
                            <div className="col-8">
                                <input type="number" className="form-control" id="floatingShiftsForEp" name="shiftsForEp"
                                    value={formik.values.shiftsForEp}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                />
                            </div>
                        </div>
                        <div className="form-floating row mb-3">
                            <div className="col-4 pt-2">
                                <label htmlFor="floatingBudget">תקציב משמרות עריכה:</label>
                                {formik.touched.budget && formik.errors.budget && (
                                    <small className="text-danger">{formik.errors.budget}</small>
                                )}
                            </div>
                            <div className="col-8">
                                <input type="text" className="form-control" id="floatingBudget" name="budget"
                                    value={formik.values.budget}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                />
                            </div>
                        </div>
                        <div className="form-floating row">
                            <div className="col-4 pt-2">
                                <label htmlFor="floatingShotef">שוטף:</label>
                                {formik.touched.shotef && formik.errors.shotef && (
                                    <small className="text-danger">{formik.errors.shotef}</small>
                                )}
                            </div>
                            <div className="col-8">
                                <select
                                    className="form-select"
                                    id="floatingShotef"
                                    name="shotef"
                                    value={selectedShotef || ''}
                                    onChange={(e) => {
                                        setSelectedShotef(parseInt(e.target.value, 10));
                                        formik.handleChange(e);
                                    }}
                                    onBlur={formik.handleBlur}
                                >
                                    <option value="" selected>
                                        בחר/י
                                    </option>
                                    <option value={15}>15</option>
                                    <option value={30}>30</option>
                                    <option value={45}>45</option>
                                    <option value={60}>60</option>
                                    <option value={90}>90</option>
                                </select>
                            </div>
                        </div>
                        <button
                            type="submit"
                            disabled={!formik.isValid || !formik.dirty}
                            className="btn btn-primary my-3 w-50 mb-2"
                            onClick={handleNewProjectClick}
                        >
                            פרויקט חדש
                        </button>
                    </form>
                </div>
            </div>
            <div className="w-100 space"></div>
            <div className="w-100 space"></div>
            <div className="row mt-4">
                <h2>מאגר מפיקים</h2>
                <div className="row mx-auto col-10 col-md-8 col-lg-6 mt-2 mb-2">
                    <div className="">
                        <button className="btn btn-outline-success mx-2" onClick={() => {
                            setChange(!change);
                            setList(1);
                        }} >פנויים</button>
                        <button className="btn btn-outline-warning mx-2" onClick={() => {
                            setChange(!change);
                            setList(2);
                        }} > לפי חודש</button>
                    </div>
                </div>
                {list === 1 && <ListOfManagers refresh={refresh} onAddClick={handleAddClick} />}
                {list === 2 && <ListOfManagerByMonth refresh={refresh} onAddClick={handleAddClick} />}
            </div>
            <div className="div my-5"></div>
            <div className="row newProjectOptions mx-auto col-10 col-md-8 col-lg-6">
                <p onClick={() => navigate(-1)} style={{
                    color: 'blue',
                    textDecoration: 'underline',
                    cursor: 'pointer',
                }}>חזור</p>
                <p style={{ color: 'lightgray' }}>Almog Liebermensch 2024 ©</p>
            </div>
        </div>
    );
}

export default NewProject;
import { FunctionComponent } from "react";
import { VictoryPie } from "victory-pie";
import "../css/pieChart.css"
import Project from "../interfaces/Project";

interface PieChartProps {
    head: string;
    projects: Project[];
}

const PieChart: FunctionComponent<PieChartProps> = ({ head, projects }) => {

    const headline = head;

    const shiftsSum = function (project: Project) {
        if (typeof project?.episodes !== 'number' || typeof project?.currentEp !== 'number' || typeof project?.shiftsForEp !== 'number' || typeof project?.totalEditShifts !== 'number') {
            return 0; // אם לפחות אחד מהערכים אינו מספר, נחזיר 0
        }
        let rate = 0;
        if (project.name === "רוקדים עם כוכבים") {
            rate = 16;
        } else if (project.name === "מאסטר שף") {
            rate = 14;
        }
        let sum;
        sum = (project?.shiftsForEp - (rate / project?.currentEp)) * project?.episodes;
        return (typeof sum === 'number' && isFinite(sum)) ? Math.round(sum) : 0;
    };

    const shiftsSumT = function (project: Project) {
        if (typeof project?.episodes !== 'number' || typeof project?.currentEp !== 'number' || typeof project?.shiftsForEp !== 'number' || typeof project?.totalEditShifts !== 'number') {
            return 0; // אם לפחות אחד מהערכים אינו מספר, נחזיר 0
        }

        let sum;
        sum = (project?.shiftsForEp - (project?.totalEditShifts / project?.currentEp)) * project?.episodes;

        // בדיקה האם הסכום הוא מספר שלם וגם סיימות תקינה
        return (typeof sum === 'number' && isFinite(sum)) ? Math.round(Math.abs(sum)) : 0;
    };

    const myData = projects.map(project => ({
        x: shiftsSum(project as Project),
        y: shiftsSumT(project as Project),
    }));

    const colors = ["#5DADE2", "#F4D03F", "#D98880", "#76448A", "#16A085", "#D63484", "#3B3486", "#FF9800", "#7D0A0A", "#C1F2B0"];
    const projectData = projects.map((project, index) => ({
        name: project.name,
        color: colors[index % colors.length],
    }));

    return (
        <div className="piechart">
            <h3 className="headline">{headline}</h3>
            <VictoryPie
                data={myData}
                colorScale={["#5DADE2", "#F4D03F", "#D98880", "#76448A", "#16A085", "#D63484", "#3B3486", "#FF9800", "#7D0A0A", "#C1F2B0"]}
                radius={150}
                style={{ labels: { fontSize: 16 } }}
            />
            <table className="namesAndColors table-sm">
                <tbody>
                    {projectData.map((data, index) => (
                        (index % 2 === 0) && (
                            <tr key={index}>
                                <td><span className="color badge" style={{ backgroundColor: data.color }}> </span> {data.name}</td>
                                {(index + 1 < projectData.length) && (
                                    <td><span className="color badge" style={{ backgroundColor: projectData[index + 1].color }}> </span> {projectData[index + 1].name}</td>
                                )}
                            </tr>
                        )
                    ))}
                </tbody>
            </table>
        </div>
    );
}

export default PieChart;


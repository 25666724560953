import { FunctionComponent, useEffect, useState } from "react";
import "../css/mainDashboard.css";
import { Link, useNavigate } from "react-router-dom";
import { successMsg } from "../services/feedbacks";
import Manager from "../interfaces/Manager";
import { getMainName, getManagerProfile, getProjectByMain } from "../services/managerServices";
import Project from "../interfaces/Project";
import PieChart from "./PieChart";
import PieChart2 from "./PieChart2";

interface MainDashboardProps {

}

const MainDashboard: FunctionComponent<MainDashboardProps> = () => {
    let navigate = useNavigate();
    let [manager, setManager] = useState<Manager | null>(null);
    let [projects, setProjects] = useState<Project[]>([]);
    let h1 = "צפי רווח/חריגה במשמרות עד סיום פרויקט";
    let h2 = "צפי רווח/חריגה בתקציב עד סיום פרויקט";

    useEffect(() => {
        const fetchData = async () => {
            try {
                const managerResponse = await getManagerProfile();
                setManager(managerResponse.data);

                const projectsResponse = await getProjectByMain(getMainName());
                setProjects(projectsResponse.data);
            } catch (error) {
                console.error(error);
            }
        };
        fetchData();
    }, []);

    const sumVal = function (shiftsForEp: number, episodes: number) {
        return shiftsForEp * episodes;
    };
    const sumBudgetSoFar = function (project: Project) {
        let rate = 0;
        if (project.name === "רוקדים עם כוכבים") {
            rate = 16;
        } else if (project.name === "מאסטר שף") {
            rate = 14;
        }
        return Math.round(rate * (parseFloat(project?.avgWage as string) || 0) * 10);
    };

    const sumBudget = function (project: Project) {
        let rate = 0;
        if (project.name === "רוקדים עם כוכבים") {
            rate = 16;
        } else if (project.name === "מאסטר שף") {
            rate = 14;
        }
        return Math.round(rate * (project?.episodes ?? 0) * (parseFloat(project?.avgWage as string) || 0) * 10);
    };
    const shiftsSum = function (project: Project) {
        if (typeof project?.episodes !== 'number' || typeof project?.currentEp !== 'number' || typeof project?.shiftsForEp !== 'number' || typeof project?.totalEditShifts !== 'number') {
            return 0; // אם לפחות אחד מהערכים אינו מספר, נחזיר 0
        }
        let rate = 0;
        if (project.name === "רוקדים עם כוכבים") {
            rate = 16;
        } else if (project.name === "מאסטר שף") {
            rate = 14;
        }
        let sum;
        sum = (project?.shiftsForEp - (rate / project?.currentEp)) * project?.episodes;
        return (typeof sum === 'number' && isFinite(sum)) ? Math.round(sum) : 0;
    };
    const budgetSum = function (project: Project) {
        const sum = (shiftsSum(project as Project)) * (parseFloat(project.avgWage as string) || 0) * 10;
        return Math.round(sum);
    };
    const fixedEpisodes = function (totalEditShifts: number, shiftsForEp: number, episodes: number, currentEp: number, project: Project) {
        let rate = 0;
        if (project.name === "רוקדים עם כוכבים") {
            rate = 16;
        } else if (project.name === "מאסטר שף") {
            rate = 14;
        }
        let totalShifts = sumVal(shiftsForEp as number, episodes as number);
        let sum;
        sum = (totalShifts - rate) / (episodes - currentEp);
        return parseFloat(sum.toFixed(2)); // החזרת התוצאה עם שני ספרות אחרי הנקודה העשרונית
    };

    const formatDate = (dateString: string) => {
        const date = new Date(dateString);
        const day = date.getDate();
        const month = date.getMonth() + 1;
        const year = date.getFullYear();
        const formattedDate = `${day}/${month}/${year}`;

        return formattedDate;
    };


    return (<>
        <div className="mainDbPanel">
            <div className="row mainDbHeadline">
                <img src="/Monica_Logo@0.25x.png" alt="Logo" style={{ height: '60px', width: '200px' }} />
                <h1>שלום {manager?.name}</h1>
            </div>
            <div className="row">
                <div className="col-2 mx-3">
                    <div className="row">
                        <table className="table table-striped mt-2">
                            <thead className="thead-dark">
                                <tr className="table-primary">
                                    <th scope="col">פרויקטים</th>
                                    <th scope="col">מפיק פוסט</th>
                                </tr>
                            </thead>
                            {projects.length ? (
                                <tbody className="">
                                    {projects.map((project: Project) => (
                                        <tr key={project.name}>
                                            <td> <Link to={`/projectDashboard/${project.name}`}>
                                                {project.name}
                                            </Link></td>
                                            <td>{project.postName}</td>
                                        </tr>
                                    ))}
                                </tbody>
                            ) : (
                                <p> אין פרויקטים </p>
                            )}
                        </table>
                    </div>
                    <div className="row mb-1">
                        <Link to="/newProject"><button className="btn btn-primary w-100">פרויקט חדש</button></Link>
                    </div>
                    <div className="row">
                        <Link to="/"><button className="btn btn-danger w-100"
                            onClick={() => {
                                navigate("/");
                                sessionStorage.removeItem("userData");
                                successMsg("(: נתראה בקרוב");
                            }}
                        >יציאה מהמערכת</button></Link>
                    </div>
                </div>
                <div className="col-4 mx-5 mt-3">
                    <div className="shifts" style={{ overflowY: "auto", maxHeight: "600px" }}>
                        <PieChart head={h1} projects={projects} />
                    </div>
                </div>
                <div className="col-4 mx-5 mt-3">
                    <div className="shifts" style={{ overflowY: "auto", maxHeight: "600px" }}>
                        <PieChart2 head={h2} projects={projects} />

                    </div>
                </div>
            </div>
            <div className="row">
                <div className="mx-auto col-12 col-md-8 col-lg-6 w-100">
                    <table className="table table-striped mt-4">
                        <thead className="thead-dark">
                            <tr className="table-primary">
                                <th scope="col">פרויקט</th>
                                <th scope="col">סה״כ משמרות</th>
                                <th scope="col">מספר פרקים</th>
                                <th scope="col">מספר משמרות לפרק</th>
                                <th scope="col">צפי משמרות מתוקן</th>
                                <th scope="col">פרק שודר</th>
                                <th scope="col">סה״כ משמרות בפועל</th>
                                <th scope="col">תקציב עבודה</th>
                                <th scope="col">תקציב עבודה בפועל</th>
                                <th scope="col">צפי רווח/חריגה במש׳ עד סוף הפרויקט</th>
                                <th scope="col">צפי רווח/חריגה בתקציב עד סוף הפרויקט</th>
                                <th scope="col">תאריך סיום</th>
                            </tr>
                        </thead>
                        {projects.length ? (
                            <tbody>
                                {projects.map((project: Project) => (
                                    <tr key={project.name}>
                                        <td>{project.name}</td>
                                        <td>{sumVal(project.shiftsForEp as number, project.episodes as number)}</td>
                                        <td>{project.episodes}</td>
                                        <td>{project.shiftsForEp}</td>
                                        <td>{fixedEpisodes(project.totalEditShifts as number, project.shiftsForEp as number, project.episodes as number, project.currentEp as number, project as Project)}</td>
                                        {/* <td>{project.currentEp}</td> */}
                                        <td>1</td>
                                        <td>{project.name === "רוקדים עם כוכבים" ? 16 : project.name === "מאסטר שף" ? 14 : 0}</td>
                                        <td>{sumBudget(project as Project)}</td>
                                        <td>{sumBudgetSoFar(project as Project)}</td>
                                        <td style={{
                                            color: shiftsSum(project as Project) < 0 ? '#fc2d2d' : '#19cf22', unicodeBidi: 'plaintext', fontSize: "1.1rem"
                                        }}>
                                            <b>{shiftsSum(project as Project)}</b>
                                        </td>
                                        <td style={{
                                            color: budgetSum(project as Project) < 0 ? '#fc2d2d' : '#19cf22', unicodeBidi: 'plaintext', fontSize: "1.1rem"
                                        }}>
                                            <b>{budgetSum(project as Project)}</b>
                                        </td>
                                        <td>{formatDate(project.endDate as string)}</td>
                                    </tr>
                                ))}
                            </tbody>
                        ) : (
                            <p> אין פרויקטים </p>
                        )}
                    </table>
                </div>
            </div>
        </div>

    </>);
}

export default MainDashboard;
import { FunctionComponent, useEffect, useState } from "react";
import "../css/mainDashboard.css";
import { Link, useNavigate } from "react-router-dom";
import { successMsg } from "../services/feedbacks";
import User from "../interfaces/User";
import { getUserProfile, updateUser } from "../services/userServices";
interface EditorDashboardProps {

}

const EditorDashboard: FunctionComponent<EditorDashboardProps> = () => {
    const navigate = useNavigate();
    const [user, setUser] = useState<User>({
        username: "",
        password: "",
        avilabaleNow: "",
    });
    const [selectedDays, setSelectedDays] = useState<string[]>([]);

    const daysOfWeek = ["ראשון", "שני", "שלישי", "רביעי", "חמישי", "שישי", "שבת"];

    const handleCheckboxChange = (day: string) => {
        if (selectedDays.includes(day)) {
            setSelectedDays(selectedDays.filter((selectedDay) => selectedDay !== day));
        } else {
            setSelectedDays([...selectedDays, day]);
        }
    };

    const handleSaveClick = () => {
        const selectedDaysString = selectedDays.join(", ");
        updateUser({ ...user, avilabaleNow: selectedDaysString });
        return selectedDaysString;
    };

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await getUserProfile();
                setUser(response.data);
                if (response.data.avilabaleNow) {
                    const selectedDays = response.data.avilabaleNow.split(", ");
                    setSelectedDays(selectedDays);
                }
            } catch (error) {
                console.log(error);
            }
        };

        fetchData();
    }, []);


    return (<>
        <div className="panel">
            <div className="row mainDbHeadline">
                <img src="/Monica_Logo@0.25x.png" alt="Logo" style={{ height: '60px', width: '200px' }} />
                <h1>שלום {user?.name}</h1>
            </div>
            <div className="mx-auto col-10 col-md-8 col-lg-6 m-4">
                <div className="row mt-2 mb-3">
                    <div className="col">
                        <span className="postBadge badge rounded-pill w-100 py-3">{user?.name}</span>
                    </div>
                    <div className="col">
                        <span className="postBadge badge rounded-pill w-100 py-3">{user?.position}</span>
                    </div>
                </div>
                <div className="row mt-2 mb-3">
                    <div className="col">
                        <span className="postBadge badge rounded-pill w-100 py-3">פרויקט ראשי: {user.projectName}</span>
                    </div>
                    <div className="col">
                        <span className="postBadge badge rounded-pill w-100 py-3">פרויקט משני: {user.secProject}</span>
                    </div>
                </div>
                <div className="userInfo">
                    <div className="row mb-5 mt-5">
                        <div className="col-10 mx-auto">
                            <h3>זמינות שבועית:</h3>
                            <table className="table mt-4">
                                <thead className="table-primary">
                                    <tr>
                                        {daysOfWeek.map((day, index) => (
                                            <th key={index}>{day}</th>
                                        ))}
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        {daysOfWeek.map((day, index) => (
                                            <td key={index}>
                                                <input
                                                    type="checkbox"
                                                    checked={selectedDays.includes(day)}
                                                    onChange={() => handleCheckboxChange(day)}
                                                />
                                            </td>
                                        ))}
                                    </tr>
                                </tbody>
                            </table>
                            <button className="btn btn-outline-primary w-25 mt-3" onClick={() => {
                                user.avilabaleNow = handleSaveClick();
                                updateUser(user);
                                successMsg("!נשמר בהצלחה");
                            }}>שמור</button>
                        </div>
                    </div>
                    <div className="row mb-5">
                        <div className="col-2">
                            <h5>דוח יומי: </h5>
                        </div>
                        <div className="col-1">
                            {user?.dailyReport &&
                                <span className="badge bg-success py-2">דווח</span>}
                            {!user?.dailyReport &&
                                <span className="badge bg-danger py-2">לא דווח</span>}
                        </div>
                    </div>
                </div>
                <div className="row mb-1 mt-5">
                    {user.projectName &&
                        <div className="col">
                            <Link to="/editorSalaryReport"><button className="btn btn-primary w-100"> דוח שכר</button></Link>
                        </div>
                    }
                    {user.projectName &&
                        <div className="col">
                            <Link to="/editorWorkReport"><button className="btn btn-primary w-100">דיווח עבודה</button></Link>
                        </div>
                    }
                </div>
                <div className="row mb-1">
                    <div className="col">
                        <Link to="/updateUser"><button className="btn btn-primary w-100">עדכון פרטים</button></Link>
                    </div>
                    <div className="col">
                        <Link to="/"><button className="btn btn-danger w-100"
                            onClick={() => {
                                navigate("/");
                                sessionStorage.removeItem("userData");
                                successMsg("(: נתראה בקרוב");
                            }}
                        >יציאה מהמערכת</button></Link>
                    </div>
                </div>
            </div>
        </div>
    </>);
}

export default EditorDashboard;
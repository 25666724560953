import { FunctionComponent } from "react";
import { useNavigate } from "react-router-dom";

interface PageNotFoundProps {

}

const PageNotFound: FunctionComponent<PageNotFoundProps> = () => {
    const navigate = useNavigate();

    return (<>
        <h3 className="display-3 mb-5">404 Page Not Found</h3>
        <div className="row mx-auto col-10 col-md-8 col-lg-6 mt-5">
            <p onClick={() => navigate(-1)} style={{
                color: 'blue',
                textDecoration: 'underline',
                cursor: 'pointer',
            }}>חזור</p>
            <p style={{ color: 'lightgray' }}>Almog Liebermensch 2024 ©</p>
        </div>
    </>);
}

export default PageNotFound;
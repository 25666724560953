import { FunctionComponent, useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import Manager from "../interfaces/Manager";
import Project from "../interfaces/Project";
import Report from "../interfaces/Report";
import { getManagerProfile } from "../services/managerServices";
import { getProjectByName } from "../services/projectServices";
import { getReportsByProjectName } from "../services/reportServices";

interface EpisodesProps {

}

const Episodes: FunctionComponent<EpisodesProps> = () => {
    let navigate = useNavigate();
    let [manager, setManager] = useState<Manager | null>(null);
    let [project, setProject] = useState<Project | null>(null);
    let [reports, setReports] = useState<Report[]>([]);
    let [change, setChange] = useState<boolean>(false);

    const { projectName } = useParams();

    useEffect(() => {
        const fetchData = async () => {
            try {
                if (projectName) {
                    const projectData = await getProjectByName(projectName as string);
                    setProject(projectData.data);

                    const reportsResponse = await getReportsByProjectName(projectName as string);
                    setReports(reportsResponse.data);
                }
                else {
                    const managerProfile = await getManagerProfile();
                    setManager(managerProfile.data);

                    const projectData = await getProjectByName(managerProfile.data.projectName as string);
                    setProject(projectData.data);

                    const reportsResponse = await getReportsByProjectName(managerProfile.data.projectName as string);
                    setReports(reportsResponse.data);
                }

            } catch (error) {
                console.error(error);
            }
        };
        fetchData();
    }, [change]);

    const totalNumberOfShifts = (episode: number) => {
        return reports.reduce((count: number, report: Report) => {
            if (report.episode === (episode ?? "").toString()) {
                count++;
            }
            return count;
        }, 0);
    }

    return (
        <div className="passwordPanel">
            <div className="row passwordHeadline">
                <img src="/Monica_Logo@0.25x.png" alt="Logo" style={{ height: '60px', width: '200px' }} />
                <h1>מסך פרקים</h1>
            </div>
            <div className="form mx-auto col-12 col-md-8 col-lg-6 mt-4">
                <div className="row">
                    <div className="col projectName">
                        <span className="postBadge badge rounded-pill w-50 py-3">{project?.name}</span>
                    </div>
                </div>
                <div className="row">
                    <div className="table-responsive" style={{ maxHeight: "650px", overflowY: "auto", width: "50vw" }}>
                        <table className="table table-striped mt-3">
                            <thead className="thead-dark" style={{ position: "sticky", top: 0, zIndex: 1 }}>
                                <tr className="table-info">
                                    <th scope="col">פרק בעבודה</th>
                                    <th scope="col">מס׳ משמרות לפרק</th>
                                    <th scope="col">מס' משמרות בפועל</th>
                                    <th scope="col">יתרה/חריגה</th>
                                </tr>
                            </thead>
                            <tbody style={{ overflowY: "auto" }}>
                                {(Array.from({ length: project?.episodes || 0 }, (_, index) => index)).map((episode: number, index: number) => (
                                    <tr key={index}>
                                        <td style={{ backgroundColor: episode === 0 ? '#19cf22' : '' }}> <Link to={`/episodeScreen/${project?.name}/${episode + 1}`} >{episode + 1}</Link></td>
                                        <td style={{ backgroundColor: episode === 0 ? '#19cf22' : '' }}>{project?.shiftsForEp}</td>
                                        <td style={{ backgroundColor: episode === 0 ? '#19cf22' : '' }}>{totalNumberOfShifts(episode + 1)}</td>
                                        <td style={{ backgroundColor: episode === 0 ? '#19cf22' : '' }}>
                                            <b style={{ direction: 'rtl' }}>
                                                {project && typeof project.shiftsForEp === 'number' && totalNumberOfShifts(episode + 1) >= 0 ? project.shiftsForEp - totalNumberOfShifts(episode + 1) : ''}
                                            </b>
                                        </td>

                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
            <div className="row passwordOptions mx-auto col-10 col-md-8 col-lg-6">
                <p onClick={() => navigate(-1)} style={{
                    color: 'blue',
                    textDecoration: 'underline',
                    cursor: 'pointer',
                }}>חזור</p>
                <p style={{ color: 'lightgray' }}>Almog Liebermensch 2024 ©</p>
            </div>
        </div>
    );
}

export default Episodes;
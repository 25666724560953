import axios from "axios";
import Report from "../interfaces/Report";


const api: string = process.env.REACT_APP_API || "";

// add new Report
export function addReport(newReport: Report) {
    return axios.post(`${api}/reports`, newReport, { headers: { Authorization: JSON.parse(sessionStorage.getItem("userData") as string).token } });
}
// update report

export function updateReport(newDetails: Report) {
    return axios.put(`${api}/reports/${newDetails._id}`, newDetails);
}

// get Reports by name
export function getReportsByName(name: string) {
    return axios.get(`${api}/reports/${name}`, { headers: { Authorization: JSON.parse(sessionStorage.getItem("userData") as string).token } })
}
// get Reports by id
export function getReportById(_id: string) {
    return axios.get(`${api}/reports/${_id}`, { headers: { Authorization: JSON.parse(sessionStorage.getItem("userData") as string).token } })
}
export function getReportsByProjectName(project: string) {
    return axios.get(`${api}/userReports/${project}`, { headers: { Authorization: JSON.parse(sessionStorage.getItem("userData") as string).token } })
}
// get Reports by Project name
export function getReportsByProject(project: string) {
    return axios.get(`${api}/managerReports/${project}`, { headers: { Authorization: JSON.parse(sessionStorage.getItem("userData") as string).token } })
}
// get monthly salary by Project name
export function getMonthlySalaryByProject(project: string, month: string) {
    return axios.get(`${api}/managerReports`, { headers: { Authorization: JSON.parse(sessionStorage.getItem("userData") as string).token } })
}

// delete Report
export function deleteReport(id: string) {
    return axios.delete(`${api}/reports/${id}`, { headers: { Authorization: JSON.parse(sessionStorage.getItem("userData") as string).token } });
}

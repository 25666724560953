import axios from "axios";
import Project from "../interfaces/Project";


const api: string = process.env.REACT_APP_API + "/projects" || "";

// add new Project
export function addProject(newProject: Project) {
    return axios.post(api, newProject, { headers: { Authorization: JSON.parse(sessionStorage.getItem("userData") as string).token } });
}
// get Project by name
export function getProjectByName(name: string) {
    return axios.get(`${api}/${name}`, { headers: { Authorization: JSON.parse(sessionStorage.getItem("userData") as string).token } })
}

// update project
export function updateProject(newDetails: Project) {
    return axios.put(`${api}/${newDetails.name}`, newDetails);
}


// add custom item to project
export function addCustomItemToProject(projectName: string, customItem: { code: number, description: string }) {
    return axios.patch(`${api}/${projectName}/addCustomItem`, customItem, {
        headers: { Authorization: JSON.parse(sessionStorage.getItem("userData") as string).token }
    });
}

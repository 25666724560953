import { FunctionComponent, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Project from "../interfaces/Project";
import { getProjectName } from "../services/userServices";
import { getProjectByName } from "../services/projectServices";
import { format, addDays, parseISO } from 'date-fns';
import { getScheduleByName, updateSchedule } from "../services/scheduleServices";
import Schedule from "../interfaces/Schedule";

interface WorkScheduleProps { }

const WorkSchedule: FunctionComponent<WorkScheduleProps> = () => {
    const navigate = useNavigate();
    let [indexNum, setIndexNum] = useState<number>(0);
    let [project, setProject] = useState<Project | null>(null);
    let [schedule, setSchedule] = useState<Schedule | null>(null);
    let [change, setChange] = useState<boolean>(false);
    let [projectName, setProjectName] = useState<string>("");
    const [editMode, setEditMode] = useState<boolean>(false);
    const [editorValue, setEditorValue] = useState<string>('');
    const [editorNames, setEditorNames] = useState<string[]>([]);
    const [editorModes, setEditorModes] = useState<boolean[]>(new Array(13).fill(false));
    const [selectedCell, setSelectedCell] = useState<number | null>(null);
    const [selectedColor, setSelectedColor] = useState<string>('');
    const [inputValue, setInputValue] = useState<string>('');
    const [startRow, setStartRow] = useState<number | null>(null);
    const [endRow, setEndRow] = useState<number | null>(null);
    const [startCol, setStartCol] = useState<number | null>(null);
    const [endCol, setEndCol] = useState<number | null>(null);
    const [isEditingPanelOpen, setEditingPanelOpen] = useState<boolean>(false);
    const { proName } = useParams();

    const daysOfWeekHebrew = ['א׳', 'ב׳', 'ג׳', 'ד׳', 'ה׳', 'ו׳', 'ש׳'];

    const inputStyles = {
        fontSize: '14px',
        border: '1px solid rgba(0, 0, 0, 0.125)',
        backgroundColor: 'rgba(255, 255, 255, 0.8)',
    };

    const generateDateArray = (startDate: string, endDate: string) => {
        const dateArray = [];
        let currentDate = parseISO(startDate);

        while (currentDate <= parseISO(endDate)) {
            dateArray.push({
                date: format(currentDate, 'dd/MM/yy'),
                day: daysOfWeekHebrew[currentDate.getDay()],
            });
            currentDate = addDays(currentDate, 1);
        }
        return dateArray;
    };

    useEffect(() => {
        const fetchData = async () => {
            try {
                if (proName) {
                    setProjectName(proName);
                    const pro = await getProjectByName(proName);
                    setProject(pro.data);
                    const scheduleResponse = await getScheduleByName(
                        proName as string
                    );
                    setSchedule(scheduleResponse.data);
                }
                else {
                    const name = await getProjectName();
                    setProjectName(name);
                    const projectResponse = await getProjectByName(name);
                    setProject(projectResponse.data);
                    const scheduleResponse = await getScheduleByName(
                        name as string
                    );
                    setSchedule(scheduleResponse.data);
                }
            } catch (error) {
                console.log(error);
            }
        };

        fetchData();
    }, [change]);
    // useEffect(() => {
    //     let name = getProjectName();
    //     setProjectName(name);
    //     getProjectByName(name).then((res) => {
    //         setProject({ ...res.data });
    //     }).catch((error) => console.log(error));
    //     getScheduleByName(name).then((res) => {
    //         setSchedule({ ...res.data });
    //     }).catch((error) => console.log(error));
    // }, [change]);

    const dateArray = project ? generateDateArray(project.startDate as string, project.endDate as string) : [];

    const handleAddOrSaveButtonClick = (editorIndex: number) => {
        const updatedEditorModes = [...editorModes];
        updatedEditorModes[editorIndex] = !updatedEditorModes[editorIndex];
        setEditorModes(updatedEditorModes);
    };

    const handleSaveButtonClick = async (editorIndex: number) => {
        const updatedEditorNames = [...editorNames];
        updatedEditorNames[editorIndex] = editorValue;
        setEditorNames(updatedEditorNames);

        const updatedEditorModes = [...editorModes];
        updatedEditorModes[editorIndex] = false;
        setEditorModes(updatedEditorModes);

        const updatedData = {
            _id: schedule?._id,
            projectName: projectName,
            editors: updatedEditorNames,
            // table: [],
        };

        // updateSchedule(updatedData);
        setChange(!change);
    };

    const handleCellSelection = (cellIndex: number) => {
        setSelectedCell(cellIndex);
    };

    const handleColorSelection = (color: string) => {
        setSelectedColor(color);
    };

    const handleInputChange = (value: string) => {
        setInputValue(value);
    };
    const handleEditCell = () => {
        // כאן תבצע את הלוגיקה לעדכון התאים בלוח הזמנים
        // שימוש ב selectedCell, selectedColor, inputValue
        // ...
    };
    const handleSelectRange = () => {
        if (startRow !== null && endRow !== null && startCol !== null && endCol !== null) {
            // שליטה על תחום השורות והעמודות שנבחר
            const selectedRange = { startRow, endRow, startCol, endCol };
            console.log(selectedRange);
        }
    };

    const toggleEditingPanel = () => {
        setEditingPanelOpen(!isEditingPanelOpen);
    };
    return (
        <div className="editorWorkPanel" style={{ overflowY: "auto" }}>
            <div className="row editorWorkHeadline">
                <img src="/Monica_Logo@0.25x.png" alt="Logo" style={{ height: '60px', width: '200px' }} />
                <h1>בניית לו״ז</h1>
            </div>
            <div>
                <button
                    className="btn btn-primary"
                    style={{
                        position: 'fixed',
                        top: '10px',
                        left: '10px',
                        transition: 'transform 0.3s ease-out',
                    }}
                    onClick={toggleEditingPanel}
                >
                    פאנל עריכה
                </button>
                {isEditingPanelOpen && (
                    <div className="editPanel" style={{
                        borderRadius: '15px', backgroundColor: '#ECF2F9', border: '2px solid #3E5F77', width: '40vw', padding: '10px', position: 'fixed', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', zIndex: '1000',
                    }}
                    >
                        <button className="btn btn-close" style={{
                            position: 'absolute', top: '10px', left: '10px', fontSize: '18px', cursor: 'pointer', background: 'none', border: 'none', color: '#3E5F77'
                        }} onClick={toggleEditingPanel}>
                            X
                        </button>
                        <h2>פאנל עריכה</h2>
                        <label htmlFor="startRow">משורה:</label>
                        <input type="number" id="startRow" value={startRow || ''} onChange={(e) => setStartRow(parseInt(e.target.value, 10))} />
                        <label htmlFor="endRow">עד שורה:</label>
                        <input type="number" id="endRow" value={endRow || ''} onChange={(e) => setEndRow(parseInt(e.target.value, 10))} />
                        <label htmlFor="startCol">מעמודה:</label>
                        <input type="number" id="startCol" value={startCol || ''} onChange={(e) => setStartCol(parseInt(e.target.value, 10))} />
                        <label htmlFor="endCol">עד עמודה:</label>
                        <input type="number" id="endCol" value={endCol || ''} onChange={(e) => setEndCol(parseInt(e.target.value, 10))} />
                        <label htmlFor="color">בחר צבע:</label>
                        <input type="color" id="color" value={selectedColor} onChange={(e) => handleColorSelection(e.target.value)} />
                        <label htmlFor="inputValue">הזן ערך:</label>
                        <input type="text" id="inputValue" value={inputValue} onChange={(e) => handleInputChange(e.target.value)} />
                        {/* <button className="btn btn-primary mx-2" onClick={() => handleSelectRange()}>בחר טווח</button> */}
                        <button className="btn btn-primary mx-4 mb-2" onClick={() => handleEditCell()}>שמור שינויים</button>
                    </div>
                )}
            </div>
            <div className="row" style={{ overflowX: "auto" }}>
                <div style={{ width: "200vw", height: '100vh', overflowY: 'auto', }}>
                    <table className="table table-striped table-bordered mt-3" style={{ width: "200%" }}>
                        <thead style={{ position: "sticky", top: "0px", zIndex: "1" }}>
                            <tr className="table-info">
                                <th style={{ position: 'sticky', right: '10px' }} scope="col">תאריך</th>
                                <th style={{ position: 'sticky', right: '105px' }} scope="col"></th>
                                <th scope="col">צילומים</th>
                                <th scope="col">שידור</th>
                                <th scope="col">
                                    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                                        <div>אופליין-1</div>
                                        <div>
                                            {editorModes[0] ? (
                                                <button className="btn btn-outline-primary btn-sm" onClick={() => handleSaveButtonClick(0)}>
                                                    שמור
                                                </button>
                                            ) : (
                                                <button className="btn btn-outline-primary btn-sm" onClick={() => handleAddOrSaveButtonClick(0)}>
                                                    {editMode ? 'שמור' : 'הוסף'}
                                                </button>
                                            )}
                                        </div>
                                    </div>
                                </th>
                                <th scope="col">
                                    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                                        <div>אופליין-2</div>
                                        <div>
                                            {editorModes[1] ? (
                                                <button className="btn btn-outline-primary btn-sm" onClick={() => handleSaveButtonClick(1)}>
                                                    שמור
                                                </button>
                                            ) : (
                                                <button className="btn btn-outline-primary btn-sm" onClick={() => handleAddOrSaveButtonClick(1)}>
                                                    {editMode ? 'שמור' : 'הוסף'}
                                                </button>
                                            )}
                                        </div>
                                    </div>
                                </th>
                                <th scope="col">
                                    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                                        <div>אופליין-3</div>
                                        <div>
                                            {editorModes[2] ? (
                                                <button className="btn btn-outline-primary btn-sm" onClick={() => handleSaveButtonClick(2)}>
                                                    שמור
                                                </button>
                                            ) : (
                                                <button className="btn btn-outline-primary btn-sm" onClick={() => handleAddOrSaveButtonClick(2)}>
                                                    {editMode ? 'שמור' : 'הוסף'}
                                                </button>
                                            )}
                                        </div>
                                    </div>
                                </th>
                                <th scope="col">
                                    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                                        <div>אופליין-4</div>
                                        <div>
                                            {editorModes[3] ? (
                                                <button className="btn btn-outline-primary btn-sm" onClick={() => handleSaveButtonClick(3)}>
                                                    שמור
                                                </button>
                                            ) : (
                                                <button className="btn btn-outline-primary btn-sm" onClick={() => handleAddOrSaveButtonClick(3)}>
                                                    {editMode ? 'שמור' : 'הוסף'}
                                                </button>
                                            )}
                                        </div>
                                    </div>
                                </th>
                                <th scope="col">
                                    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                                        <div>אופליין-5</div>
                                        <div>
                                            {editorModes[4] ? (
                                                <button className="btn btn-outline-primary btn-sm" onClick={() => handleSaveButtonClick(4)}>
                                                    שמור
                                                </button>
                                            ) : (
                                                <button className="btn btn-outline-primary btn-sm" onClick={() => handleAddOrSaveButtonClick(4)}>
                                                    {editMode ? 'שמור' : 'הוסף'}
                                                </button>
                                            )}
                                        </div>
                                    </div>
                                </th>
                                <th scope="col">
                                    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                                        <div>אופליין-6</div>
                                        <div>
                                            {editorModes[5] ? (
                                                <button className="btn btn-outline-primary btn-sm" onClick={() => handleSaveButtonClick(5)}>
                                                    שמור
                                                </button>
                                            ) : (
                                                <button className="btn btn-outline-primary btn-sm" onClick={() => handleAddOrSaveButtonClick(5)}>
                                                    {editMode ? 'שמור' : 'הוסף'}
                                                </button>
                                            )}
                                        </div>
                                    </div>
                                </th>
                                <th scope="col">
                                    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                                        <div>אופליין-7</div>
                                        <div>
                                            {editorModes[6] ? (
                                                <button className="btn btn-outline-primary btn-sm" onClick={() => handleSaveButtonClick(6)}>
                                                    שמור
                                                </button>
                                            ) : (
                                                <button className="btn btn-outline-primary btn-sm" onClick={() => handleAddOrSaveButtonClick(6)}>
                                                    {editMode ? 'שמור' : 'הוסף'}
                                                </button>
                                            )}
                                        </div>
                                    </div>
                                </th>
                                <th scope="col">
                                    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                                        <div>אופליין-8</div>
                                        <div>
                                            {editorModes[7] ? (
                                                <button className="btn btn-outline-primary btn-sm" onClick={() => handleSaveButtonClick(7)}>
                                                    שמור
                                                </button>
                                            ) : (
                                                <button className="btn btn-outline-primary btn-sm" onClick={() => handleAddOrSaveButtonClick(7)}>
                                                    {editMode ? 'שמור' : 'הוסף'}
                                                </button>
                                            )}
                                        </div>
                                    </div>
                                </th>
                                <th scope="col">
                                    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                                        <div>אופליין-9</div>
                                        <div>
                                            {editorModes[8] ? (
                                                <button className="btn btn-outline-primary btn-sm" onClick={() => handleSaveButtonClick(8)}>
                                                    שמור
                                                </button>
                                            ) : (
                                                <button className="btn btn-outline-primary btn-sm" onClick={() => handleAddOrSaveButtonClick(8)}>
                                                    {editMode ? 'שמור' : 'הוסף'}
                                                </button>
                                            )}
                                        </div>
                                    </div>
                                </th>
                                <th scope="col">
                                    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                                        <div>אופליין-10</div>
                                        <div>
                                            {editorModes[9] ? (
                                                <button className="btn btn-outline-primary btn-sm" onClick={() => handleSaveButtonClick(9)}>
                                                    שמור
                                                </button>
                                            ) : (
                                                <button className="btn btn-outline-primary btn-sm" onClick={() => handleAddOrSaveButtonClick(9)}>
                                                    {editMode ? 'שמור' : 'הוסף'}
                                                </button>
                                            )}
                                        </div>
                                    </div>
                                </th>
                                <th scope="col">
                                    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                                        <div>אונליין-1</div>
                                        <div>
                                            {editorModes[10] ? (
                                                <button className="btn btn-outline-primary btn-sm" onClick={() => handleSaveButtonClick(10)}>
                                                    שמור
                                                </button>
                                            ) : (
                                                <button className="btn btn-outline-primary btn-sm" onClick={() => handleAddOrSaveButtonClick(10)}>
                                                    {editMode ? 'שמור' : 'הוסף'}
                                                </button>
                                            )}
                                        </div>
                                    </div>
                                </th>
                                <th scope="col">
                                    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                                        <div>אונליין-2</div>
                                        <div>
                                            {editorModes[11] ? (
                                                <button className="btn btn-outline-primary btn-sm" onClick={() => handleSaveButtonClick(11)}>
                                                    שמור
                                                </button>
                                            ) : (
                                                <button className="btn btn-outline-primary btn-sm" onClick={() => handleAddOrSaveButtonClick(11)}>
                                                    {editMode ? 'שמור' : 'הוסף'}
                                                </button>
                                            )}
                                        </div>
                                    </div>
                                </th>
                                <th scope="col">
                                    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                                        <div>סאונד</div>
                                        <div>
                                            {editorModes[12] ? (
                                                <button className="btn btn-outline-primary btn-sm" onClick={() => handleSaveButtonClick(12)}>
                                                    שמור
                                                </button>
                                            ) : (
                                                <button className="btn btn-outline-primary btn-sm" onClick={() => handleAddOrSaveButtonClick(12)}>
                                                    {editMode ? 'שמור' : 'הוסף'}
                                                </button>
                                            )}
                                        </div>
                                    </div>
                                </th>
                                <th scope="col">שידור</th>
                                <th scope="col">הורדה לשידור</th>
                            </tr>
                            <tr className="table-secondary">
                                <th style={{ position: 'sticky', right: '10px' }} scope="col">שם עורך</th>
                                <th style={{ position: 'sticky', right: '105px' }} scope="col"></th>
                                <th scope="col"></th>
                                <th scope="col"></th>
                                <td>
                                    {editorModes[0] ? (
                                        <input type="text" className="input-sm w-100" id="floatingEditor1" name="editor1"
                                            placeholder="שם עורך" onChange={(e) => setEditorValue(e.target.value)} />
                                    ) : (
                                        <span>{schedule?.editors[0]}</span>
                                    )}
                                </td>
                                <td>
                                    {editorModes[1] ? (
                                        <input type="text" className="input-sm w-100" id="floatingEditor2" name="editor2"
                                            placeholder="שם עורך" onChange={(e) => setEditorValue(e.target.value)} />
                                    ) : (
                                        <span>{schedule?.editors[1]}</span>
                                    )}
                                </td>
                                <td>
                                    {editorModes[2] ? (
                                        <input type="text" className="input-sm w-100" id="floatingEditor3" name="editor3"
                                            placeholder="שם עורך" onChange={(e) => setEditorValue(e.target.value)} />
                                    ) : (
                                        <span>{schedule?.editors[2]}</span>
                                    )}
                                </td>
                                <td>
                                    {editorModes[3] ? (
                                        <input type="text" className="input-sm w-100" id="floatingEditor4" name="editor4"
                                            placeholder="שם עורך" onChange={(e) => setEditorValue(e.target.value)} />
                                    ) : (
                                        <span>{schedule?.editors[3]}</span>
                                    )}
                                </td>
                                <td>
                                    {editorModes[4] ? (
                                        <input type="text" className="input-sm w-100" id="floatingEditor5" name="editor5"
                                            placeholder="שם עורך" onChange={(e) => setEditorValue(e.target.value)} />
                                    ) : (
                                        <span>{schedule?.editors[4]}</span>
                                    )}
                                </td>
                                <td>
                                    {editorModes[5] ? (
                                        <input type="text" className="input-sm w-100" id="floatingEditor6" name="editor6"
                                            placeholder="שם עורך" onChange={(e) => setEditorValue(e.target.value)} />
                                    ) : (
                                        <span>{schedule?.editors[5]}</span>
                                    )}
                                </td>
                                <td>
                                    {editorModes[6] ? (
                                        <input type="text" className="input-sm w-100" id="floatingEditor7" name="editor7"
                                            placeholder="שם עורך" onChange={(e) => setEditorValue(e.target.value)} />
                                    ) : (
                                        <span>{schedule?.editors[6]}</span>
                                    )}
                                </td>
                                <td>
                                    {editorModes[7] ? (
                                        <input type="text" className="input-sm w-100" id="floatingEditor8" name="editor8"
                                            placeholder="שם עורך" onChange={(e) => setEditorValue(e.target.value)} />
                                    ) : (
                                        <span>{schedule?.editors[7]}</span>
                                    )}
                                </td>
                                <td>
                                    {editorModes[8] ? (
                                        <input type="text" className="input-sm w-100" id="floatingEditor9" name="editor9"
                                            placeholder="שם עורך" onChange={(e) => setEditorValue(e.target.value)} />
                                    ) : (
                                        <span>{schedule?.editors[8]}</span>
                                    )}
                                </td>
                                <td>
                                    {editorModes[9] ? (
                                        <input type="text" className="input-sm w-100" id="floatingEditor10" name="editor10"
                                            placeholder="שם עורך" onChange={(e) => setEditorValue(e.target.value)} />
                                    ) : (
                                        <span>{schedule?.editors[9]}</span>
                                    )}
                                </td>
                                <td>
                                    {editorModes[10] ? (
                                        <input type="text" className="input-sm w-100" id="floatingEditor11" name="editor11"
                                            placeholder="שם עורך" onChange={(e) => setEditorValue(e.target.value)} />
                                    ) : (
                                        <span>{schedule?.editors[10]}</span>
                                    )}
                                </td>
                                <td>
                                    {editorModes[11] ? (
                                        <input type="text" className="input-sm w-100" id="floatingEditor12" name="editor12"
                                            placeholder="שם עורך" onChange={(e) => setEditorValue(e.target.value)} />
                                    ) : (
                                        <span>{schedule?.editors[11]}</span>
                                    )}
                                </td>
                                <td>
                                    {editorModes[12] ? (
                                        <input type="text" className="input-sm w-100" id="floatingEditor13" name="editor13"
                                            placeholder="שם עורך" onChange={(e) => setEditorValue(e.target.value)} />
                                    ) : (
                                        <span>{schedule?.editors[12]}</span>
                                    )}
                                </td>
                                <th scope="col"></th>
                                <th scope="col"></th>
                            </tr>
                        </thead>
                        <tbody>
                            {dateArray.map((dateObj, index) => (
                                <tr key={index}>
                                    <td style={{ position: 'sticky', right: '10px' }}>
                                        <span style={{
                                            position: 'absolute', left: '3%', bottom: 0, fontSize: '0.8rem', color: '#888',
                                            backgroundColor: '#f8f9fa', padding: '1px 2px', borderRadius: '3px',
                                        }}>{index + 1}</span>
                                        {`${dateObj.date}`}
                                    </td>
                                    <td style={{ position: 'sticky', right: '105px' }}>{`${dateObj.day}`}</td>
                                    <td><input type="text" className="input-sm w-100" id="floatingStart" name="name" style={inputStyles}
                                    /></td>
                                    <td><input type="text" className="input-sm w-100" id="floatingCost" name="cost" style={inputStyles} />
                                    </td>
                                    <td><input type="text" className="input-sm w-100" id="floatingAmount" name="amount" style={inputStyles}
                                    /> </td>
                                    <td><input type="text" className="input-sm w-100" id="floatingPeriod" name="period" style={inputStyles} />
                                    </td>
                                    <td> <input type="text" className="input-sm w-100" id="floatingUnits" name="units" style={inputStyles}
                                    /></td>
                                    <td> <input type="text" className="input-sm w-100" id="floatingTotal" name="total" style={inputStyles}
                                    /></td>
                                    <td> <input type="text" className="input-sm w-100" id="floatingCurrent" name="current" style={inputStyles}
                                    /></td>
                                    <td> <input type="text" className="input-sm w-100" id="floatingBalance" name="balance" style={inputStyles}
                                    /></td>
                                    <td> <input type="text" className="input-sm w-100" id="floatingFlow" name="flow" style={inputStyles} />
                                    </td>
                                    <td> <input type="text" className="input-sm w-100" id="floatingFlow" name="flow" style={inputStyles} />
                                    </td>
                                    <td> <input type="text" className="input-sm w-100" id="floatingFlow" name="flow" style={inputStyles} />
                                    </td>
                                    <td> <input type="text" className="input-sm w-100" id="floatingFlow" name="flow" style={inputStyles} />
                                    </td>
                                    <td> <input type="text" className="input-sm w-100" id="floatingFlow" name="flow" style={inputStyles} />
                                    </td>
                                    <td> <input type="text" className="input-sm w-100" id="floatingFlow" name="flow" style={inputStyles} />
                                    </td>
                                    <td> <input type="text" className="input-sm w-100" id="floatingFlow" name="flow" style={inputStyles} />
                                    </td>
                                    <td> <input type="text" className="input-sm w-100" id="floatingFlow" name="flow" style={inputStyles} />
                                    </td>
                                    <td> <input type="text" className="input-sm w-100" id="floatingFlow" name="flow" style={inputStyles} />
                                    </td>

                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            </div>
            <div className="row empsSalRepOptions mx-auto col-10 col-md-8 col-lg-6">
                <p onClick={() => navigate(-1)} style={{
                    color: 'blue',
                    textDecoration: 'underline',
                    cursor: 'pointer',
                }}>חזור</p>
                <p style={{ color: 'lightgray' }}>Almog Liebermensch 2024 ©</p>

            </div>
        </div>
    );
}

export default WorkSchedule;
// *** OTHER ***
import { Task } from "react-gantt-chart";

export const initTasks = (): Task[] => {
    const tasks: Task[] = [
        // Project 1
        {
            line: 0,
            type: "project",
            id: "ProjectSample",
            name: "Project",
            start: new Date(2024, 3, 1),
            end: new Date(2024, 11, 30),
            progress: 8,
            hideChildren: false,
        },
        {
            line: 1,
            type: "task",
            id: "Task 0",
            name: "פרק 1",
            start: new Date(2024, 3, 1),
            end: new Date(2024, 3, 13),
            progress: 100,
            project: "ProjectSample",
        },
        {
            line: 1,
            type: "milestone",
            id: "Task 1",
            name: "סיום",
            start: new Date(2024, 3, 13),
            end: new Date(2024, 3, 13),
            progress: 100,
            dependencies: ["Task 0"],
            project: "ProjectSample",
        },
        {
            line: 2,
            type: "task",
            id: "Task 2",
            name: "פרק 2",
            start: new Date(2024, 3, 14),
            end: new Date(2024, 3, 23),
            progress: 7,
            dependencies: ["Task 1"],
            project: "ProjectSample",
        },
        {
            line: 2,
            type: "milestone",
            id: "Task 3",
            name: "סיום",
            start: new Date(2024, 3, 23),
            end: new Date(2024, 3, 23),
            progress: 0,
            dependencies: ["Task 2"],
            project: "ProjectSample",
        },
        {
            line: 3,
            type: "task",
            id: "Task 4",
            name: "פרק 3",
            start: new Date(2024, 3, 24),
            end: new Date(2024, 4, 6),
            progress: 15,
            dependencies: ["Task 3"],
            project: "ProjectSample",
        },
        {
            line: 3,
            type: "milestone",
            id: "Task 5",
            name: "סיום",
            start: new Date(2024, 4, 6),
            end: new Date(2024, 4, 6),
            progress: 0,
            dependencies: ["Task 4"],
            project: "ProjectSample",
        },
        // {
        //     line: 4,
        //     type: "task",
        //     id: "Task 6",
        //     name: "פרק 4",
        //     start: new Date(2024, 7, 2),
        //     end: new Date(2024, 7, 12),
        //     progress: 75,
        //     dependencies: ["Task 5"],
        //     project: "ProjectSample",
        // },
        // {
        //     line: 4,
        //     type: "milestone",
        //     id: "Task 7",
        //     name: "סיום",
        //     start: new Date(2023, 7, 12),
        //     end: new Date(2023, 7, 12),
        //     progress: 100,
        //     dependencies: ["Task 6"],
        //     project: "ProjectSample",
        // },
        // {
        //     line: 5,
        //     type: "task",
        //     id: "Task 8",
        //     name: "פרק 5",
        //     start: new Date(2023, 7, 13),
        //     end: new Date(2023, 7, 20),
        //     progress: 40,
        //     dependencies: ["Task 7"],
        //     project: "ProjectSample",
        // },
        // {
        //     line: 5,
        //     type: "milestone",
        //     id: "Task 9",
        //     name: "סיום",
        //     start: new Date(2023, 7, 20),
        //     end: new Date(2023, 7, 20),
        //     progress: 100,
        //     dependencies: ["Task 8"],
        //     project: "ProjectSample",
        // },
        // {
        //     line: 6,
        //     type: "task",
        //     id: "Task 10",
        //     name: "פרק 6",
        //     start: new Date(2023, 7, 21),
        //     end: new Date(2023, 8, 2),
        //     progress: 10,
        //     dependencies: ["Task 9"],
        //     project: "ProjectSample",
        // },
        // {
        //     line: 6,
        //     type: "milestone",
        //     id: "Task 11",
        //     name: "סיום",
        //     start: new Date(2023, 8, 2),
        //     end: new Date(2023, 8, 2),
        //     progress: 100,
        //     dependencies: ["Task 10"],
        //     project: "ProjectSample",
        // },
        // {
        //     line: 7,
        //     type: "task",
        //     id: "Task 12",
        //     name: "פרק 7",
        //     start: new Date(2023, 8, 3),
        //     end: new Date(2023, 8, 15),
        //     progress: 0,
        //     dependencies: ["Task 11"],
        //     project: "ProjectSample",
        // },
        // {
        //     line: 7,
        //     type: "milestone",
        //     id: "Task 13",
        //     name: "סיום",
        //     start: new Date(2023, 8, 15),
        //     end: new Date(2023, 8, 15),
        //     progress: 100,
        //     dependencies: ["Task 12"],
        //     project: "ProjectSample",
        // },
        // {
        //     line: 8,
        //     type: "task",
        //     id: "Task 14",
        //     name: "פרק 8",
        //     start: new Date(2023, 8, 16),
        //     end: new Date(2023, 8, 25),
        //     progress: 0,
        //     dependencies: ["Task 13"],
        //     project: "ProjectSample",
        // },
        // {
        //     line: 8,
        //     type: "milestone",
        //     id: "Task 15",
        //     name: "סיום",
        //     start: new Date(2023, 8, 25),
        //     end: new Date(2023, 8, 25),
        //     progress: 100,
        //     dependencies: ["Task 14"],
        //     project: "ProjectSample",
        // },
        // {
        //     line: 9,
        //     type: "task",
        //     id: "Task 16",
        //     name: "פרק 9",
        //     start: new Date(2023, 8, 26),
        //     end: new Date(2023, 9, 9),
        //     progress: 0,
        //     dependencies: ["Task 15"],
        //     project: "ProjectSample",
        // },
        // {
        //     line: 9,
        //     type: "milestone",
        //     id: "Task 17",
        //     name: "סיום",
        //     start: new Date(2023, 9, 9),
        //     end: new Date(2023, 9, 9),
        //     progress: 100,
        //     dependencies: ["Task 16"],
        //     project: "ProjectSample",
        // },
        // {
        //     line: 10,
        //     type: "task",
        //     id: "Task 18",
        //     name: "פרק 10",
        //     start: new Date(2023, 9, 10),
        //     end: new Date(2023, 9, 17),
        //     progress: 0,
        //     dependencies: ["Task 17"],
        //     project: "ProjectSample",
        // },
        // {
        //     line: 10,
        //     type: "milestone",
        //     id: "Task 19",
        //     name: "סיום",
        //     start: new Date(2023, 9, 17),
        //     end: new Date(2023, 9, 17),
        //     progress: 100,
        //     dependencies: ["Task 18"],
        //     project: "ProjectSample",
        // },
    ];
    return tasks;
};

export const getStartEndDateForProject = (
    tasks: Task[],
    projectId: string
): [Date, Date] => {
    const projectTasks = tasks.filter((t) => t.project === projectId);
    let start = projectTasks[0].start;
    let end = projectTasks[0].end;

    for (let i = 0; i < projectTasks.length; i++) {
        const task = projectTasks[i];
        if (start.getTime() > task.start.getTime()) {
            start = task.start;
        }
        if (end.getTime() < task.end.getTime()) {
            end = task.end;
        }
    }
    return [start, end];
};

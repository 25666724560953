import { FunctionComponent, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import "../css/selectEmployees.css"
import Project from "../interfaces/Project";
import { getProjectName, getUsersbyProject, getUsersbySecProject, updateUser } from "../services/userServices";
import User from "../interfaces/User";
import { getProjectByName, updateProject } from "../services/projectServices";
import ListOfEditors from "./ListOfEditors";
import ListByMonth from "./ListByMonth";
import ListOfAvilabale from "./ListOfAvilabale";

interface SelectEmployeesProps {

}

const SelectEmployees: FunctionComponent<SelectEmployeesProps> = () => {
    const navigate = useNavigate();
    const [project, setProject] = useState<Project | null>(null);
    const [projectName, setProjectName] = useState<string>("");
    const [projectWorkers, setProjectWorkers] = useState<User[]>([]);
    const [change, setChange] = useState<boolean>(false);
    const [list, setList] = useState<number>(1);
    const [editingActualWage, setEditingActualWage] = useState<string | null>(null);


    useEffect(() => {
        const fetchData = async () => {
            try {
                const name = getProjectName();
                setProjectName(name);

                const [projectRes, projectWorkersRes, secProjectWorkersRes] = await Promise.all([
                    getProjectByName(name),
                    getUsersbyProject(name),
                    getUsersbySecProject(name)
                ]);

                setProject({ ...projectRes.data });
                setProjectWorkers([...projectWorkersRes.data, ...secProjectWorkersRes.data]);
            } catch (error) {
                console.error(error);
            }
        };

        fetchData();
    }, [change]);

    const refresh = () => {
        setChange(!change);
    }
    const handleEditClick = (userId: string) => {
        setEditingActualWage(userId);
    };

    const handleSaveClick = (user: User) => {
        setEditingActualWage(null);
        updateUser(user);
        setChange(!change);

    };
    const handleRemoveClick = (user: User) => {
        if (user.secProject === projectName) {
            user.secProject = "";
            user.secWage = "";
        } else {
            user.avilabale = "פנוי/ה";
            user.projectName = "";
            user.actualWage = "";
        }

        updateUser({ ...user });
        setChange(!change);
        setList(0);
        const updatedWorkers = project?.workers?.filter(worker => worker.id !== user.id) || [];
        if (project) {
            project.workers = updatedWorkers;
            updateProject(project);
        }
    };
    const averageWage =
        projectWorkers.length > 0
            ? projectWorkers
                .filter(user => user.position === "עורך וידאו-אופליין")
                .reduce((sum, user) => sum + Number(user.actualWage), 0) /
            projectWorkers.filter(user => user.position === "עורך וידאו-אופליין").length
            : 0;
    useEffect(() => {
        if (project && projectWorkers.length > 0) {
            const editors = projectWorkers.filter(user => user.position === "עורך וידאו-אופליין");
            const newAvgWage = editors.reduce((sum, user) => sum + Number(user.actualWage), 0) / editors.length;
            project.avgWage = newAvgWage.toFixed(2).toString();
            updateProject(project);
        }
    }, [change]);

    return (
        <div className="selectEmployeesPanel" style={{ height: "100vh" }}>
            <div className="row selectEmployeesHeadline">
                <img src="/Monica_Logo@0.25x.png" alt="Logo" style={{ height: '60px', width: '200px' }} />
                <h1>שיבוץ עובדים</h1>
            </div>
            <div className="row col-12 mx-auto">
                <div className="col-1"></div>
                <div className="form col-3 col-md-2 col-lg-4 mt-5">
                    <form>
                        <div className="form-floating row mb-3 mt-5">
                            <div className="col-8">
                                <span className="postBadge badge bg-primary w-100 py-2">שם פרויקט:   {projectName}</span>
                            </div>
                        </div>
                        <div className="form-floating row mb-3">
                            <div className="col-8">
                                <span className="postBadge badge bg-primary w-100 py-2">מספר פרקים:    {project?.episodes}</span>
                            </div>
                        </div>
                        <div className="form-floating row mb-3">
                            <div className="col-8">
                                <span className="postBadge badge bg-primary w-100 py-2">תקציב:    {project?.budget}</span>
                            </div>
                        </div>
                    </form>
                </div>
                <div className="col-7">
                    <h2 className="my-3">עובדים בפרויקט</h2>
                    <div className="table-responsive" style={{ maxHeight: "210px", overflowY: "auto" }}>
                        {projectWorkers?.length ? (
                            <table className="table mt-2">
                                <thead className="table-primary" style={{ position: "sticky", top: 0, zIndex: 1 }}>
                                    <tr>
                                        <th scope="col">שם</th>
                                        <th scope="col">תפקיד</th>
                                        <th scope="col">טלפון</th>
                                        <th scope="col">וותק</th>
                                        <th scope="col">ז׳אנר</th>
                                        <th scope="col">שכר שעתי</th>
                                        <th scope="col"></th>
                                        <th scope="col"></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {projectWorkers.map((user: User) => (
                                        <tr key={user.id}>
                                            <td>{user.name}</td>
                                            <td>{user.position}</td>
                                            <td>{user.phone}</td>
                                            <td>{user.experience}</td>
                                            <td>{user.genre}</td>
                                            <td>
                                                {editingActualWage === user.id ? (
                                                    <input
                                                        className="w-50"
                                                        type="text"
                                                        value={user.projectName === projectName ? user.actualWage : user.secWage}
                                                        onChange={(e) => {
                                                            const newValue = e.target.value;
                                                            setProjectWorkers(prevWorkers =>
                                                                prevWorkers.map(prevUser =>
                                                                    prevUser.id === user.id
                                                                        ? {
                                                                            ...prevUser,
                                                                            actualWage: user.projectName === projectName ? newValue : prevUser.actualWage,
                                                                            secWage: user.secProject === projectName ? newValue : prevUser.secWage,
                                                                        }
                                                                        : prevUser
                                                                )
                                                            );
                                                        }}
                                                    />
                                                ) : (
                                                    user.projectName === projectName
                                                        ? user.actualWage
                                                        : user.secProject === projectName
                                                            ? user.secWage
                                                            : ''
                                                )}
                                            </td>
                                            <td>
                                                {editingActualWage === user.id ? (
                                                    <button
                                                        className="btn btn-success btn-sm"
                                                        onClick={() => handleSaveClick(user)}
                                                    >
                                                        אישור
                                                    </button>
                                                ) : (
                                                    <button
                                                        className="btn btn-primary btn-sm"
                                                        onClick={() => handleEditClick(user.id as string)}
                                                    >
                                                        עריכה
                                                    </button>
                                                )}
                                            </td>
                                            <td>
                                                <button className="btn btn-secondary btn-sm" onClick={() => handleRemoveClick(user)}>
                                                    הסר
                                                </button>
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        ) : (
                            <p> אין עובדים עדיין </p>
                        )}
                    </div>
                    <div className="text-center mt-2">
                        <h4>ממוצע שכר שעתי: {averageWage.toFixed(2)}</h4>
                    </div>
                </div>
            </div>
            <div className="w-100 space mt-3"></div>
            <div className="w-100 space"></div>
            <div className="row mt-4">
                <h2>מאגר עובדים</h2>
                <div className="row mx-auto col-10 col-md-8 col-lg-6 mt-2 mb-2">
                    <div className="">
                        <button className="btn btn-outline-success mx-2" onClick={() => {
                            setChange(!change);
                            setList(1);
                        }} >פנויים</button>
                        <button className="btn btn-outline-warning mx-2" onClick={() => {
                            setChange(!change);
                            setList(2);
                        }} > לפי חודש</button>
                        <button className="btn btn-outline-danger mx-2" onClick={() => {
                            setChange(!change);
                            setList(3);
                        }} >זמינות שבועית</button>
                    </div>
                </div>
                {list === 1 && <ListOfEditors refresh={refresh} />}
                {list === 2 && <ListByMonth refresh={refresh} />}
                {list === 3 && <ListOfAvilabale refresh={refresh} />}
            </div>
            <div className="div my-5"></div>
            <div className="row selectEmployeesOptions mx-auto col-10 col-md-8 col-lg-6">
                <p onClick={() => navigate(-1)} style={{
                    color: 'blue',
                    textDecoration: 'underline',
                    cursor: 'pointer',
                }}>חזור</p>
                <p style={{ color: 'lightgray' }}>Almog Liebermensch 2024 ©</p>
            </div>
        </div>
    );
}

export default SelectEmployees;
import { FunctionComponent, useState } from "react";
import { useNavigate } from "react-router-dom";
import * as yup from "yup";
import { useFormik } from "formik";
import User from "../interfaces/User";
import "../css/resetPassword.css"


interface ResetPasswordProps {

}

const ResetPassword: FunctionComponent<ResetPasswordProps> = () => {
    const navigate = useNavigate();
    let [code, setCode] = useState<string>("");
    let formik = useFormik({
        initialValues: { username: "", password: "" },
        validationSchema: yup.object({
            username: yup.string().required().min(5),
            password: yup.string().required().min(8)
        }),
        onSubmit: (values: User) => {
            // checkUser(values).then((res) => {
            //     const user = res.data;
            //     navigate("/dashboard")
            //     UserCtx.userctx = ({ ...user, isLoggedIn: true, position: res.data.position });
            //     UserCtx.changeUser({ ...user, isLoggedIn: true, position: res.data.position });

            //     sessionStorage.setItem("userData", JSON.stringify({
            //         isLoggedIn: true,
            //         position: res.data.position,
            //         token: res.data,
            //     }));
            //     successMsg("You logged in successfully");
            // })
            //     .catch((err) => {
            //         console.log(err)
            //         errorMsg("Wrong email or password")
            //     })
        }
    })
    return (<>
        <div className="passwordPanel">
            <div className="row passwordHeadline">
                <img src="/Monica_Logo@0.25x.png" alt="Logo" style={{ height: '60px', width: '200px' }} />
                <h1>אתחול סיסמא</h1>
            </div>
            <div className="form mx-auto col-10 col-md-8 col-lg-6 mt-5">
                <form
                    onSubmit={formik.handleSubmit}
                >
                    <div className="form-floating row mb-5">
                        <h2 className="mb-4">קבלת קוד למייל</h2>
                        <div className="col-2 pt-2">
                            <label htmlFor="floatingEmail">אימייל:</label>
                            {/* {formik.touched.email && formik.errors.email && (
                                <small className="text-danger">{formik.errors.email}</small>
                            )} */}
                        </div>
                        <div className="col-7">
                            <input type="email" className="form-control" id="floatingEmail" name="email"
                                // value={formik.values.email}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                            />
                        </div>
                        <div className="col-1 w-25">
                            <button
                                // type="submit"
                                onClick={() => {
                                    // setCode(getUserByEmail("hi"))
                                }}
                                disabled={!formik.isValid || !formik.dirty}
                                className="btn btn-info w-50">
                                שלח
                            </button>
                        </div>
                    </div>
                </form>
                <form
                // onSubmit={formik.handleSubmit}
                >
                    <div className="row space mb-5"></div>
                    <div className="form-floating row mb-3">
                        <div className="col-4 pt-2">
                            <label htmlFor="floatingCode">קוד:</label>
                            {/* {formik.touched.password && formik.errors.password && (
                    <small className="text-danger">{formik.errors.password}</small>
                )} */}
                        </div>
                        <div className="col-8">
                            <input type="text" className="form-control" id="floatingCode" name="code"
                            // value={formik.values.password}
                            // onChange={formik.handleChange}
                            // onBlur={formik.handleBlur}
                            />
                        </div>
                    </div>
                    <div className="form-floating row mb-3">
                        <div className="col-4 pt-2">
                            <label htmlFor="floatingPassword">סיסמא חדשה:</label>
                            {/* {formik.touched.password && formik.errors.password && (
                    <small className="text-danger">{formik.errors.password}</small>
                )} */}
                        </div>
                        <div className="col-8">
                            <input type="password" className="form-control" id="floatingPassword" name="password"
                            // value={formik.values.password}
                            // onChange={formik.handleChange}
                            // onBlur={formik.handleBlur}
                            />
                        </div>
                    </div>
                    <div className="form-floating row mb-3">
                        <div className="col-4 pt-2">
                            <label htmlFor="floatingPassword">הזן שוב:</label>
                            {/* {formik.touched.password && formik.errors.password && (
                    <small className="text-danger">{formik.errors.password}</small>
                )} */}
                        </div>
                        <div className="col-8">
                            <input type="password" className="form-control" id="floatingPassword" name="password"
                            // value={formik.values.password}
                            // onChange={formik.handleChange}
                            // onBlur={formik.handleBlur}
                            />
                        </div>
                    </div>
                    <button type="submit"
                        // disabled={!formik.isValid || !formik.dirty} 
                        className="btn btn-info my-3 w-50 mt-5 mb-5">
                        שמור
                    </button>
                </form>
            </div >
            <div className="row passwordOptions mx-auto col-10 col-md-8 col-lg-6">
                <p onClick={() => navigate(-1)} style={{
                    color: 'blue',
                    textDecoration: 'underline',
                    cursor: 'pointer',
                }}>חזור</p>
                <p style={{ color: 'lightgray' }}>Almog Liebermensch 2024 ©</p>
            </div>
        </div >
    </>);
}

export default ResetPassword;
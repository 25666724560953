import { FunctionComponent, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Project from "../interfaces/Project";
import { getProjectName } from "../services/managerServices";
import { getProjectByName } from "../services/projectServices";
import { getUsersbyProject } from "../services/userServices";
import User from "../interfaces/User";
import { getReportsByProjectName } from "../services/reportServices";
import Report from "../interfaces/Report";
import { getEpisodeReportsByEpisode, updateEpisodeReport } from "../services/episodeReportServices";
import EpisodeReport from "../interfaces/EpisodeReport";
import Task from "../interfaces/Task";
import Manager from "../interfaces/Manager";

interface EpisodeScreenProps {

}

const EpisodeScreen: FunctionComponent<EpisodeScreenProps> = () => {
    const navigate = useNavigate();
    let [userInfo, setUserInfo] = useState<User[]>([]);
    let [managerName, setManagerName] = useState<string>("");
    let [projectName, setProjectName] = useState<string>("");
    let [change, setChange] = useState<boolean>(false);
    let [project, setProject] = useState<Project | null>(null);
    let [reports, setReports] = useState<Report[]>([]);
    let [episodeReport, setEpisodeReport] = useState<EpisodeReport | null>(null);
    let [newTask, setNewTask] = useState<string>('');

    const { projectNameParams, episode } = useParams();


    useEffect(() => {
        const fetchData = async () => {
            try {
                if (projectNameParams) {
                    setProjectName(projectNameParams);
                    const reportsResponse = await getReportsByProjectName(projectNameParams);
                    setReports(reportsResponse.data);

                    const projectResponse = await getProjectByName(projectNameParams);
                    setProject(projectResponse.data);
                    setProjectName(projectResponse.data.name)

                    const usersResponse = await getUsersbyProject(projectNameParams);
                    setUserInfo(usersResponse.data);

                    const episodeNumber = episode ? +episode : undefined;
                    if (episodeNumber !== undefined) {
                        const episodeResponse = await getEpisodeReportsByEpisode(projectNameParams, episodeNumber);

                        setEpisodeReport(episodeResponse.data);
                        checkAndHandleEpisodeReport();
                    }
                }
                else {
                    const proName = await getProjectName();
                    setProjectName(proName)
                    const reportsResponse = await getReportsByProjectName(proName);
                    setReports(reportsResponse.data);

                    const projectResponse = await getProjectByName(proName);
                    setProject(projectResponse.data);
                    setProjectName(projectResponse.data.name)

                    const usersResponse = await getUsersbyProject(proName);
                    setUserInfo(usersResponse.data);

                    const episodeNumber = episode ? +episode : undefined;
                    if (episodeNumber !== undefined) {
                        const episodeResponse = await getEpisodeReportsByEpisode(proName, episodeNumber);

                        setEpisodeReport(episodeResponse.data);
                        checkAndHandleEpisodeReport();
                    }
                }
                checkAndHandleEpisodeReport();
            } catch (error) {
                console.error(error);
            }
        };

        const checkAndHandleEpisodeReport = async () => {
            try {
                if (episode !== undefined) {
                    let existingEpisodeReportResponse;
                    try {
                        existingEpisodeReportResponse = await getEpisodeReportsByEpisode(project?.name as string, +episode);
                    } catch (error) {
                        const newEpisodeReport = {
                            projectName: project?.name,
                            episode: +episode,
                            shiftsForEp: project?.shiftsForEp as number,
                            shiftsSoFar: 0,
                            totalShifts: 0,
                            deviation: project?.shiftsForEp as number,
                            tasks: [],
                        };
                        // await addEpisodeReport(newEpisodeReport);
                        return;
                    }

                    const existingEpisodeReport = existingEpisodeReportResponse.data;

                    if (existingEpisodeReport) {
                        const updatedEpisodeReport = {
                            projectName: project?.name,
                            episode: +episode,
                            shiftsForEp: project?.shiftsForEp as number,
                            shiftsSoFar: 0,
                            deviation: project?.shiftsForEp as number,
                            tasks: existingEpisodeReport.tasks,
                        };
                        await updateEpisodeReport(updatedEpisodeReport);
                    }
                }
            } catch (error) {
                console.error(error);
            }
        };

        fetchData();
    }, [change]);

    const totalNumberOfShifts = reports.reduce((count: number, report: Report) => {
        if (report.episode === (episode ?? "").toString()) {
            count++;
        }
        return count;
    }, 0);

    const updateTaskList = async () => {
        try {
            const episodeNumber = episode ? +episode : undefined;
            if (episodeNumber !== undefined) {
                const episodeResponse = await getEpisodeReportsByEpisode(project?.name as string, episodeNumber);
                setEpisodeReport(episodeResponse.data);
            }
        } catch (error) {
            console.error('Error updating task list:', error);
        }
    };

    // הוספת משימה לרשימת המשימות ב-EpisodeReport
    const handleAddTask = async (task: string) => {
        try {
            const episodeNumber = episode ? +episode : undefined;
            if (episodeNumber !== undefined) {
                await updateEpisodeReport({
                    episode: episodeNumber,
                    tasks: [
                        ...(episodeReport?.tasks ?? []),
                        { description: task, completed: false }, // שמירת משימה כאובייקט
                    ],
                });
                setChange(!change);
                updateTaskList();
            } else {
                console.error('Episode number is undefined');
            }
        } catch (error) {
            console.error('Error handling add task:', error);
        }
    };
    // מחיקת משימה מרשימת המשימות ב-EpisodeReport
    const handleDeleteTask = async (index: number) => {
        try {
            const episodeNumber = episode ? +episode : undefined;
            if (episodeNumber !== undefined) {
                const updatedTasks = [...(episodeReport?.tasks ?? [])];
                updatedTasks.splice(index, 1); // הסרת המשימה מהרשימה
                await updateEpisodeReport({
                    episode: episodeNumber,
                    tasks: updatedTasks,
                });
                setChange(!change);
                updateTaskList();
            }
        } catch (error) {
            console.error('Error handling delete task:', error);
        }
    };

    const handleTaskCompletion = async (index: number, completed: boolean) => {
        try {
            const episodeNumber = episode ? +episode : undefined;
            if (episodeNumber !== undefined) {
                const updatedTasks = [...(episodeReport?.tasks ?? [])];
                updatedTasks[index].completed = completed;

                await updateEpisodeReport({
                    episode: episodeNumber,
                    tasks: updatedTasks,
                });

                setChange(!change);
                updateTaskList();
            }
        } catch (error) {
            console.error('Error handling task completion:', error);
        }
    };
    return (
        <div className="passwordPanel">
            <div className="row passwordHeadline">
                <img src="/Monica_Logo@0.25x.png" alt="Logo" style={{ height: '60px', width: '200px' }} />
                <h1>דוח פרק</h1>
            </div>
            <div className="form mx-auto col-12 col-md-8 col-lg-6 mt-4">
                <div className="row">
                    <div className="col projectName">
                        <span className="postBadge badge rounded-pill w-50 py-3">פרק: {episode}</span>
                    </div>
                </div>
                <div className="form-floating row my-3">
                    <div className="col-4 pt-1">
                        <label htmlFor="floatingEmail">הוספת משימה:</label>
                    </div>
                    <div className="col-6">
                        <input type="text" className="form-control" id="floatingText" name="text" onChange={(e) => setNewTask(e.target.value)} />
                    </div>
                    <div className="col-1">
                        <button className="btn btn-info" onClick={() => handleAddTask(newTask)}>
                            הוסף
                        </button>
                    </div>
                </div>
                <div className="row mt-3">
                    <table className="table table-striped mt-2">
                        <thead className="thead-dark">
                            <tr className="table-info">
                                <th scope="col">בוצע</th>
                                <th scope="col">משימות</th>
                                <th scope="col">מחיקה</th>
                            </tr>
                        </thead>
                        {episodeReport && episodeReport.tasks && episodeReport.tasks.length ? (
                            <tbody>
                                {episodeReport.tasks.map((task: Task, index: number) => (
                                    <tr key={index}>
                                        <td>
                                            <input
                                                type="checkbox"
                                                className="input-sm w-50"
                                                name="confirm"
                                                checked={task.completed}
                                                onChange={(e) => handleTaskCompletion(index, e.target.checked)}
                                            />
                                        </td>
                                        <td>{task.description}</td>
                                        <td>
                                            <button className="btn btn-secondary btn-sm" onClick={() => handleDeleteTask(index)}>
                                                מחק
                                            </button>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        ) : (
                            <p>אין משימות</p>
                        )}
                    </table>
                </div>
                <div className="row w-50 mx-auto">
                    <button className="btn btn-outline-primary">
                        סיום פרק
                    </button>
                </div>
                <div className="row space mb-4 mt-3"></div>
                <div className="row mb-2">
                    <div className="col projectName">
                        <span className="postBadge badge rounded-pill w-50 py-3">עובדים בפרק</span>
                    </div>
                </div>
                <div className="row mt-3">
                    <table className="table table-striped mt-2">
                        <thead className="thead-dark">
                            <tr className="table-info">
                                <th scope="col">שם עובד</th>
                                <th scope="col">מספר משמרות על הפרק</th>
                            </tr>
                        </thead>
                        {userInfo.length ? (
                            <tbody className="">
                                {userInfo.map((user: User) => {
                                    const numberOfReports = reports.reduce((count: number, report: Report) => {
                                        if (report.episode === (episode ?? '').toString() && report.editorName === user.name) {
                                            count++;
                                        }
                                        return count;
                                    }, 0);
                                    return (
                                        <tr key={user.id}>
                                            <td>{user.name}</td>
                                            <td>{numberOfReports}</td>
                                        </tr>
                                    );
                                })}
                            </tbody>
                        ) : (
                            <p>  </p>
                        )}
                    </table>
                    <div className="row">
                        <h5 className="m-2">סה״כ משמרות: {totalNumberOfShifts}</h5>
                    </div>
                </div>
            </div>
            <div className="row passwordOptions mx-auto col-10 col-md-8 col-lg-6">
                <p onClick={() => navigate(-1)} style={{
                    color: 'blue',
                    textDecoration: 'underline',
                    cursor: 'pointer',
                }}>חזור</p>
                <p style={{ color: 'lightgray' }}>Almog Liebermensch 2024 ©</p>
            </div>
        </div>
    );
}

export default EpisodeScreen;
import { FunctionComponent, useEffect, useState } from "react";
import Manager from "../interfaces/Manager";
import Report from "../interfaces/Report";
import { getManagerById, getManagerProfile } from "../services/managerServices";
import { Link, useNavigate, useParams } from "react-router-dom";
import { getReportsByProject } from "../services/reportServices";
import User from "../interfaces/User";
import { getUsersbyProject } from "../services/userServices";
import Project from "../interfaces/Project";
import { getProjectByName } from "../services/projectServices";

interface SalaryReportProps {

}

const convertHoursMinutesToMinutes = (timeString: string) => {
    const [hours, minutes] = timeString.split(":").map(Number);
    const totalMinutes = hours * 60 + minutes;
    return totalMinutes;
};

const SalaryReport: FunctionComponent<SalaryReportProps> = () => {
    let [manager, setManager] = useState<Manager>({
        username: "",
        password: "",
    });
    const navigate = useNavigate();
    let [users, setUsers] = useState<User[]>([]);
    let [reports, setReports] = useState<Report[]>([]);
    let [project, setProject] = useState<Project>();
    const [selectedMonth, setSelectedMonth] = useState<string>(getInitialMonth());

    const { projectName } = useParams();
    const monthNames = [
        "ינואר", "פברואר", "מרץ", "אפריל", "מאי", "יוני",
        "יולי", "אוגוסט", "ספטמבר", "אוקטובר", "נובמבר", "דצמבר"
    ];
    useEffect(() => {
        const fetchData = async () => {
            try {
                if (projectName) {
                    const pro = await getProjectByName(projectName);
                    setProject(pro.data);

                    const man = await getManagerById(pro.data.postId as string);
                    setManager(man.data);

                    const reportsResponse = await getReportsByProject(man.data.projectName as string);
                    setReports(reportsResponse.data);

                    const usersResponse = await getUsersbyProject(man.data.projectName as string);
                    setUsers(usersResponse.data);
                }
                else {
                    const managerResponse = await getManagerProfile();
                    setManager(managerResponse.data);

                    const projectResponse = await getProjectByName(managerResponse.data.projectName);
                    setProject(projectResponse.data);

                    const reportsResponse = await getReportsByProject(managerResponse.data.projectName as string);
                    setReports(reportsResponse.data);

                    const usersResponse = await getUsersbyProject(managerResponse.data.projectName as string);
                    setUsers(usersResponse.data);
                }


            } catch (error) {
                console.log(error);
            }
        };

        fetchData(); // קריאה ראשונית כשהרכיב מתקבל
    }, [selectedMonth]); // רישום לשינוי ב selectedMonth


    function getInitialMonth(): string {
        const now = new Date();
        const monthNames = [
            "ינואר", "פברואר", "מרץ", "אפריל", "מאי", "יוני",
            "יולי", "אוגוסט", "ספטמבר", "אוקטובר", "נובמבר", "דצמבר"
        ];
        return monthNames[now.getMonth()]; // החודש הנוכחי בתור string
    }

    const calculateTotalWorkHours = (user: User) => {
        const userReports = reports.filter(
            (report) => report.editorName === user.name && report.monthName === selectedMonth
        );

        const totalWorkMinutes = userReports.reduce((total, report) => {
            const minutes = convertHoursMinutesToMinutes(report.total as string) || 0;
            return total + minutes;
        }, 0);

        const totalWorkHours = Math.floor(totalWorkMinutes / 60); // חלוקת הקורות דקות לשעות

        return totalWorkHours;
    };

    const calculateTotalSalary = (user: User) => {
        const userReports = reports.filter(
            (report) => report.editorName === user.name && report.monthName === selectedMonth
        );

        const totalSalary = userReports.reduce((total, report) => {
            const minutes = convertHoursMinutesToMinutes(report.total as string) || 0;
            const hourlyRate = parseFloat(user.actualWage as string);
            const hourlySalary = (hourlyRate * minutes) / 60;
            return total + hourlySalary;
        }, 0);

        // השורה הבאה משנה את הפורמט ל"xx,xxx" עם פסיק
        return totalSalary.toLocaleString('en-US', {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
        });
    };

    const calculateTotalMonthlyPayment = () => {
        let totalMonthlyPayment = 0;
        users.forEach(user => {
            const userReports = reports.filter(
                report => report.editorName === user.name && report.monthName === selectedMonth
            );

            userReports.forEach(report => {
                const minutes = convertHoursMinutesToMinutes(report.total as string) || 0;
                const hourlyRate = parseFloat(user.actualWage as string);
                const hourlySalary = (hourlyRate * minutes) / 60;
                totalMonthlyPayment += hourlySalary;
            });
        });

        // עיגול לשני ספרות אחרי הנקודה העשרונית ופורמט בדולרים
        return totalMonthlyPayment.toLocaleString('en-US', {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
        });
    };

    return (<>
        <div className="editorWorkPanel">
            <div className="row editorWorkHeadline">
                <img src="/Monica_Logo@0.25x.png" alt="Logo" style={{ height: '60px', width: '200px' }} />
                <h1>דוח שכר חודשי</h1>
            </div>
            <div className="row">
                <div className="col-1"></div>
                <div className="form col-8 col-md-8 col-lg-6 mt-5">
                    <form>
                        <div className="form-floating row mb-3">
                            <div className="col-4 pt-2">
                                <label htmlFor="floatingInput">חודש:</label>
                            </div>
                            <div className="col-8">
                                <select className="episodeSel custom-select mt-1" id="episodeSel" name="month"
                                    onChange={m => setSelectedMonth(m.target.value)}
                                    value={selectedMonth} // הגדרת ערך ברירת מחדל עבור השדה select
                                >
                                    <option disabled>בחר/י</option>
                                    {monthNames.map((month, index) => (
                                        <option key={index} value={month}>{month}</option>
                                    ))}
                                </select>
                            </div>
                        </div>
                        <div className="form-floating row mb-3">
                            <div className="col-4 pt-2">
                                <label htmlFor="floatingPassword">שם מפיק:</label>
                            </div>
                            <div className="col-8">
                                <input type="text" className="form-control" id="floatingPost" name="position"
                                    value={manager.name}
                                />
                            </div>
                        </div>
                        <div className="form-floating row mb-3">
                            <div className="col-4 pt-2">
                                <label htmlFor="floatingPassword">שם פרויקט:</label>
                            </div>
                            <div className="col-8">
                                <input type="text" className="form-control" id="floatingStartDate" name="wage"
                                    value={manager.projectName}
                                />
                            </div>
                        </div>
                    </form>
                </div>
            </div>
            <div className="row">
                <div className="col-1"></div>
                <div className="form col-8 col-md-8 col-lg-6 mt-5">
                </div>
            </div>
            <div className="row">
                <div>
                    <table className="table table-striped mt-5">
                        <thead>
                            <tr className="table-info">
                                <th scope="col">שם עובד</th>
                                <th scope="col">תפקיד</th>
                                <th scope="col">שכר שעתי</th>
                                <th scope="col">שעות עריכה מצטבר</th>
                                <th scope="col">סה״כ</th>
                            </tr>
                        </thead>
                        <tbody>
                            {users?.map((user: User) => {
                                const totalWorkHours = calculateTotalWorkHours(user); // חשוב: כאן אנו קוראים לפונקציה
                                const totalSalary = calculateTotalSalary(user);
                                return (
                                    <tr key={user.name}>
                                        <td><Link to={`/editorScreen/${project?.name}/${user.name}`}>
                                            {user?.name}
                                        </Link></td>
                                        <td>{user.position}</td>
                                        <td>₪ {user.actualWage}</td>
                                        <td>{totalWorkHours}</td> {/* יש להציב כאן את סה״כ השעות */}
                                        <td>₪ {totalSalary}</td>
                                    </tr>
                                );
                            })}
                        </tbody>
                    </table>
                    <div className="div">
                        <h4>סה״כ תשלום חודשי</h4>
                        <h5>₪ {calculateTotalMonthlyPayment()}</h5>
                    </div>
                </div>
            </div>
            <button
                className="btn btn-info my-3 w-25 mt-5 mb-5 w-">
                הפקת דוח
            </button>
            <div className="row empsSalRepOptions mx-auto col-10 col-md-8 col-lg-6">
                <p onClick={() => navigate(-1)} style={{
                    color: 'blue',
                    textDecoration: 'underline',
                    cursor: 'pointer',
                }}>חזור</p>
                <p style={{ color: 'lightgray' }}>Almog Liebermensch 2024 ©</p>
            </div>
        </div >
    </>);
}

export default SalaryReport;
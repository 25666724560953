import { FunctionComponent, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import "../css/login.css"
import * as yup from "yup";
import { useFormik } from "formik";
import { checkUser } from "../services/userServices";
import User from "../interfaces/User";
import Manager from "../interfaces/Manager";
import { errorMsg, successMsg } from "../services/feedbacks";


interface LoginProps {

}

const Login: FunctionComponent<LoginProps> = () => {
    let navigate = useNavigate();
    let formik = useFormik({
        initialValues: { username: "", password: "" },
        validationSchema: yup.object({
            username: yup.string().required().min(5),
            password: yup.string().required().min(8)
        }),
        onSubmit: (values: User | Manager) => {
            checkUser(values).then((res) => {
                navigate("/dash")
                sessionStorage.setItem("userData", JSON.stringify({
                    isLoggedIn: true,
                    id: res.data.id,
                    position: res.data.position,
                    token: res.data,
                }));
                successMsg("!נכנסת בהצלחה, ברוך הבא");
            })
                .catch((err) => {
                    console.log(err)
                    errorMsg("שם משתמש או סיסמא שגויים");
                })
        }
    })
    return (<>
        <div className="loginPanel">
            <div className="row loginHeadline mb-5">
                <img src="/Monica_Logo@0.25x.png" alt="Logo" style={{ height: '100px', paddingRight: '30vw', paddingLeft: '30vw' }} />
                <h1 className="mt-3">ברוכים הבאים</h1>
            </div>
            <div className="login mx-auto col-10 col-md-8 col-lg-6 mt-5">
                <div className="form mx-auto col-10 col-md-8 col-lg-6 mt-4">
                    <h1 className="enter mb-5">כניסה למערכת</h1>
                    <form onSubmit={formik.handleSubmit}>
                        <div className="form-floating row mb-3">
                            <div className="col-3 pt-1">
                                <label htmlFor="floatingInput">שם משתמש:</label>
                            </div>
                            <div className="col-8">
                                <input type="text" className="form-control" id="floatingInput" name="username"
                                    value={formik.values.username}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                />
                                {formik.touched.username && formik.errors.username && (
                                    <small className="text-danger">{formik.errors.username}</small>
                                )}
                            </div>
                        </div>
                        <div className="form-floating row mb-3">
                            <div className="col-3 pt-1">
                                <label htmlFor="floatingPassword">הקלד סיסמא:</label>
                            </div>
                            <div className="col-8">
                                <input type="password" className="form-control" id="floatingPassword" name="password"
                                    value={formik.values.password}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                />
                                {formik.touched.password && formik.errors.password && (
                                    <small className="text-danger">{formik.errors.password}</small>
                                )}
                            </div>
                        </div>
                        <button type="submit"
                            disabled={!formik.isValid || !formik.dirty}
                            className="btn btn-primary my-3 w-50 mt-5 mb-5">
                            כניסה
                        </button>
                    </form>
                </div>
            </div>
            <div className="row loginOptions mx-auto w-25 col-10 col-md-8 col-lg-6 mt-4 pt-3">
                <p><Link className="mx-2" to="/signManager">רישום מפיקים</Link> | <Link className="mx-2" to="/signUser">רישום עורכים</Link></p>
                <p><Link to="/resetPassword">שכחת סיסמא?</Link></p>
                <p style={{ color: 'lightgray' }}>Almog Liebermensch 2024 ©</p>
            </div>
        </div>

    </>);
}

export default Login;
import { FunctionComponent, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { addCustomItemToProject, getProjectByName, updateProject } from "../services/projectServices";
import Project from "../interfaces/Project";
import { useFormik } from "formik";
import * as yup from "yup";
import Invoice from "../interfaces/Invoice";
import { addInvoice, deleteInvoice, getInvoicesByName, updateInvoice } from "../services/invoiceServices";
import { successMsg } from "../services/feedbacks";
import { getManagerById, getManagerProfile } from "../services/managerServices";
import Manager from "../interfaces/Manager";


interface InvocieEntryProps {

}
const selectionArray = [
    { code: 1101, description: "ע' מפיקת פוסט" },
    { code: 1102, description: "דגימה+עוזר עריכה+סידור" },
    { code: 1103, description: "השכרת שטח אחסון" },
    { code: 1104, description: "גיבוי חומרי גלם" },
    { code: 1105, description: "קלטות lto" },
    { code: 1106, description: "חדר אופליין - ויטיארים" },
    { code: 1107, description: "עורך אופליין- ויטיארים" },
    { code: 1108, description: "עורך אופליין- מוקלטות" },
    { code: 1109, description: "קריינות" },
    { code: 1110, description: "דיגיטציה אונליין" },
    { code: 1111, description: "חדר דיגיטציה on line" },
    { code: 1112, description: "עורך אונליין" },
    { code: 1113, description: "חדר אונליין" },
    { code: 1114, description: "סאונד+חדר" },
    { code: 1115, description: "תרגום" },
    { code: 1116, description: "אפטר אפקט" },
    { code: 1117, description: "ייעוץ מוזיקלי" },
    { code: 1118, description: "אישורי אקום" },
    { code: 1119, description: 'ק"ק' },
    { code: 1120, description: "חומרי ארכיון" },
];
const monthOrder: { [key: string]: number } = {
    'ינואר': 1,
    'פברואר': 2,
    'מרץ': 3,
    'אפריל': 4,
    'מאי': 5,
    'יוני': 6,
    'יולי': 7,
    'אוגוסט': 8,
    'ספטמבר': 9,
    'אוקטובר': 10,
    'נובמבר': 11,
    'דצמבר': 12,
};
const InvocieEntry: FunctionComponent<InvocieEntryProps> = () => {
    let navigate = useNavigate();
    let [manager, setManager] = useState<Manager | null>(null);
    let [invoices, setInvoices] = useState<Invoice[]>([]);
    let [project, setProject] = useState<Project>({
        name: "",
        mainProducer: "",
        customItems: []
    });
    let [change, setChange] = useState<boolean>(false);
    let [projectName, setProjectName] = useState<string>("");
    const [selectedCode, setSelectedCode] = useState<number | null>(null);
    const [selectedDescription, setSelectedDescription] = useState<string>("");
    const [orderBy, setOrderBy] = useState<string>("");
    const [searchQuery, setSearchQuery] = useState<string>("");
    const [originalInvoices, setOriginalInvoices] = useState<Invoice[]>([]);
    const [selectedMonth, setSelectedMonth] = useState<string>("");
    const [editingInvoice, setEditingInvoice] = useState<Invoice | null>(null);
    const { projectNameParams } = useParams();

    const handleCodeChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
        const selectedCode = parseInt(event.target.value, 10);
        const selectedItem = selectionArray.find(item => item.code === selectedCode)
            || invoices.find((item: { code?: number }) => item?.code === selectedCode);
        const selectedDescription = selectedItem?.description || "";

        setSelectedCode(selectedCode);
        setSelectedDescription(selectedDescription);
        formik.setFieldValue("description", selectedDescription);
        return selectedCode;
    };

    const formik = useFormik({
        initialValues: {
            managerName: "", code: 0, description: "", name: "", type: "", proofNumber: 0, totalBefore: 0, totalAfter: 0, invoiceDate: "", paymentDate: "", fillDate: "", month: "",
        },
        validationSchema: yup.object({
            code: yup.number().required(),
            description: yup.string().required(),
            name: yup.string(),
            type: yup.string(),
            proofNumber: yup.number(),
            totalBefore: yup.number(),
            totalAfter: yup.number(),
            invoiceDate: yup.string(),
            paymentDate: yup.string(),
            fillDate: yup.string(),
            month: yup.string(),
            // notes: yup.string(),
        }),

        onSubmit: (values: Invoice) => {

            const newInvoice = {
                ...values, managerName: manager?.name as string, projectName: manager?.projectName as string, code: selectedCode as number, description: selectedDescription as string, month: selectedMonth as string
            };

            if (editingInvoice) {
                // If there is a report for editing, update it instead of adding a new one
                updateInvoice({ ...editingInvoice, ...newInvoice })
                    .then((res) => {
                        successMsg("החשבונית עודכנה בהצלחה");
                        setChange(!change);
                        setEditingInvoice(null);
                        // Manually reset form values to empty values
                        formik.setValues({
                            managerName: "", code: 0, description: "", name: "", type: "", proofNumber: 0, totalBefore: 0, totalAfter: 0, invoiceDate: "", paymentDate: "", fillDate: "", month: "", notes: "",
                        });
                    })
                    .catch((err) => console.log(err));
            } else {
                // If there is no report for editing, add a new one
                addInvoice(newInvoice)
                    .then((res) => {
                        successMsg("החשבונית נוספה בהצלחה");
                        setChange(!change);
                        // Manually reset form values to empty values
                        formik.setValues({
                            managerName: "", code: 0, description: "", name: "", type: "", proofNumber: 0, totalBefore: 0, totalAfter: 0, invoiceDate: "", paymentDate: "", fillDate: "", month: "", notes: ""
                        });
                    })
                    .catch((err) => console.log(err));
            }
        }
    });

    useEffect(() => {
        const fetchData = async () => {
            try {
                let projectName;
                let pro, man, managerResponse, invoicesResponse, projectResponse;

                if (projectNameParams) {
                    pro = await getProjectByName(projectNameParams);
                    setProject(pro.data);

                    man = await getManagerById(pro.data.postId as string);
                    setManager(man.data);

                    projectName = pro.data.name;
                    setProjectName(projectNameParams);

                    invoicesResponse = await getInvoicesByName(man.data.name);
                    setInvoices(invoicesResponse.data);
                    setOriginalInvoices(invoicesResponse.data);
                } else {
                    managerResponse = await getManagerProfile();
                    setManager(managerResponse.data);

                    projectName = managerResponse.data.projectName;
                    setProjectName(projectName);

                    invoicesResponse = await getInvoicesByName(managerResponse.data.name);
                    setInvoices(invoicesResponse.data);
                    setOriginalInvoices(invoicesResponse.data);
                }

                projectResponse = await getProjectByName(projectName);
                if (typeof projectResponse === 'object') {
                    projectName = projectResponse.data.name;
                    setProject({ ...projectResponse.data });
                }
            } catch (error) {
                console.error(error);
            }
        };

        fetchData();

    }, [change, projectNameParams]);

    const resetTable = () => {
        setInvoices(originalInvoices);
        setOrderBy("");
        setSearchQuery("");
        setChange(!change);
    };

    const handleApprovalChange = async (index: number, approvalType: 'main' | 'post') => {
        try {
            const updatedInvoices = [...invoices];
            const updatedInvoice = { ...updatedInvoices[index] };

            if (approvalType === 'main') {
                updatedInvoice.mainApproval = !updatedInvoice.mainApproval;
            } else if (approvalType === 'post') {
                updatedInvoice.postApproval = !updatedInvoice.postApproval;
            }

            await updateInvoice(updatedInvoice);

            updatedInvoices[index] = updatedInvoice;
            setInvoices(updatedInvoices);

            // תוסיף את הקוד המתאים לשמירה בשרת
            // await saveApprovalStatus(updatedInvoice);
        } catch (error) {
            console.error(error);
        }
    };
    const startEditingInvoice = (invocie: Invoice) => {
        setEditingInvoice(invocie);
        setSelectedCode(invocie.code);
        setSelectedDescription(invocie.description);
        formik.setValues({
            managerName: invocie.managerName,
            code: invocie.code,
            description: invocie.description,
            name: invocie.name,
            type: invocie.type,
            proofNumber: invocie.proofNumber,
            totalBefore: invocie.totalBefore,
            totalAfter: invocie.totalAfter,
            invoiceDate: invocie.invoiceDate,
            paymentDate: invocie.paymentDate,
            fillDate: invocie.fillDate,
            month: invocie.month,
            notes: invocie.notes,
        });
    };
    const calculatePaymentDate = (selectedMonth: string) => {
        const shotefValue = project?.shotef || 0;
        const monthNumber = monthOrder[selectedMonth] || 1;
        const firstDayOfMonth = new Date(new Date().getFullYear(), monthNumber - 1, 1);
        firstDayOfMonth.setDate(firstDayOfMonth.getDate() + shotefValue + 31);
        const paymentDate = firstDayOfMonth.toISOString().split('T')[0];

        return paymentDate;
    };

    const handleMonthChange = (e: any) => {
        const selectedMonth = e.target.value;
        const paymentDate = calculatePaymentDate(selectedMonth);

        formik.setFieldValue('paymentDate', paymentDate);
        setSelectedMonth(selectedMonth);
    };

    const calculateTotalWithVAT = () => {

        const VATPercentage = 0.17; // האחוז המקובל למע"מ
        const totalBeforeVAT = Number(formik.values.totalBefore);
        // const totalWithVAT = (totalBeforeVAT * 1000 * (1 + VATPercentage)) / 1000; 
        const totalWithVAT = totalBeforeVAT * 2;
        formik.setFieldValue('totalAfter', totalWithVAT.toFixed(2), true);
    };

    const handleTotalBeforeChange = (e: any) => {
        formik.handleChange(e);
        calculateTotalWithVAT();
    };


    return (
        <div className="editorWorkPanel">
            <div className="row editorWorkHeadline">
                <img src="/Monica_Logo@0.25x.png" alt="Logo" style={{ height: '60px', width: '200px' }} />
                <h1>הזנת חשבונית</h1>
            </div>
            <div className="row mt-2">
                <h2 className="mt-2">הוספת חשבונית</h2>
                <form onSubmit={formik.handleSubmit}>
                    <table className="table table-striped">
                        <thead>
                            <tr className="table-info">
                                <th scope="col">מס׳ סעיף</th>
                                <th scope="col">שם סעיף</th>
                                <th scope="col">שם</th>
                                <th scope="col">סוג מסמך</th>
                                <th scope="col"># אסמכתא</th>
                                <th scope="col">סה"כ לפני מע"מ</th>
                                <th scope="col">סה"כ כולל מע"מ</th>
                                <th scope="col">תאריך חשבונית</th>
                                <th scope="col">תאריך לתשלום</th>
                                <th scope="col">תאריך הזנה</th>
                                <th scope="col">חודש עבודה</th>
                                <th scope="col">הערות</th>
                                <th scope="col"></th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>
                                    <select
                                        className="custom-select mt-1"
                                        id="floatingCode"
                                        name="code"
                                        value={selectedCode || ''}
                                        onChange={handleCodeChange}
                                    >
                                        <option value="" selected>
                                            בחר/י
                                        </option>
                                        {selectionArray.map(item => (
                                            <option key={item.code} value={item.code}>
                                                {item.code}
                                            </option>
                                        ))}
                                        {project &&
                                            project.customItems?.map((item: { code: number; description: string }) => (
                                                <option key={item.code} value={item.code}>
                                                    {item.code}
                                                </option>
                                            ))}
                                    </select>
                                </td>
                                <td>
                                    <input
                                        type="text"
                                        className="input-sm"
                                        id="floatingDescription"
                                        name="description"
                                        value={formik.values.description}
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        style={{ width: 'fit-content' }}
                                        readOnly
                                    />
                                    {formik.errors.description && formik.touched.description && (
                                        <div className="error-message" style={{ color: 'red', fontSize: '14px' }}>עליך לבחור סעיף</div>
                                    )}
                                </td>
                                <td>
                                    <input
                                        type="text"
                                        className="input-sm w-75"
                                        id="floatingName"
                                        name="name"
                                        value={formik.values.name}
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                    />
                                    {formik.errors.name && formik.touched.name && (
                                        <div className="error-message">{formik.errors.name}</div>
                                    )}
                                </td>
                                <td><input type="text" className="input-sm w-75" id="floatingType" name="type"
                                    value={formik.values.type}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                />
                                    {formik.errors.name && formik.touched.name && (
                                        <div className="error-message">{formik.errors.name}</div>
                                    )}
                                </td>
                                <td><input type="number" className="input-sm w-75" id="floatingProofNumber" name="proofNumber"
                                    value={formik.values.proofNumber}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                /> </td>
                                <td><input
                                    type="number"
                                    className="input-sm w-75"
                                    id="floatingTotalBefore"
                                    name="totalBefore"
                                    value={formik.values.totalBefore}
                                    onChange={handleTotalBeforeChange}
                                    onBlur={formik.handleBlur}
                                /> </td>
                                <td> <input type="number" className="input-sm w-75" id="floatingTotalAfter" name="totalAfter" readOnly
                                    value={formik.values.totalAfter}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                /></td>
                                <td> <input type="date" className="input-sm w-75" id="floatingInvoiceDate" name="invoiceDate"
                                    value={formik.values.invoiceDate}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                /></td>
                                <td> <input type="text" className="input-sm w-75" id="floatingPaymentDate" name="paymentDate"
                                    value={formik.values.paymentDate}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                /></td>
                                <td> <input type="date" className="input-sm w-75" id="floatingFillDate" name="fillDate"
                                    value={formik.values.fillDate}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                /></td>
                                <td>
                                    <select
                                        className="custom-select mt-1"
                                        id="floatingMonth"
                                        name="month"
                                        value={selectedMonth || ''}
                                        onChange={handleMonthChange}
                                    >
                                        <option value="" selected>
                                            בחר/י
                                        </option>
                                        {Object.keys(monthOrder).map((month) => (
                                            <option key={month} value={month}>
                                                {month}
                                            </option>
                                        ))}
                                    </select>
                                    {formik.errors.month && formik.touched.month && (
                                        <div className="error-message" style={{ color: 'red', fontSize: '14px' }}>
                                            {formik.errors.month}
                                        </div>
                                    )}
                                </td>
                                <td> <input type="text" className="input-sm w-75" id="floatingNotes" name="notes"
                                    value={formik.values.notes}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                /></td>
                                <td>
                                    <button type="submit" className="btn btn-info btn-sm">
                                        שמור
                                    </button>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </form>
            </div>
            <div className="row mx-auto mt-4 w-50">
                <h2 className="mt-2 mb-4">חשבוניות</h2>
                <div className="col-2 pt-1">
                    <label htmlFor="floatingEmail">תצוגה לפי:</label>
                </div>
                <div className="col-2 mt-1">
                    <select className="" id="" name="" value={orderBy}
                        onChange={(e) => setOrderBy(e.target.value)}>
                        <option value="" selected>בחר/י</option>
                        <option value="name">שם</option>
                        <option value="code">מס׳ סעיף</option>
                        <option value="description">שם סעיף</option>
                        <option value="type">סוג מסמך</option>
                        <option value="month">חודש עבודה</option>
                    </select>
                </div>
                <div className="col-1 pt-1">
                    <label htmlFor="search">חיפוש:</label>
                </div>
                <div className="col-2">
                    <input
                        type="text"
                        className="form-control form-control-sm"
                        id="search"
                        value={searchQuery}
                        onChange={(e) => setSearchQuery(e.target.value)}
                    />
                </div>
                <div className="col-1">
                    <button
                        className="btn btn-secondary btn-sm"
                        onClick={resetTable}
                    >
                        נקה
                    </button>
                </div>
                <div className="col-2 pt-1">
                    <h5>שוטף: {project?.shotef}</h5>
                </div>
            </div>
            <table className="table table-striped mt-3">
                <thead>
                    <tr className="table-primary">
                        <th scope="col">מס׳ סעיף</th>
                        <th scope="col">שם סעיף</th>
                        <th scope="col">שם</th>
                        <th scope="col">סוג מסמך</th>
                        <th scope="col"># אסמכתא</th>
                        <th scope="col">סה"כ לפני מע"מ</th>
                        <th scope="col">סה"כ כולל מע"מ</th>
                        <th scope="col">תאריך חשבונית</th>
                        <th scope="col">תאריך לתשלום</th>
                        <th scope="col">תאריך הזנה</th>
                        <th scope="col">מפיק פוסט</th>
                        <th scope="col">מפיקה ראשית</th>
                        <th scope="col">חודש עבודה</th>
                        <th scope="col">הערות</th>
                        <th scope="col"></th>
                        <th scope="col"></th>
                    </tr>
                </thead>
                <tbody>
                    {invoices
                        .filter((invoice) =>
                            Object.values(invoice).some(
                                (value) =>
                                    typeof value === "string" && value.toLowerCase().includes(searchQuery.toLowerCase())
                            )
                        )
                        .sort((a, b) => {
                            switch (orderBy) {
                                case "name":
                                    return (a.name || "").localeCompare(b.name || "");
                                case "code":
                                    return (a.code || 0) - (b.code || 0);
                                case "description":
                                    return (a.description || "").localeCompare(b.description || "");
                                case "type":
                                    return (a.type || "").localeCompare(b.type || "");
                                case "month":
                                    return (monthOrder[a.month || ""] || 0) - (monthOrder[b.month || ""] || 0);
                                default:
                                    return 0;
                            }
                        })
                        .map((invoice, index) => (
                            <tr key={index}>
                                <td>{invoice.code}</td>
                                <td>{invoice.description}</td>
                                <td>{invoice.name}</td>
                                <td>{invoice.type}</td>
                                <td>{invoice.proofNumber}</td>
                                <td>{invoice.totalBefore}</td>
                                <td>{invoice.totalAfter}</td>
                                <td>{invoice.invoiceDate}</td>
                                <td>{invoice.paymentDate}</td>
                                <td>{invoice.fillDate}</td>
                                <td> <input
                                    type="checkbox"
                                    className="input-sm w-50"
                                    name="confirm"
                                    checked={invoice.mainApproval}
                                    onChange={() => handleApprovalChange(index, 'main')}
                                /></td>
                                <td> <input
                                    type="checkbox"
                                    className="input-sm w-50"
                                    name="confirm"
                                    checked={invoice.postApproval}
                                    onChange={() => handleApprovalChange(index, 'post')}
                                /></td>
                                <td>{invoice.month}</td>
                                <td>{invoice.notes}</td>
                                <td>
                                    <button className="btn btn-warning btn-sm" onClick={() => startEditingInvoice(invoice)}>
                                        ערוך
                                    </button>
                                </td>
                                <td>
                                    <button
                                        className="btn btn-secondary btn-sm"
                                        onClick={() => {
                                            console.log(invoice);
                                            console.log(invoice._id);
                                            deleteInvoice(invoice._id as string);
                                            setChange(!change);
                                        }}
                                    >
                                        הסר
                                    </button>
                                </td>
                            </tr>
                        ))}
                </tbody>
            </table>
            <div className="row empsSalRepOptions mx-auto col-10 col-md-8 col-lg-6" >
                <p onClick={() => navigate(-1)} style={{
                    color: 'blue',
                    textDecoration: 'underline',
                    cursor: 'pointer',
                }}>חזור</p>
                <p style={{ color: 'lightgray' }}>Almog Liebermensch 2024 ©</p>

            </div>
        </div>

    );
}

export default InvocieEntry;
import { FunctionComponent, useEffect, useState } from "react";
import User from "../interfaces/User";
import { getProjectName, getWorkers, updateUser } from "../services/userServices";
import { getProjectByName, updateProject } from "../services/projectServices";
import Project from "../interfaces/Project";

interface ListOfAvilabaleProps {
    refresh: Function;
}

const ListOfAvilabale: FunctionComponent<ListOfAvilabaleProps> = ({ refresh }) => {
    let [project, setProject] = useState<Project | null>(null);
    let [change, setChange] = useState<boolean>(false);
    let [workers, setWorkers] = useState<User[]>([]);
    const [projectName, setProjectName] = useState<string>("");
    const [selectedOption, setSelectedOption] = useState<string>("הכל");

    useEffect(() => {
        getWorkers().then((res) => setWorkers(res.data)).catch((err) => console.log(err))
        let name = getProjectName();
        setProjectName(name);
        getProjectByName(name).then((res) => {
            setProject({ ...res.data });
        }).catch((error) => console.log(error));
    }, [change]);


    const filteredUsers = workers.filter((user) => {
        const positionCondition = selectedOption === "הכל" || user.position === selectedOption;

        return positionCondition;
    });


    return (<>
        <div className="row">
            <div className="col-1 mx-4">
                {/* <label htmlFor="positionFilter">תפקיד:</label> */}
                <select
                    id="positionFilter"
                    value={selectedOption}
                    onChange={(e) => setSelectedOption(e.target.value)}
                >
                    <option value="הכל">הכל</option>
                    <option value="עורך וידאו-אונליין">עורך וידאו-אונליין</option>
                    <option value="עורך וידאו-אופליין">עורך וידאו-אופליין</option>
                    <option value="עורך סאונד">עורך סאונד</option>
                </select>
            </div>
        </div>
        <div className="row w-100 mx-auto">
            <div className="table-responsive" style={{ maxHeight: "350px", overflowY: "auto" }}>
                {filteredUsers.length ? (
                    <table className="table table-hover mt-2">
                        <thead className="table-danger" style={{ position: "sticky", top: 0, zIndex: 1 }}>
                            <tr>
                                <th scope="col">שם מלא</th>
                                <th scope="col">תפקיד</th>
                                <th scope="col">טלפון</th>
                                <th scope="col">זמינות מיידית</th>
                                <th scope="col">פרויקט נוכחי</th>
                                <th scope="col">ז׳אנר</th>
                                <th scope="col">שנות וותק</th>
                                <th scope="col">שכר שעתי מוצע</th>
                                <th scope="col">עבודות לדוגמא</th>
                                <th scope="col">הערות</th>
                                <th scope="col"></th>
                            </tr>
                        </thead>
                        <tbody>
                            {filteredUsers?.filter(user => user.avilabaleNow !== "" && user.projectName !== projectName).map((user: User) => (
                                <tr key={user.id} >
                                    <td>{user.name}</td>
                                    <td>{user.position}</td>
                                    <td>{user.phone}</td>
                                    <td>{user.avilabaleNow}</td>
                                    <td>{user.projectName}</td>
                                    <td>{user.genre}</td>
                                    <td>{user.experience}</td>
                                    <td>{user.wage}</td>
                                    <td>
                                        <a href={user.example1} target="_blank">{user.example1}</a> ,
                                        <a href={user.example2} target="_blank">{user.example2}</a> ,
                                        <a href={user.example3} target="_blank">{user.example3}</a>
                                    </td>
                                    <td>{user.freeText}</td>
                                    <td>
                                        <button className="btn btn-danger btn-sm" onClick={() => {
                                            user.secProject = projectName;
                                            updateUser({ ...user })
                                            project?.workers?.push(user)
                                            updateProject(project as Project)
                                            setChange(!change)
                                            refresh();
                                        }} >הוסף</button>
                                    </td>
                                </tr>
                            ))}

                        </tbody>
                    </table>
                ) : (
                    <p>  </p>
                )}
            </div>
        </div>
    </>);
}

export default ListOfAvilabale;
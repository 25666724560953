import React from 'react';
import './App.css';
import { ToastContainer } from 'react-toastify';
import { HashRouter as BrowserRouter, Routes, Route } from "react-router-dom";
import Login from './components/Login';
import SignUser from './components/SignUser';
import SignManager from './components/SignManager';
import MainDashboard from './components/MainDashboard';
import PostDashboard from './components/PostDashboard';
import EditorDashboard from './components/EditorDashboard';
import ResetPassword from './components/ResetPassword';
import Dash from './components/Dash';
import NewProject from './components/NewProject';
import SelectEmployees from './components/SelectEmployees';
import EditorWorkReport from './components/EditorWorkReport';
import UpdateUser from './components/UpdateUser';
import UpdateManager from './components/UpdateManager';
import PageNotFound from './components/PageNotFound';
import UpdateProject from './components/UpdateProject';
import Gantt from './components/Gantt';
import EditorSalaryReport from './components/EditorSalaryReport';
import SalaryReport from './components/SalaryReport';
import InvocieEntry from './components/InvocieEntry';
import AssistantDashboard from './components/AssistantDashboard';
import ProjectDashboard from './components/ProjectDashboard';
import BudgetBuilding from './components/BudgetBuilding';
import MonthlyReport from './components/MonthlyReport';
import WorkSchedule from './components/WorkSchedule';
import EditorScreen from './components/EditorScreen';
import EpisodeScreen from './components/EpisodeScreen';
import UpdateManagerNew from './components/UpdateManagerNew';
import Episodes from './components/Episodes';



function App() {
  return (
    <div className="App">
      <ToastContainer />
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Login />} />
          <Route path="/signUser" element={<SignUser />} />
          <Route path="/signManager" element={<SignManager />} />
          <Route path="/mainDashboard" element={<MainDashboard />} />
          <Route path="/postDashboard" element={<PostDashboard />} />
          <Route path="/projectDashboard" element={<ProjectDashboard />} />
          <Route path="/projectDashboard/:projectName" Component={ProjectDashboard} />
          <Route path="/episodes" element={<Episodes />} />
          <Route path="/episodes/:projectName" Component={Episodes} />
          <Route path="/episodeScreen" element={<EpisodeScreen />} />
          <Route path="/episodeScreen/:projectNameParams/:episode" Component={EpisodeScreen} />
          <Route path="/assistantDashboard" element={<AssistantDashboard />} />
          <Route path="/editorDashboard" element={<EditorDashboard />} />
          <Route path="/resetPassword" element={<ResetPassword />} />
          <Route path="/dash" element={<Dash />} />
          <Route path="/monthlyReport" element={<MonthlyReport />} />
          <Route path="/monthlyReport/:projectName" Component={MonthlyReport} />
          <Route path="/newProject" element={<NewProject />} />
          <Route path="/selectEmployees" element={<SelectEmployees />} />
          <Route path="/editorWorkReport" element={<EditorWorkReport />} />
          <Route path="/editorSalaryReport" element={<EditorSalaryReport />} />
          <Route path="/salaryReport" element={<SalaryReport />} />
          <Route path="/salaryReport/:projectName" Component={SalaryReport} />
          <Route path="/updateUser" element={<UpdateUser />} />
          <Route path="/updateManager" element={<UpdateManager />} />
          <Route path="/updateManagerNew" element={<UpdateManagerNew />} />
          <Route path="/updateProject" element={<UpdateProject />} />
          <Route path="/updateProject/:projectName" Component={UpdateProject} />
          <Route path="*" element={<PageNotFound />} />
          <Route path="/gantt" element={<Gantt />} />
          <Route path="/invocieEntry" element={<InvocieEntry />} />
          <Route path="/invocieEntry/:projectNameParams" Component={InvocieEntry} />
          <Route path="/budgetBuilding" element={<BudgetBuilding />} />
          <Route path="/workSchedule" element={<WorkSchedule />} />
          <Route path="/workSchedule/:projectName" Component={WorkSchedule} />
          <Route path="/editorScreen" element={<EditorScreen />} />
          <Route path="/editorScreen/:projectNameParams/:editorName" element={<EditorScreen />} />
          <Route path="/editorScreen/:projectNameParams" element={<EditorScreen />} />

        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;

import { FunctionComponent, useEffect, useState } from "react";
import { getUserProfile } from "../services/userServices";
import User from "../interfaces/User";
import { getReportsByName } from "../services/reportServices";
import { useNavigate } from "react-router-dom";
import Report from "../interfaces/Report";
import "../css/editorWorkReport.css"

interface EditorSalaryReportProps {

}

const EditorSalaryReport: FunctionComponent<EditorSalaryReportProps> = () => {
    let [user, setUser] = useState<User>({
        username: "",
        password: "",
    });
    const navigate = useNavigate();
    const [totalMoney, setTotalMoney] = useState<number>(0);
    const [totalHours, setTotalHours] = useState<string>("0:00");
    const [reports, setReports] = useState<Report[]>([]);
    const [change, setChange] = useState<boolean>(false);
    const [month, setMonth] = useState<string>(getInitialMonth());

    const calculateTotalHours = (reports: Report[]): string => {
        const relevantReports = reports.filter(report => report.episode !== "מחלה" && report.episode !== "חופש" && report.episode !== "כללי");
        const totalMinutes = relevantReports
            .map(report => {
                const [hours, minutes] = (report.total || "0:00").split(":").map(Number);
                return hours * 60 + minutes;
            })
            .reduce((acc, curr) => acc + curr, 0);

        const hours = Math.floor(totalMinutes / 60);
        const minutes = totalMinutes % 60;

        const formattedHours = String(hours).padStart(2, '0');
        const formattedMinutes = String(minutes).padStart(2, '0');

        return `${formattedHours}:${formattedMinutes}`;
    };
    useEffect(() => {
        getUserProfile()
            .then((res) => {
                setUser(res.data);
            })
            .catch((error) => console.log(error));

        getReportsByName(user?.name as string)
            .then((res) => {
                setReports(res.data);
                setChange(!change);
            })
            .catch((error) => console.log(error));
    }, [change, user]);

    useEffect(() => {
        const filteredReports = reports
            .filter((report: Report) => {
                // בדיקה אם התאריך קיים ואם כן, משיכת החודש מהתאריך בפורמט "יום.חודש.שנה"
                const reportMonth = report.date ? report.date.split('.')[1] : '';
                return reportMonth === month;
            });

        const totalDailySalary = filteredReports
            .map(report => report.dailySalary ?? 0)
            .reduce((acc, curr) => (acc ?? 0) + (curr ?? 0), 0);
        setTotalMoney(totalDailySalary);

        const totalHoursFormatted = calculateTotalHours(filteredReports);
        setTotalHours(totalHoursFormatted);
    }, [reports, month]);

    const totalMoneyFormatted = totalMoney.toLocaleString('en-US', {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
    });
    function getInitialMonth(): string {
        const now = new Date();
        const monthNames = [
            "ינואר", "פברואר", "מרץ", "אפריל", "מאי", "יוני",
            "יולי", "אוגוסט", "ספטמבר", "אוקטובר", "נובמבר", "דצמבר"
        ];
        return monthNames[now.getMonth()]; // החודש הנוכחי בתור string
    }
    return (
        <div className="editorWorkPanel">
            <div className="row editorWorkHeadline">
                <img src="/Monica_Logo@0.25x.png" alt="Logo" style={{ height: '60px', width: '200px' }} />
                <h1>דוח שכר עובד</h1>
            </div>
            <div className="row">
                <div className="col-1"></div>
                <div className="form col-8 col-md-8 col-lg-6 mt-5">
                    <form>
                        <div className="form-floating row mb-3">
                            <div className="col-4 pt-2">
                                <label htmlFor="floatingInput">שם עובד:</label>
                            </div>
                            <div className="col-8">
                                <input type="text" className="form-control" id="floatingInput" name="name"
                                    value={user.name}
                                />
                            </div>
                        </div>
                        <div className="form-floating row mb-3">
                            <div className="col-4 pt-2">
                                <label htmlFor="floatingPassword">תפקיד:</label>
                            </div>
                            <div className="col-8">
                                <input type="text" className="form-control" id="floatingPost" name="position"
                                    value={user.position}
                                />
                            </div>
                        </div>
                        <div className="form-floating row mb-3">
                            <div className="col-4 pt-2">
                                <label htmlFor="floatingActualWage">שכר שעתי:</label>
                            </div>
                            <div className="col-8">
                                <input type="text" className="form-control" id="floatingActualWage" name="actualWage"
                                    value={user.actualWage}
                                />
                            </div>
                        </div>
                        <div className="form-floating row mb-3">
                            <div className="col-4 pt-2">
                                <label htmlFor="floatingInput">חודש:</label>
                            </div>
                            <div className="col-8">
                                <select className="episodeSel custom-select mt-1" id="episodeSel" name="month"
                                    onChange={m => setMonth(m.target.value)}>
                                    <option selected>{month}</option>
                                    <option value="ינואר">ינואר</option>
                                    <option value="פברואר">פברואר</option>
                                    <option value="מרץ">מרץ</option>
                                    <option value="אפריל">אפריל</option>
                                    <option value="מאי">מאי</option>
                                    <option value="יוני">יוני</option>
                                    <option value="יולי">יולי</option>
                                    <option value="אוגוסט">אוגוסט</option>
                                    <option value="ספטמבר">ספטמבר</option>
                                    <option value="אוקטובר">אוקטובר</option>
                                    <option value="נובמבר">נובמבר</option>
                                    <option value="דצמבר">דצמבר</option>
                                </select>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
            <div className="row">
                <div className="table-responsive" style={{ maxHeight: "500px", overflowY: "auto" }}>
                    <table className="table table-striped mt-2" style={{ position: "sticky" }}>
                        <thead style={{ position: "sticky", top: 0, zIndex: 1 }}>
                            <tr className="table-info">
                                <th scope="col">תאריך</th>
                                <th scope="col">פרויקט</th>
                                <th scope="col">פרק</th>
                                <th scope="col">שעת התחלה</th>
                                <th scope="col">שעת סיום</th>
                                <th scope="col">שעות עבודה</th>
                                <th scope="col">שכר יומי</th>
                                <th scope="col">הערות</th>
                            </tr>
                        </thead>
                        <tbody style={{ overflowY: "auto" }}>
                            {reports?.filter((report: Report) => report.monthName === month)
                                .map((report: Report) => (
                                    <tr key={report._id} >
                                        <td>{report.date}</td>
                                        <td>{report.projectName}</td>
                                        <td>{report.episode}</td>
                                        <td>{report.start}</td>
                                        <td>{report.end}</td>
                                        <td>{isNaN(parseFloat(report.total || '0')) ? 0 : parseFloat(report.total || '0')}</td>
                                        <td>₪ {report.dailySalary}</td>
                                        <td>{report.notes}</td>
                                    </tr>
                                ))}
                        </tbody>
                    </table>
                </div>
            </div>
            <div className="row mt-3">
                <h3 className="m-2">שעות עבודה: {totalHours}</h3> {/* שימו לב לפונקציה toFixed שמקנה את המספר ל-2 ספרות אחרי הנקודה */}
                <h3>סה״כ: {totalMoneyFormatted} ₪</h3>
            </div>
            <button
                className="btn btn-info my-3 w-25 mt-5 mb-5">
                הפקת דוח
            </button>
            <div className="row empsSalRepOptions mx-auto col-10 col-md-8 col-lg-6">
                <p onClick={() => navigate(-1)} style={{
                    color: 'blue',
                    textDecoration: 'underline',
                    cursor: 'pointer',
                }}>חזור</p>
                <p style={{ color: 'lightgray' }}>Almog Liebermensch 2024 ©</p>
            </div>
        </div >
    );
}

export default EditorSalaryReport;
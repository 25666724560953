import { FunctionComponent, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { getPosition } from "../services/managerServices";

interface DashProps {

}

const Dash: FunctionComponent<DashProps> = () => {
    let navigate = useNavigate();

    useEffect(() => {
        const pos = getPosition(); // אני מניח שכאן אתה כבר משנה את ה-state בהתאם למידע שמתקבל מהשרת
        if (pos === "מפיקה ראשית") {
            navigate("/mainDashboard");
        } else if (pos === "מפיק פוסט") {
            navigate("/postDashboard");
        } else if (pos === "ע. מפיק פוסט") {
            navigate("/assistantDashboard");
        } else {
            // מקרה ברירת המחדל, לדוגמה - אם אין מידע או עמוד אחר
            navigate("/editorDashboard");
        }
    }, []); // אני מניח שהניווט אמור להתרחש כאשר משתנה currentUser

    return null; // אין צורך להציג משהו ברכיב זה, כי הניווט יתבצע באופן אוטומטי
};
export default Dash;
import axios from "axios";
import User from "../interfaces/User";
import jwt_decode from "jwt-decode";
import Manager from "../interfaces/Manager";


const api: string = process.env.REACT_APP_API || "";


export function checkUser(userToCheck: User | Manager) {
    return axios.post(`${api}/userLogin`, userToCheck);
}

// add user
export function addUser(userToAdd: User) {
    return axios.post(`${api}/userRegister`, userToAdd);
}

// get user by Id
export function getUserById(id: string) {
    return axios.get(`${api}/users/${id}`);
}

// update user
export function updateUser(newDetails: User) {
    return axios.put(`${api}/users/${newDetails.id}`, newDetails);
}

// get user profile
export function getUserProfile() {
    return axios.get(`${api}/userProfile`, {
        headers: { Authorization: JSON.parse(sessionStorage.getItem("userData") as string).token }
    })
}

// get userId from token
export function getUserId() {
    let token = JSON.parse(sessionStorage.getItem("userData") as string).token;
    return (jwt_decode(token) as any).id;
}
// get position from token
export function getPosition() {
    let token = JSON.parse(sessionStorage.getItem("userData") as string).token;
    return (jwt_decode(token) as any).position;
}
// get all workers
export function getWorkers() {
    return axios.get(`${api}/users`);
}

// get user by project name
export function getUsersbyProject(name: string) {
    return axios.get(`${api}/users/projectName/${name}`);
}
export function getUsersbySecProject(name: string) {
    return axios.get(`${api}/users/secProject/${name}`);
}

// get project name from token
export function getProjectName() {
    let token = JSON.parse(sessionStorage.getItem("userData") as string).token;
    return (jwt_decode(token) as any).projectName;
}
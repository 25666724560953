import { FunctionComponent, useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import User from "../interfaces/User";
import "../css/mainDashboard.css"
import { getManagerProfile } from "../services/managerServices";
import Project from "../interfaces/Project";
import { getUsersbyProject } from "../services/userServices";
import Manager from "../interfaces/Manager";
import { getProjectByName } from "../services/projectServices";
import Gantt from "./Gantt";
import Report from "../interfaces/Report";
import { getReportsByProjectName } from "../services/reportServices";

interface ProjectDashboardProps {

}

const ProjectDashboard: FunctionComponent<ProjectDashboardProps> = () => {
    let [manager, setManager] = useState<Manager | null>(null);
    let [userInfo, setUserInfo] = useState<User[]>([]);
    let [gantt, setGantt] = useState<number>(0);
    let [change, setChange] = useState<boolean>(false);
    let [project, setProject] = useState<Project | null>(null);
    let [reports, setReports] = useState<Report[]>([]);
    let [editorsCount, setEditorsCount] = useState<number>(0);
    let [episodesInWork, setEpisodesInWork] = useState<number[]>([]);

    const { projectName } = useParams();

    const current = new Date();
    const date = `${current.getDate()}/${current.getMonth() + 1}/${current.getFullYear()}`;

    useEffect(() => {
        const fetchData = async () => {
            try {
                const managerProfile = await getManagerProfile();
                setManager(managerProfile.data);

                const projectData = await getProjectByName(projectName as string);
                setProject(projectData.data);

                const usersData = await getUsersbyProject(projectName as string);
                setUserInfo(usersData.data);

                const editorCount = usersData.data.filter((user: User) => user.position === "עורך וידאו-אופליין").length;
                setEditorsCount(editorCount);

                const reportsResponse = await getReportsByProjectName(projectName as string);
                setReports(reportsResponse.data);

                const uniqueEpisodesSet = new Set<number>();

                reportsResponse.data.forEach((report: Report) => {
                    if (report.episode !== undefined) {
                        uniqueEpisodesSet.add(Number(report.episode));
                    }
                });
                const episodesWithReport = Array.from(uniqueEpisodesSet)
                    .sort((a, b) => a - b)
                    .filter((episode) => !isNaN(episode));

                setEpisodesInWork(episodesWithReport);
            } catch (error) {
                console.error(error);
            }
        };
        fetchData();
    }, [change, projectName]);

    const handleButtonClick = (value: number) => {
        setGantt(value);
    };

    const totalNumberOfShifts = (episode: number) => {
        return reports.reduce((count: number, report: Report) => {
            if (report.episode === (episode ?? "").toString()) {
                count++;
            }
            return count;
        }, 0);
    }

    const sumVal = function (shiftsForEp: number, episodes: number) {
        return shiftsForEp * episodes;
    };
    const sumBudgetSoFar = function (totalEditShifts: number) {
        return Math.round(totalEditShifts * (parseFloat(project?.avgWage as string) || 0) * 10);
    };

    const sumBudget = function (shiftsForEp: number, episodes: number) {
        return Math.round(shiftsForEp * episodes * (parseFloat(project?.avgWage as string) || 0) * 10);
    };
    const shiftsSum = function (episodes: number, currentEp: number, shiftsForEp: number, totalEditShifts: number) {
        if (typeof episodes !== 'number' || typeof currentEp !== 'number' || typeof shiftsForEp !== 'number' || typeof totalEditShifts !== 'number') {
            return 0; // אם לפחות אחד מהערכים אינו מספר, נחזיר 0
        }
        let sum;
        sum = (shiftsForEp - (totalEditShifts / currentEp)) * episodes;
        return (typeof sum === 'number' && isFinite(sum)) ? Math.round(sum) : 0;
    };
    const budgetSum = function (budget: number, avgWage: string) {
        let sum;
        sum = budget * (parseFloat(avgWage as string) || 0) * 10;
        return Math.round(sum);
    };
    const fixedEpisodes = function (totalEditShifts: number, shiftsForEp: number, episodes: number, currentEp: number) {
        let totalShifts = sumVal(shiftsForEp as number, episodes as number);
        let sum;
        sum = (totalShifts - totalEditShifts) / (episodes - currentEp);
        return parseFloat(sum.toFixed(2)); // החזרת התוצאה עם שני ספרות אחרי הנקודה העשרונית
    };

    const formatDate = (dateString: string) => {
        const date = new Date(dateString);
        const day = date.getDate();
        const month = date.getMonth() + 1;
        const year = date.getFullYear();
        const formattedDate = `${day}/${month}/${year}`;

        return formattedDate;
    };

    return (
        <div className="mainDbPanel">
            <div className="row mainDbHeadline">
                <img src="/Monica_Logo@0.25x.png" alt="Logo" style={{ height: '60px', width: '200px' }} />
                <h1>שלום {manager?.name}</h1>
            </div>
            <div className="row">
                <div className="col-3 mx-3">
                    <div className="row mt-2 mb-2">
                        <div className="col projectName">
                            <span className="postBadge badge rounded-pill  w-100 py-3">שם פרויקט:   {project?.name}</span>
                        </div>
                    </div>
                    <div className="row mb-4">
                        <div className="col projectName">
                            <span className="postBadge badge rounded-pill  w-100 py-3">מפיק פוסט:   {project?.postName}</span>
                        </div>
                    </div>
                    <div className="row mb-1">
                        <div className="col">
                            <button className="btn btn-primary w-100" disabled={true}>עדכון פרטים</button>
                        </div>
                        <div className="col">
                            <Link to={`/updateProject/${project?.name}`}><button className="btn btn-primary w-100">עדכון פרויקט</button></Link>
                        </div>
                    </div>
                    <div className="row mb-1">
                        <div className="col">
                            <button className="btn btn-primary w-100" disabled={true}> בניית תקציב</button>
                        </div>
                        <div className="col">
                            <Link to={`/invocieEntry/${project?.name}`}><button className="btn btn-primary w-100">הזנת חשבוניות</button></Link>
                        </div>
                    </div>
                    <div className="row mb-1">
                        <div className="col">
                            <button className="btn btn-primary w-100" disabled={true}>שיבוץ עובדים</button>
                        </div>
                        <div className="col">
                            <Link to={`/salaryReport/${project?.name}`}><button className="btn btn-primary w-100">דוח שכר</button></Link>
                        </div>
                    </div>
                    <div className="row mb-1">
                        <div className="col">
                            <Link to={`/monthlyReport/${project?.name}`}><button className="btn btn-primary w-100">דוח חודשי</button></Link>
                        </div>
                        <div className="col">
                            <Link to={`/workSchedule/${project?.name}`}><button className="btn btn-primary w-100">לו״ז עבודה</button></Link>
                        </div>

                    </div>
                    <div className="row mb-1">
                        <div className="col">
                            <Link to={`/editorScreen/${project?.name}`}><button className="btn btn-primary w-100">מסך עובדים</button></Link>
                        </div>
                        <div className="col">
                            <Link to="/mainDashboard"><button className="btn btn-danger w-100">חזרה לדף ראשי</button></Link>
                        </div>
                    </div>
                    <div className="row">
                        <div className="table-responsive" style={{ maxHeight: "300px", overflowY: "auto" }}>
                            <table className="table table-striped mt-3">
                                <thead className="thead-dark" style={{ position: "sticky", top: 0, zIndex: 1 }}>
                                    <tr className="table-info">
                                        <th scope="col">פרק בעבודה</th>
                                        <th scope="col">מס׳ משמרות לפרק</th>
                                        <th scope="col">מס' משמרות בפועל</th>
                                        <th scope="col">יתרה/חריגה</th>
                                    </tr>
                                </thead>
                                <tbody style={{ overflowY: "auto" }}>
                                    {(Array.from({ length: project?.episodes || 0 }, (_, index) => index))
                                        .filter((episode: number) => episodesInWork.includes(episode + 1) && episode > 0) // הוסף את הבדיקה כאן
                                        .map((episode: number, index: number) => (
                                            <tr key={index}>
                                                <td> <Link to={`/episodeScreen/${project?.name}/${episode + 1}`} >{episode + 1}</Link></td>
                                                <td>{project?.shiftsForEp}</td>
                                                <td>{totalNumberOfShifts(episode + 1)}</td>
                                                <td style={{
                                                    color: (project?.shiftsForEp as number - totalNumberOfShifts(episode + 1)) < 0 ? '#fc2d2d' : '', direction: 'rtl'
                                                }}>
                                                    <b>{project?.shiftsForEp as number - totalNumberOfShifts(episode + 1)} </b>
                                                </td>
                                            </tr>
                                        ))}
                                    <p><Link to={`/episodes/${project?.name}`} >כל הפרקים</Link></p>
                                </tbody>
                            </table>
                        </div>
                        <div className="table-responsive" style={{ maxHeight: "250px", overflowY: "auto" }}>
                            <table className="table table-striped">
                                <thead className="thead-dark" style={{ position: "sticky", top: 0, zIndex: 1 }}>
                                    <tr className="table-info">
                                        <th scope="col">דוח עבודה יומי</th>
                                        <th scope="col"></th>
                                        <th scope="col">{date}</th>
                                    </tr>
                                </thead>
                                {userInfo?.length ? (
                                    <tbody style={{ overflowY: "auto" }}>
                                        {userInfo
                                            .filter((user: User) => user.dailyReport === false)
                                            .map((user: User) => (
                                                <tr key={user?.id}>
                                                    <td><Link to={`/editorScreen/${project?.name}/${user.name}`}>
                                                        {user?.name}
                                                    </Link></td>
                                                    <td></td>
                                                    <td><span className="badge bg-danger py-2">לא דווח</span></td>
                                                </tr>
                                            ))}
                                    </tbody>
                                ) : (
                                    <p> אין עובדים </p>
                                )}
                            </table>
                        </div>
                    </div>
                </div>
                <div className="col-8 mt-3">
                    <div className="row">
                        <div className="mx-auto col-12 col-md-8 col-lg-6 w-100">
                            <table className="table table-striped">
                                <thead className="thead-dark">
                                    <tr className="table-primary">
                                        <th scope="col">פרויקט</th>
                                        <th scope="col">סה״כ משמרות</th>
                                        <th scope="col">מספר פרקים</th>
                                        <th scope="col">מספר משמרות לפרק</th>
                                        <th scope="col">צפי משמרות מתוקן</th>
                                        <th scope="col">פרק שודר</th>
                                        <th scope="col">סה״כ משמרות בפועל</th>
                                        <th scope="col">תקציב עבודה</th>
                                        <th scope="col">תקציב עבודה בפועל</th>
                                        <th scope="col">צפי רווח/חריגה במש׳ עד סוף הפרויקט</th>
                                        <th scope="col">צפי רווח/חריגה בתקציב עד סוף הפרויקט</th>
                                        <th scope="col">תאריך סיום</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr key={project?.name}>
                                        <td>{project?.name}</td>
                                        <td>{sumVal(project?.shiftsForEp as number, project?.episodes as number)}</td>
                                        <td>{project?.episodes}</td>
                                        <td>{project?.shiftsForEp}</td>
                                        <td>{fixedEpisodes(totalNumberOfShifts(1), project?.shiftsForEp as number, project?.episodes as number, project?.currentEp as number)}</td>
                                        {/* <td>{project?.currentEp}</td> */}
                                        <td>1</td>
                                        <td>{totalNumberOfShifts(1)}</td>
                                        <td>{sumBudget(project?.shiftsForEp as number, project?.episodes as number)}</td>
                                        <td>{sumBudgetSoFar(totalNumberOfShifts(1))}</td>
                                        <td style={{
                                            color: shiftsSum(project?.episodes as number, project?.currentEp as number, project?.shiftsForEp as number, totalNumberOfShifts(1)) < 0 ? '#fc2d2d' : '#19cf22', unicodeBidi: 'plaintext', fontSize: "1.1rem"
                                        }}>
                                            <b>{shiftsSum(project?.episodes as number, project?.currentEp as number, project?.shiftsForEp as number, totalNumberOfShifts(1))}</b>
                                        </td>
                                        <td style={{
                                            color: budgetSum(shiftsSum(project?.episodes as number, project?.currentEp as number, project?.shiftsForEp as number, totalNumberOfShifts(1)), project?.avgWage as string) < 0 ? '#fc2d2d' : '#19cf22', unicodeBidi: 'plaintext', fontSize: "1.1rem"
                                        }}>
                                            <b>{budgetSum(shiftsSum(project?.episodes as number, project?.currentEp as number, project?.shiftsForEp as number, totalNumberOfShifts(1)), project?.avgWage as string)}</b>
                                        </td>
                                        <td>{formatDate(project?.endDate as string)}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                    <div className="row">
                        <div className="gantt">
                            {gantt === 0 && <h1>תצוגת אופליין</h1>}
                            {gantt === 1 && <h1>תצוגת אונליין</h1>}
                            {gantt === 2 && <h1>תצוגת סאונד</h1>}
                            {gantt === 0 && <Gantt />}
                            {gantt === 1 && <Gantt />}
                            {gantt === 2 && <Gantt />}
                        </div>
                    </div>
                </div>
                <div className="col mt-4">
                    <button
                        className={`btn btn-outline-danger mb-3 ${gantt === 0 ? 'active' : ''}`}
                        onClick={() => handleButtonClick(0)}
                    >
                        אופליין
                    </button>
                    <button
                        className={`btn btn-outline-success mb-3 px-3 ${gantt === 1 ? 'active' : ''}`}
                        onClick={() => handleButtonClick(1)}
                    >
                        אונליין
                    </button>
                    <button
                        className={`btn btn-outline-secondary px-3 ${gantt === 2 ? 'active' : ''}`}
                        onClick={() => handleButtonClick(2)}
                    >
                        סאונד
                    </button>
                </div>
            </div>
        </div >
    );
}

export default ProjectDashboard;
import { FunctionComponent, useEffect, useState } from "react";
import { useFormik } from "formik";
import * as yup from "yup";
import { useNavigate } from "react-router-dom";
import { errorMsg, successMsg } from "../services/feedbacks";
import Manager from "../interfaces/Manager";
import { getManagerById, getManagerId, updateManager } from "../services/managerServices";

interface UpdateManagerNewProps {

}

const UpdateManagerNew: FunctionComponent<UpdateManagerNewProps> = () => {
    let [manager, setManager] = useState<Manager>({
        username: "",
        password: "",
    });
    let navigate = useNavigate();
    const [position, setPosition] = useState<string>("");
    const [genre, setGenre] = useState<string>("");
    const [experience, setExperience] = useState<string>("");
    const [status, setStatus] = useState<string>("");

    useEffect(() => {
        getManagerById(getManagerId())
            .then((res) => {
                const managerData = res.data;
                // Update the manager state with the received data
                setManager({
                    username: managerData.username,
                    password: managerData.password,
                    name: managerData.name,
                    id: managerData.id,
                    phone: managerData.phone,
                    email: managerData.email,
                    position: managerData.position,
                    genre: managerData.genre,
                    status: managerData.status,
                    example1: managerData.example1,
                    example2: managerData.example2,
                    example3: managerData.example3,
                    freeText: managerData.freeText
                });
                // Update formik values
                formik.setValues({
                    username: managerData.username,
                    password: managerData.password,
                    name: managerData.name,
                    id: managerData.id,
                    phone: managerData.phone,
                    email: managerData.email,
                    position: managerData.position,
                    genre: managerData.genre,
                    status: managerData.status,
                    example1: managerData.example1,
                    example2: managerData.example2,
                    example3: managerData.example3,
                    freeText: managerData.freeText
                });
                setPosition(managerData.position);
                setGenre(managerData.genre);
                setExperience(managerData.experience);
                setStatus(managerData.status);
            })
            .catch((err) => console.log(err));
    }, []);

    const formik = useFormik({
        initialValues: {
            username: manager.username,
            password: manager.password,
            name: manager.name,
            id: manager.id,
            phone: manager.phone,
            email: manager.email,
            position: manager.position,
            genre: manager.genre,
            status: manager.status,
            example1: manager.example1,
            example2: manager.example2,
            example3: manager.example3,
            freeText: manager.freeText
        },
        enableReinitialize: true,
        validationSchema: yup.object({
            username: yup.string().required().min(5),
            password: yup.string().required().min(8),
            name: yup.string().required().min(2),
            id: yup.string().required(),
            phone: yup.string().required(),
            email: yup.string().required().email().min(5),
            status: yup.string(),
            wage: yup.string().required(),
        }),
        onSubmit: (values: Manager) => {
            console.log("הטופס נשלח עם הערכים:", values);
            const newManager = {
                ...values, position: position, experience: experience, genre: genre, code: manager.code,
                status: status,
            };
            updateManager({ ...newManager })
                .then((res) => {
                    navigate("/postDashboard");
                    successMsg("הפרטים עודכנו בהצלחה!");
                })
                .catch((err) => console.log(err));
        }
    });

    return (
        <div className="signupPanel">
            <div className="row signupHeadline">
                <img src="/Monica_Logo@0.25x.png" alt="Logo" style={{ height: '60px', width: '200px' }} />
                <h1>עדכון פרטים</h1>
            </div>
            <form onSubmit={formik.handleSubmit}>
                <div className="row">
                    <div className="col-1"></div>
                    <div className="form col-8 col-md-8 col-lg-6 mt-5">
                        <div className="form-floating row mb-3">
                            <div className="col-1"></div>
                            <div className="col-3 pt-2">
                                <label htmlFor="floatingInput">שם משתמש:</label>
                            </div>
                            <div className="col-8">
                                <input type="text" className="form-control" id="floatingInput" name="username"
                                    value={formik.values.username}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                />
                                {formik.touched.username && formik.errors.username && (
                                    <small className="text-danger">{formik.errors.username}</small>
                                )}
                            </div>
                        </div>
                        <div className="form-floating row mb-3">
                            <div className="col-1"></div>
                            <div className="col-3 pt-2">
                                <label htmlFor="floatingName">שם מלא:</label>
                            </div>
                            <div className="col-8">
                                <input type="text" className="form-control" id="floatingName" name="name"
                                    value={formik.values.name}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                />
                                {formik.touched.name && formik.errors.name && (
                                    <small className="text-danger">{formik.errors.name}</small>
                                )}
                            </div>
                        </div>
                        <div className="form-floating row mb-3">
                            <div className="col-1"></div>
                            <div className="col-3 pt-2">
                                <label htmlFor="floatingId">ת.ז:</label>
                            </div>
                            <div className="col-8">
                                <input type="text" className="form-control" id="floatingId" name="id"
                                    value={formik.values.id}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                />
                                {formik.touched.id && formik.errors.id && (
                                    <small className="text-danger">{formik.errors.id}</small>
                                )}
                            </div>
                        </div>
                        <div className="form-floating row mb-3">
                            <div className="col-1"></div>
                            <div className="col-3 pt-2">
                                <label htmlFor="floatingPhone">טלפון:</label>
                            </div>
                            <div className="col-8">
                                <input type="phone" className="form-control" id="floatingPhone" name="phone"
                                    value={formik.values.phone}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                />
                                {formik.touched.phone && formik.errors.phone && (
                                    <small className="text-danger">{formik.errors.phone}</small>
                                )}
                            </div>
                        </div>
                        <div className="form-floating row mb-3">
                            <div className="col-1"></div>
                            <div className="col-3 pt-2">
                                <label htmlFor="floatingEmail">מייל:</label>
                            </div>
                            <div className="col-8">
                                <input type="email" className="form-control" id="floatingEmail" name="email"
                                    value={formik.values.email}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                />
                                {formik.touched.email && formik.errors.email && (
                                    <small className="text-danger">{formik.errors.email}</small>
                                )}
                            </div>
                        </div>
                        <div className="input-group form-floating mb-3">
                            <div className="col-1"></div>
                            <div className="col-3 pt-2 input-group-prepend">
                                <label htmlFor="positionSelect">תפקיד:</label>
                            </div>
                            <select className="positionSel custom-select mx-2 w-25" id="positionSelect"
                                onChange={p => setPosition(p.target.value)}
                                value={position}>
                                <option selected>בחר/י תפקיד</option>
                                <option value="מפיק פוסט">מפיק פוסט</option>
                                <option value="ע. מפיק פוסט">ע. מפיק פוסט</option>
                            </select>
                        </div>
                        <div className="input-group form-floating mb-3">
                            <div className="col-1"></div>
                            <div className="col-3 pt-2 input-group-prepend">
                                <label htmlFor="experienceSelect">שנות וותק:</label>
                            </div>
                            <select className="custom-select mx-2 w-25" id="experienceSelect"
                                onChange={e => setExperience(e.target.value)}
                                value={experience}>
                                <option selected>בחר/י</option>
                                <option value="0">0</option>
                                <option value="1-3">1-3</option>
                                <option value="3+">מעל 3</option>
                            </select>
                        </div>
                        <div className="input-group form-floating mb-3">
                            <div className="col-1"></div>
                            <div className="col-3 pt-2 input-group-prepend">
                                <label htmlFor="genreSelect">ז׳אנר:</label>
                            </div>
                            <select className="custom-select mx-2 w-25" id="genreSelect"
                                onChange={g => setGenre(g.target.value)}
                                value={genre}>
                                <option selected>בחר/י</option>
                                <option value="ריאליטי">ריאליטי</option>
                                <option value="דוקומנטרי">דוקומנטרי</option>
                                <option value="דרמה">דרמה</option>
                                <option value="הכל">הכל</option>
                            </select>
                        </div>
                        <div className="input-group form-floating mb-3">
                            <div className="col-1"></div>
                            <div className="col-3 pt-2 input-group-prepend">
                                <label htmlFor="statusSelect">זמינות:</label>
                            </div>
                            <select className="custom-select mx-2 w-25" id="statusSelect"
                                onChange={z => setStatus(z.target.value)}
                                value={status}>
                                <option selected>בחר/י</option>
                                <option value="פנוי/ה">פנוי/ה</option>
                                <option value="לא פנוי/ה">לא פנוי/ה</option>
                            </select>
                        </div>
                        <div className="form-floating row">
                            <div className="col-1"></div>
                            <div className="col-3 pt-2">
                                <label htmlFor="floatingExample">עבודות לדוגמא:</label>
                            </div>
                            <div className="col-8">
                                <input type="text" className="form-control" id="floatingExample" name="example1"
                                    value={formik.values.example1}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                />
                            </div>
                        </div>
                        <div className="form-floating row mb-3">
                            <div className="col-4"></div>
                            <div className="col-8">
                                <input type="text" className="form-control" id="floatingExample2" name="example2"
                                    value={formik.values.example2}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                />
                            </div>
                        </div>
                        <div className="form-floating row mb-3">
                            <div className="col-4"></div>
                            <div className="col-8">
                                <input type="text" className="form-control" id="floatingExample3" name="example3"
                                    value={formik.values.example3}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="freeText col-2 m-5">
                        <div className="form-floating">
                            <input type="text-area" className="form-control" name="freeText"
                                value={formik.values.freeText}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                            />
                            <label htmlFor="floatingTextarea">מלל חופשי</label>
                        </div>
                    </div>
                </div>
                <button type="submit" className="btn btn-primary my-3 w-25 mt-2 mb-5">
                    עדכון
                </button>
            </form>
            <div className="row signupOptions mx-auto col-10 col-md-8 col-lg-6">
                <p onClick={() => navigate(-1)} style={{
                    color: 'blue',
                    textDecoration: 'underline',
                    cursor: 'pointer',
                }}>חזור</p>
                <p style={{ color: 'lightgray' }}>Almog Liebermensch 2024 ©</p>
            </div>
        </div>
    );
}

export default UpdateManagerNew;
import { FunctionComponent, useEffect, useState } from "react";
import Manager from "../interfaces/Manager";
import { useNavigate, useParams } from "react-router-dom";
import { getProjectByName, updateProject } from "../services/projectServices";
import { successMsg } from "../services/feedbacks";
import { getManagerById, getManagerProfile } from "../services/managerServices";
import Project from "../interfaces/Project";
import { useFormik } from "formik";
import * as yup from "yup";

interface UpdateProjectProps {

}

const UpdateProject: FunctionComponent<UpdateProjectProps> = () => {
    let [project, setProject] = useState<Project>({
        name: "",
        mainProducer: "",
    });
    let navigate = useNavigate();
    let [manager, setManager] = useState<Manager | null>(null);
    const { projectName } = useParams();

    let formik = useFormik({
        initialValues: {
            name: project.name,
            mainProducer: project.mainProducer,
            postName: project.postName,
            startDate: project.startDate,
            endDate: project.endDate,
            episodes: project.episodes,
            budget: project.budget,
            postId: project.postId,
            shiftsForEp: project.shiftsForEp,
            shotef: project.shotef,
        },
        enableReinitialize: true,
        validationSchema: yup.object({
            name: yup.string().required().min(2),
            mainProducer: yup.string(),
            postName: yup.string(),
            postId: yup.string(),
            startDate: yup.string().required(),
            endDate: yup.string().required(),
            episodes: yup.number().required(),
            budget: yup.number().required(),
            shiftsForEp: yup.number(),
            shotef: yup.number(),
            totalShifts: yup.number(),
            currentEp: yup.number(),
            editShifts: yup.number(),
            budgetSoFar: yup.number(),
        }),
        onSubmit: (values: Project) => {
            updateProject({ ...values, mainProducer: project.mainProducer, postName: project.postName, postId: project.postId })
                .then((res) => {
                    // navigate("/postDashboard");
                    window.history.back(); // חזרה אחורה בהיסטוריה
                    successMsg("!הפרויקט עודכן בהצלחה");
                })
                .catch((err) => console.log(err));
        }
    });
    useEffect(() => {
        async function fetchData() {
            try {
                if (projectName) {
                    const pro = await getProjectByName(projectName);
                    setProject(pro.data);

                    const man = await getManagerById(pro.data.postId as string);
                    setManager(man.data);
                }
                else {
                    const managerResponse = await getManagerProfile();
                    setManager(managerResponse.data);

                    const projectResponse = await getProjectByName(managerResponse.data.projectName);
                    setProject(projectResponse.data);
                }
            } catch (error) {
                console.error(error);
            }
        }
        fetchData();
    }, []);
    return (
        <div className="newProjectPanel">
            <div className="row newProjectHeadline">
                <img src="/Monica_Logo@0.25x.png" alt="Logo" style={{ height: '60px', width: '200px' }} />
                <h1>עדכון פרויקט</h1>
            </div>
            <div className="row">
                <div className="form mx-auto col-10 col-md-8 col-lg-6 mt-5">
                    <form onSubmit={formik.handleSubmit}>
                        <div className="form-floating row mb-3">
                            <div className="row mb-2">
                                <div className="col projectName">
                                    <span className="postBadge badge rounded-pill w-100 py-3">שם פרויקט:   {manager?.projectName}</span>
                                </div>
                            </div>
                            <div className="row mb-4">
                                <div className="col projectName">
                                    <span className="postBadge badge rounded-pill w-100 py-3">מפיק פוסט:   {manager?.name}</span>
                                </div>
                            </div>
                        </div>
                        <div className="form-floating row mb-3">
                            <div className="col-4 pt-2">
                                <label htmlFor="floatingStartDate">תאריך התחלה:</label>
                                {formik.touched.startDate && formik.errors.startDate && (
                                    <small className="text-danger">{formik.errors.startDate}</small>
                                )}
                            </div>
                            <div className="col-7">
                                <input type="date" className="form-control" id="floatingStartDate" name="startDate"
                                    value={formik.values.startDate}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                />
                            </div>
                        </div>
                        <div className="form-floating row mb-3">
                            <div className="col-4 pt-2">
                                <label htmlFor="floatingeEndDate">תאריך סיום:</label>
                                {formik.touched.endDate && formik.errors.endDate && (
                                    <small className="text-danger">{formik.errors.endDate}</small>
                                )}
                            </div>
                            <div className="col-7">
                                <input type="date" className="form-control" id="floatingeEndDate" name="endDate"
                                    value={formik.values.endDate}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                />
                            </div>
                        </div>
                        <div className="form-floating row mb-3">
                            <div className="col-4 pt-2">
                                <label htmlFor="floatingEpisodes">מספר פרקים:</label>
                                {formik.touched.episodes && formik.errors.episodes && (
                                    <small className="text-danger">{formik.errors.episodes}</small>
                                )}
                            </div>
                            <div className="col-7">
                                <input type="number" className="form-control" id="floatingEpisodes" name="episodes"
                                    value={formik.values.episodes}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                />
                            </div>
                        </div>
                        <div className="form-floating row mb-3">
                            <div className="col-4 pt-2">
                                <label htmlFor="floatingBudget">תקציב:</label>
                                {formik.touched.budget && formik.errors.budget && (
                                    <small className="text-danger">{formik.errors.budget}</small>
                                )}
                            </div>
                            <div className="col-7">
                                <input type="text" className="form-control" id="floatingBudget" name="budget"
                                    value={formik.values.budget}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                />
                            </div>
                        </div>
                        <div className="form-floating row mb-3">
                            <div className="col-4 pt-2">
                                <label htmlFor="floatingShiftsForEp">צפי משמרות לפרק:</label>
                                {formik.touched.shiftsForEp && formik.errors.shiftsForEp && (
                                    <small className="text-danger">{formik.errors.shiftsForEp}</small>
                                )}
                            </div>
                            <div className="col-7">
                                <input type="text" className="form-control" id="floatingShiftsForEp" name="shiftsForEp"
                                    value={formik.values.shiftsForEp}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                />
                            </div>
                        </div>
                        <div className="form-floating row mb-3">
                            <div className="col-4 pt-2">
                                <label htmlFor="floatingShotef">שוטף:</label>
                                {formik.touched.shotef && formik.errors.shotef && (
                                    <small className="text-danger">{formik.errors.shotef}</small>
                                )}
                            </div>
                            <div className="col-7">
                                <select
                                    className="form-select"
                                    id="floatingShotef"
                                    name="shotef"
                                    value={formik.values.shotef}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                >
                                    <option value="15">15</option>
                                    <option value="30">30</option>
                                    <option value="45">45</option>
                                    <option value="60">60</option>
                                    <option value="90">90</option>
                                </select>
                            </div>
                        </div>
                        <button type="submit"
                            className="btn btn-primary my-3 px-4 mt-5 mb-5">
                            עדכון פרויקט
                        </button>
                    </form>
                </div>
            </div>
            <div className="row newProjectOptions mx-auto col-10 col-md-8 col-lg-6">
                <p onClick={() => navigate(-1)} style={{
                    color: 'blue',
                    textDecoration: 'underline',
                    cursor: 'pointer',
                }}>חזור</p>
                <p style={{ color: 'lightgray' }}>Almog Liebermensch 2024 ©</p>
            </div>
        </div>
    );
}

export default UpdateProject;
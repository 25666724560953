import axios from "axios";
import jwt_decode from "jwt-decode";
import Manager from "../interfaces/Manager";

const api: string = process.env.REACT_APP_API || "";


export function checkManager(managerToCheck: Manager) {
    return axios.post(
        `${api}/managerLogin`, managerToCheck);
}

// add manager
export function addManager(managerToAdd: Manager) {
    return axios.post(`${api}/managerRegister`, managerToAdd);
}

// update user
export function updateManager(newDetails: Manager) {
    return axios.put(`${api}/managers/${newDetails.id}`, newDetails);
}

// get user profile
export function getManagerProfile() {
    return axios.get(`${api}/managerProfile`, {
        headers: { Authorization: JSON.parse(sessionStorage.getItem("userData") as string).token }
    })
}

//get Post users
export function getPostManagers() {
    return axios.get(`${api}/managerPositions/מפיק פוסט`);
}

// get user by Id
export function getManagerById(id: string) {
    return axios.get(`${api}/managers/${id}`);
}

// get managerId from token
export function getManagerId() {
    let token = JSON.parse(sessionStorage.getItem("userData") as string).token;
    return (jwt_decode(token) as any).id;
}
// get position from token
export function getPosition() {
    let token = JSON.parse(sessionStorage.getItem("userData") as string).token;
    return (jwt_decode(token) as any).position;
}

// get main producer name from token
export function getMainName() {
    let token = JSON.parse(sessionStorage.getItem("userData") as string).token;
    return (jwt_decode(token) as any).name;
}
// get project name from token
export function getProjectName() {
    let token = JSON.parse(sessionStorage.getItem("userData") as string).token;
    return (jwt_decode(token) as any).projectName;
}
//get project by Main Producer
export function getProjectByMain(mainProducer: string) {
    return axios.get(`${api}/managerProjects/${mainProducer}`);
}
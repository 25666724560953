import { FunctionComponent, useEffect, useState } from "react";
import Project from "../interfaces/Project";
import { getProjectName } from "../services/userServices";
import { getReportsByProjectName, updateReport } from "../services/reportServices";
import { getProjectByName } from "../services/projectServices";
import { useNavigate, useParams } from "react-router-dom";
import Report from "../interfaces/Report";

interface MonthlyReportProps {

}
const monthNames = [
    "ינואר", "פברואר", "מרץ", "אפריל", "מאי", "יוני", "יולי", "אוגוסט", "ספטמבר", "אוקטובר", "נובמבר", "דצמבר"
];

const MonthlyReport: FunctionComponent<MonthlyReportProps> = () => {
    const navigate = useNavigate();
    let [project, setProject] = useState<Project | null>(null);
    let [reports, setReports] = useState<Report[]>([]);
    const [selectedMonths, setSelectedMonths] = useState<number[]>([]);
    const currentDate = new Date();
    const currentMonthIndex = currentDate.getMonth();

    const { projectName } = useParams();


    useEffect(() => {
        const fetchData = async () => {
            try {
                if (projectName) {
                    const reportsResponse = await getReportsByProjectName(projectName);
                    setReports(reportsResponse.data);
                    const projectResponse = await getProjectByName(projectName);
                    setProject(projectResponse.data);
                }
                else {
                    const proName = await getProjectName();
                    const reportsResponse = await getReportsByProjectName(proName);
                    setReports(reportsResponse.data);

                    const projectResponse = await getProjectByName(proName);
                    setProject(projectResponse.data);
                }

                // הוספת החודש הנוכחי בזמן טעינת הדף, אם עדיין לא נמצא ברשימת החודשים הנבחרים
                setSelectedMonths((prevSelectedMonths) => {
                    const updatedSelectedMonths = [...prevSelectedMonths];
                    const currentMonthIndex = currentDate.getMonth();

                    if (updatedSelectedMonths.includes(currentMonthIndex)) {
                        // הסרת החודש מרשימת החודשים הנבחרים
                        return updatedSelectedMonths.filter((month) => month !== currentMonthIndex);
                    } else {
                        // הוספת החודש לרשימת החודשים הנבחרים
                        return [...updatedSelectedMonths, currentMonthIndex];
                    }
                });
            } catch (error) {
                console.error(error);
            }
        };
        fetchData();
    }, []);


    // פונקציה לשינוי בחירת החודשים
    const toggleMonthSelection = (monthIndex: number) => {
        setSelectedMonths((prevSelectedMonths) => {
            // אם החודש הוא החודש הנוכחי והוא כבר נבחר, אל תעשה כלום
            if (monthIndex === currentMonthIndex && prevSelectedMonths.includes(monthIndex)) {
                return prevSelectedMonths;
            }

            const updatedSelectedMonths = prevSelectedMonths.includes(monthIndex)
                ? prevSelectedMonths.filter((index) => index !== monthIndex)
                : [...prevSelectedMonths, monthIndex];

            return updatedSelectedMonths;
        });
    };

    // חישוב הסטייה לפי מספר המשמרות לפרק
    const divationSum = function (shiftsForEp: number, shifts: number) {
        return shiftsForEp - shifts;
    };

    // עדכון שדה האישור בדוח
    const updateConfirmed = async (updatedReport: Report) => {
        try {
            await updateReport(updatedReport);
            setReports(reports.map(report => report._id === updatedReport._id ? updatedReport : report));
        } catch (error) {
            console.error(error);
        }
    };

    return (
        <div className="passwordPanel">
            <div className="row passwordHeadline">
                <img src="/Monica_Logo@0.25x.png" alt="Logo" style={{ height: '60px', width: '200px' }} />
                <h1>דוח חודשי</h1>
            </div>
            <div className="mx-auto col-12 col-md-8 col-lg-6 mt-5">
                <div className="row justify-content-center mb-2">
                    <div className="btn-group" role="group">
                        {Array.from({ length: 12 }, (_, index) => (
                            <button
                                key={index}
                                className={`btn btn-sm btn-${selectedMonths.includes(index) || index === currentMonthIndex ? 'success' : 'outline-secondary'}`}
                                onClick={() => toggleMonthSelection(index)}
                            >
                                {monthNames[index]}
                            </button>
                        ))}
                    </div>
                </div>
                <div className="row mt-3">
                    <div className="table-responsive" style={{ maxHeight: "400px", overflowY: "auto" }}>
                        <table className="table table-striped mt-2">
                            <thead className="thead-dark" style={{ position: "sticky", top: 0, zIndex: 1 }}>
                                <tr className="table-info">
                                    <th scope="col" className="col-md-3">שם עובד</th>
                                    <th scope="col" className="col-md-2">תאריך</th>
                                    <th scope="col" className="col-md-1">פרק</th>
                                    <th scope="col" className="col-md-2">שעת התחלה</th>
                                    <th scope="col" className="col-md-2">שעת סיום</th>
                                    <th scope="col" className="col-md-2">סה״כ שעות</th>
                                    <th scope="col">אישור</th>
                                </tr>
                            </thead>
                            {reports.length ? (
                                <tbody style={{ overflowY: 'auto' }}>
                                    {reports
                                        .filter((report) => report.date && report.monthName) // מסננים דיווחים עם תאריך ושם חודש
                                        .filter((report) => {
                                            if (report.date) {
                                                const [day, month, year] = report.date.split('.');
                                                const reportMonthIndex = parseInt(month) - 1;
                                                return selectedMonths.includes(reportMonthIndex);
                                            }
                                            return false;
                                        })
                                        .sort((a, b) => {
                                            const episodeA = (a?.episode ?? 0) as number;
                                            const episodeB = (b?.episode ?? 0) as number;
                                            if (episodeA !== episodeB) {
                                                return episodeA - episodeB;
                                            }
                                            const aMonthIndex = monthNames.indexOf(a?.monthName ?? '');
                                            const bMonthIndex = monthNames.indexOf(b?.monthName ?? '');
                                            return aMonthIndex - bMonthIndex;
                                        })
                                        .map((report) => {
                                            const reportMonthIndex = monthNames.indexOf(report?.monthName ?? '');
                                            const selectedMonthIndices = selectedMonths.map((monthIndex) => monthNames.indexOf(monthNames[monthIndex]));
                                            if (selectedMonthIndices.includes(reportMonthIndex)) {
                                                return (
                                                    <tr key={report?._id}>
                                                        <td>{report?.editorName}</td>
                                                        <td>{report?.date}</td>
                                                        <td>{report?.episode}</td>
                                                        <td>{report?.start}</td>
                                                        <td>{report?.end}</td>
                                                        <td>{report?.total}</td>
                                                        <td>
                                                            <input
                                                                type="checkbox"
                                                                className="input-sm w-75"
                                                                id={`confirmCheckbox-${report?._id}`}
                                                                name="confirm"
                                                                checked={report?.confirmed || false}
                                                                onChange={(e) => {
                                                                    const updatedReport = {
                                                                        ...report,
                                                                        confirmed: e.target.checked,
                                                                    };
                                                                    updateConfirmed(updatedReport);
                                                                }}
                                                            />
                                                        </td>
                                                    </tr>
                                                );
                                            }
                                            return null;
                                        })}
                                </tbody>
                            ) : (
                                <p> </p>
                            )}

                        </table>
                    </div>
                    <button
                        className="btn btn-info mx-auto my-1 w-25">
                        הפקת דוח
                    </button>
                </div>
                <div className="row space my-2"></div>
                <div className="row mt-3">
                    <div className="table-responsive" style={{ maxHeight: "250px", overflowY: "auto" }}>
                        <table className="table table-striped mt-2">
                            <thead className="thead-dark" style={{ position: "sticky", top: 0, zIndex: 1 }}>
                                <tr className="table-info">
                                    <th scope="col">פרק</th>
                                    <th scope="col">מס׳ משמרות</th>
                                    <th scope="col">צפי משמרות</th>
                                    <th scope="col">מצב משמרות בפועל</th>
                                </tr>
                            </thead>
                            {reports.length ? (
                                <tbody style={{ overflowY: "auto" }}>
                                    {project?.currentEp && reports.length > 0 ? (
                                        reports
                                            .filter((report) => report.monthName && selectedMonths.includes(monthNames.indexOf(report.monthName)))
                                            .map((report) => parseInt(report.episode as string, 10))
                                            .filter((episodeNumber, index, self) => self.indexOf(episodeNumber) === index)
                                            .sort((a, b) => a - b)  // מיון הפרקים לפי סדר עולה
                                            .map((episodeNumber) => {
                                                const totalReportsForEpisode = selectedMonths.reduce((total: number, selectedMonthIndex) => {
                                                    const selectedMonthName = monthNames[selectedMonthIndex];
                                                    const filteredReports = reports.filter((report) => report.monthName === selectedMonthName && parseInt(report.episode as string, 10) === episodeNumber);
                                                    return total + filteredReports.length;
                                                }, 0);

                                                const deviation = divationSum(project?.shiftsForEp as number, totalReportsForEpisode);

                                                return (
                                                    <tr key={episodeNumber}>
                                                        <td><b>{episodeNumber}</b></td>
                                                        <td>{totalReportsForEpisode}</td>
                                                        <td>{project?.shiftsForEp}</td>
                                                        <td style={{ color: deviation < 0 ? '#fc2d2d' : '#19cf22', unicodeBidi: 'plaintext' }}>
                                                            {deviation}
                                                        </td>
                                                    </tr>
                                                );
                                            })
                                    ) : (
                                        <p> </p>
                                    )}
                                </tbody>
                            ) : (
                                <p> </p>
                            )}
                        </table>
                    </div>
                    <button
                        className="btn btn-info mx-auto my-1 w-25">
                        הפקת דוח
                    </button>
                </div>
            </div>
            <div className="row passwordOptions mx-auto col-10 col-md-8 col-lg-6">
                <p onClick={() => navigate(-1)} style={{
                    color: 'blue',
                    textDecoration: 'underline',
                    cursor: 'pointer',
                }}>חזור</p>
                <p style={{ color: 'lightgray' }}>Almog Liebermensch 2024 ©</p>
            </div>
        </div>
    );
}

export default MonthlyReport;
import { FunctionComponent, useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { successMsg } from "../services/feedbacks";
import User from "../interfaces/User";
import "../css/mainDashboard.css"
import { getManagerProfile, getProjectName } from "../services/managerServices";
import Project from "../interfaces/Project";
import { getUsersbyProject } from "../services/userServices";
import Manager from "../interfaces/Manager";
import Gantt from "./Gantt";
import { getProjectByName } from "../services/projectServices";
import { getReportsByProjectName } from "../services/reportServices";
import Report from "../interfaces/Report";

interface AssistantDashboardProps {

}

const AssistantDashboard: FunctionComponent<AssistantDashboardProps> = () => {
    let navigate = useNavigate();
    let [manager, setManager] = useState<Manager | null>(null);
    let [userInfo, setUserInfo] = useState<User[]>([]);
    let [project, setProject] = useState<Project | null>(null);
    let [gantt, setGantt] = useState<number>(0);
    let [change, setChange] = useState<boolean>(false);
    let [reports, setReports] = useState<Report[]>([]);


    const current = new Date();
    const date = `${current.getDate()}/${current.getMonth() + 1}/${current.getFullYear()}`;

    useEffect(() => {
        const fetchData = async () => {
            try {
                const managerProfile = await getManagerProfile();
                setManager(managerProfile.data);

                const proName = await getProjectName();
                const projectData = await getProjectByName(proName);
                setProject(projectData.data);

                const reportsResponse = await getReportsByProjectName(proName);
                setReports(reportsResponse.data);

                const usersData = await getUsersbyProject(proName);
                setUserInfo(usersData.data);
            } catch (error) {
                console.error(error);
            }
        };
        fetchData();
    }, [change]);

    const handleButtonClick = (value: number) => {
        setGantt(value);
    };

    const totalNumberOfShifts = (episode: number) => {
        return reports.reduce((count: number, report: Report) => {
            if (report.episode === (episode ?? "").toString()) {
                count++;
            }
            return count;
        }, 0);
    }


    const sumVal = function (shiftsForEp: number, episodes: number) {
        return shiftsForEp * episodes;
    };
    const sumBudgetSoFar = function (totalEditShifts: number) {
        return totalEditShifts * 1800;
    };
    const sumBudget = function (shiftsForEp: number, episodes: number) {
        return shiftsForEp * episodes * 1800;
    };
    const shiftsSum = function (episodes: number, currentEp: number, shiftsForEp: number, totalEditShifts: number) {
        let sum;
        sum = (shiftsForEp - (totalEditShifts / currentEp)) * episodes;
        return (Math.round(sum));
    };
    const budgetSum = function (budget: number, budgetSoFar: number, episodes: number, currentEp: number, shiftsForEp: number, avgWage: string) {
        let sum;
        sum = (budget - budgetSoFar) - ((episodes - currentEp) * shiftsForEp * parseInt(avgWage));
        return sum;
    };
    const fixedEpisodes = function (totalEditShifts: number, shiftsForEp: number, episodes: number, currentEp: number) {
        let totalShifts = sumVal(shiftsForEp as number, episodes as number);
        let sum;
        sum = (totalShifts - totalEditShifts) / (episodes - currentEp);
        return parseFloat(sum.toFixed(2)); // החזרת התוצאה עם שני ספרות אחרי הנקודה העשרונית
    };

    const formatDate = (dateString: string) => {
        const date = new Date(dateString);
        const day = date.getDate();
        const month = date.getMonth() + 1;
        const year = date.getFullYear();
        const formattedDate = `${day}/${month}/${year}`;

        return formattedDate;
    };
    return (<>
        <div className="mainDbPanel">
            <div className="row mainDbHeadline">
                <img src="/Monica_Logo@0.25x.png" alt="Logo" style={{ height: '60px', width: '200px' }} />
                <h1>שלום {manager?.name}</h1>
            </div>
            <div className="row">
                <div className="col-3 mx-3">
                    <div className="row mt-2 mb-2">
                        <div className="col projectName">
                            <span className="postBadge badge rounded-pill  w-100 py-3">שם פרויקט:   {manager?.projectName}</span>
                        </div>
                    </div>
                    <div className="row mb-4">
                        <div className="col projectName">
                            <span className="postBadge badge rounded-pill  w-100 py-3">ע. מפיק פוסט:   {manager?.name}</span>
                        </div>
                    </div>
                    <div className="row mb-1">
                        <div className="col">
                            <Link to="/updateManager"><button className="btn btn-primary w-100">עדכון פרטים</button></Link>
                        </div>
                        <div className="col">
                            <Link to="/updateProject"><button className="btn btn-primary w-100">עדכון פרויקט</button></Link>
                        </div>
                    </div>
                    {/* <div className="row mb-1">
                        <div className="col">
                            <Link to="/budgetBuilding"><button className="btn btn-primary w-100"> בניית תקציב</button></Link>
                        </div>
                        <div className="col">
                            <Link to="/invocieEntry"><button className="btn btn-primary w-100">הזנת חשבוניות</button></Link>
                        </div>
                    </div> */}
                    <div className="row mb-1">
                        <div className="col">
                            <Link to="/selectEmployees"><button className="btn btn-primary w-100">שיבוץ עובדים</button></Link>
                        </div>
                        <div className="col">
                            <Link to="/salaryReport"><button className="btn btn-primary w-100">דוח שכר</button></Link>
                        </div>
                    </div>
                    <div className="row mb-1">
                        <div className="col">
                            <Link to="/monthlyReport"><button className="btn btn-primary w-100">דוח חודשי</button></Link>
                        </div>
                        <div className="col">
                            <Link to="/workSchedule"><button className="btn btn-primary w-100">לו״ז עבודה</button></Link>
                        </div>
                    </div>
                    <div className="row mb-1">
                        <div className="col">
                            <Link to="/"><button className="btn btn-danger w-100"
                                onClick={() => {
                                    navigate("/");
                                    sessionStorage.removeItem("userData");
                                    successMsg("(: נתראה בקרוב");
                                }}
                            >יציאה מהמערכת</button></Link>
                        </div>
                    </div>
                    <div className="row">
                        <div className="table-responsive" style={{ maxHeight: "300px", overflowY: "auto" }}>
                            <table className="table table-striped mt-3">
                                <thead className="thead-dark" style={{ position: "sticky", top: 0, zIndex: 1 }}>
                                    <tr className="table-info">
                                        <th scope="col">פרק בעבודה</th>
                                        <th scope="col">צפי משמרות לפרק</th>
                                        <th scope="col">מס' משמרות בפועל</th>
                                        <th scope="col">סטייה בפרקים</th>
                                    </tr>
                                </thead>
                                <tbody style={{ overflowY: "auto" }}>
                                    {(Array.from({ length: project?.currentEp || 0 }, (_, index) => index)).map((episode: number, index: number) => (
                                        <tr key={index}>
                                            <td> <Link to={`/checkList/${episode + 1}`} >{episode + 1}</Link></td>
                                            <td>{project?.shiftsForEp}</td>
                                            <td>{totalNumberOfShifts(episode + 1)}</td>
                                            <td style={{
                                                backgroundColor: (project?.shiftsForEp as number - totalNumberOfShifts(episode + 1)) < 0 ? '#fd4c4c' : ''
                                            }}>
                                                {project?.shiftsForEp as number - totalNumberOfShifts(episode + 1)}
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                        <div className="table-responsive" style={{ maxHeight: "200px", overflowY: "auto" }}>
                            <table className="table table-striped">
                                <thead className="thead-dark" style={{ position: "sticky", top: 0, zIndex: 1 }}>
                                    <tr className="table-info">
                                        <th scope="col">דוח עבודה יומי</th>
                                        <th scope="col"></th>
                                        <th scope="col">{date}</th>
                                    </tr>
                                </thead>
                                {userInfo?.length ? (
                                    <tbody style={{ overflowY: "auto" }}>
                                        {userInfo
                                            .filter((user: User) => user.dailyReport === false)
                                            .map((user: User) => (
                                                <tr key={user?.id}>
                                                    <td>{user?.name}</td>
                                                    <td></td>
                                                    <td><span className="badge bg-danger py-2">לא דווח</span></td>
                                                </tr>
                                            ))}
                                    </tbody>
                                ) : (
                                    <p> אין עובדים </p>
                                )}
                            </table>
                        </div>
                    </div>
                </div>
                <div className="col-8 mt-3">
                    <div className="gantt">
                        {gantt === 0 && <h1>תצוגת אופליין</h1>}
                        {gantt === 1 && <h1>תצוגת אונליין</h1>}
                        {gantt === 2 && <h1>תצוגת סאונד</h1>}
                        {gantt === 0 && <Gantt />}
                        {gantt === 1 && <Gantt />}
                        {gantt === 2 && <Gantt />}
                    </div>
                </div>
                <div className="col mt-4">
                    <button
                        className={`btn btn-outline-danger mb-3 ${gantt === 0 ? 'active' : ''}`}
                        onClick={() => handleButtonClick(0)}
                    >
                        אופליין
                    </button>
                    <button
                        className={`btn btn-outline-success mb-3 px-3 ${gantt === 1 ? 'active' : ''}`}
                        onClick={() => handleButtonClick(1)}
                    >
                        אונליין
                    </button>
                    <button
                        className={`btn btn-outline-secondary px-3 ${gantt === 2 ? 'active' : ''}`}
                        onClick={() => handleButtonClick(2)}
                    >
                        סאונד
                    </button>
                </div>
            </div>
        </div >
    </>);
}
export default AssistantDashboard;
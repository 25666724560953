import { FunctionComponent, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import "../css/editorWorkReport.css";
import User from "../interfaces/User";
import { getUserProfile, updateUser } from "../services/userServices";
import { useFormik } from "formik";
import * as yup from "yup";
import Report from "../interfaces/Report";
import { addReport, deleteReport, getReportsByName, updateReport } from "../services/reportServices";
import { successMsg } from "../services/feedbacks";
import Project from "../interfaces/Project";
import { getProjectByName, updateProject } from "../services/projectServices";

interface EditorWorkReportProps { }

const EditorWorkReport: FunctionComponent<EditorWorkReportProps> = () => {
    let [user, setUser] = useState<User>({
        username: "",
        password: "",
    });
    const navigate = useNavigate();
    let [reports, setReports] = useState<Report[]>([]);
    let [change, setChange] = useState<boolean>(false);
    const [editingReport, setEditingReport] = useState<Report | null>(null);
    const [selectedDate, setSelectedDate] = useState(new Date());
    const [startDate, setStartDate] = useState(new Date());
    const [endDate, setEndDate] = useState(new Date());
    const [displayMode, setDisplayMode] = useState<string>("default");
    const [displayedReports, setDisplayedReports] = useState<Report[]>([]);
    const [timeDifference, setTimeDifference] = useState({ hours: 0, minutes: 0 });
    const [project, setProject] = useState<Project | null>(null);

    const current = new Date();
    const monthName = current.toLocaleDateString("he-IL", { month: "long" });

    function calculateTimeDifference(start: string, end: string): { hours: number; minutes: number } {
        const [startHours, startMinutes] = start.split(":").map(Number);
        const [endHours, endMinutes] = end.split(":").map(Number);
        const totalStartMinutes = startHours * 60 + startMinutes;
        const totalEndMinutes = endHours * 60 + endMinutes;
        const timeDifferenceInMinutes = Math.abs(totalEndMinutes - totalStartMinutes);
        const hoursDifference = Math.floor(timeDifferenceInMinutes / 60);
        const minutesDifference = timeDifferenceInMinutes % 60;

        return { hours: hoursDifference, minutes: minutesDifference };
    }
    const calculateDailySalary = (userWage: string, workedTime: string): number => {
        const [hours, minutes] = workedTime.split(":").map(Number);
        const totalMinutes = hours * 60 + minutes;
        const payment = parseFloat(userWage);
        const dailySalary = payment * (totalMinutes / 60);
        return Math.round(dailySalary);
    };

    const updateTotalTime = (start: string, end: string) => {
        let timeDifference = calculateTimeDifference(start, end);
        if (isNaN(timeDifference.hours) || isNaN(timeDifference.minutes)) {
            timeDifference = { hours: 0, minutes: 0 };
        }
        setTimeDifference(timeDifference);
    };

    const formik = useFormik({
        initialValues: { editorName: "", date: "", projectName: "", episode: "", start: "", end: "" },
        validationSchema: yup.object({
            editorName: yup.string(),
            date: yup.string(),
            projectName: yup.string(),
            episode: yup.string(),
            start: yup.string(),
            end: yup.string(),
        }),

        onSubmit: (values: Report) => {
            const timeDifference = calculateTimeDifference(values.start as string, values.end as string);
            const totalHours = timeDifference.hours;
            const totalMinutes = timeDifference.minutes;
            const total = `${totalHours}:${totalMinutes}`;
            const dailySalary = calculateDailySalary(user.actualWage as string, total as string);

            let start = values.start;
            let end = values.end;
            if (values.episode === "חופש" || values.episode === "מחלה") {
                start = "00:00";
                end = "00:00";
            }

            const newReport = {
                ...values,
                editorName: user?.name as string,
                projectName: formik.values.projectName as string || user.projectName as string,
                start: start,
                end: end,
                date: selectedDate.toLocaleDateString("he-IL"),
                total: total,
                dailySalary: dailySalary || 0,
                monthName: monthName,
                confirmed: false,
            };

            if (editingReport) {
                // If there is a report for editing, update it instead of adding a new one
                updateReport({ ...editingReport, ...newReport })
                    .then((res) => {
                        successMsg("הדיווח עודכן בהצלחה");
                        setChange(!change);
                        setEditingReport(null);
                        // Reset form values to empty values
                        formik.resetForm();
                    })
                    .catch((err) => console.log(err));
            } else {
                // If there is no report for editing, add a new one
                if (project && (newReport.episode !== "חופש" && newReport.episode !== "מחלה" && newReport.episode !== "כללי")) {
                    project.totalEditShifts = (project.totalEditShifts || 0) + 1;
                    updateProject(project as Project)
                }
                addReport(newReport)
                    .then((res) => {
                        user?.reports?.push(newReport);
                        successMsg("דיווח נוסף בהצלחה");
                        setChange(!change);
                        // Reset form values to empty values
                        formik.resetForm();
                    })
                    .catch((err) => console.log(err));
            }
        },
    });

    const startEditingReport = (report: Report) => {
        setEditingReport(report);
        formik.setValues({
            editorName: report.editorName,
            date: report.date,
            projectName: report.projectName,
            episode: report.episode,
            start: report.start,
            end: report.end,
            notes: report.notes,
        });
    };

    useEffect(() => {
        getUserProfile()
            .then((res) => {
                setUser(res.data);
                getProjectByName(res.data.projectName as string).then((res) => { setProject(res.data) })
                    .catch((error) => console.log(error));
                getReportsByName(res.data.name as string)
                    .then((reportsRes) => {
                        setReports(reportsRes.data.reverse());
                    })
                    .catch((error) => console.log(error));
            })
            .catch((error) => console.log(error));
    }, [change, editingReport]);

    const sortedReports = reports
        .sort((a, b) => new Date(b.date as string).getTime() - new Date(a.date as string).getTime())
        .slice(0, 7); // להציג רק 7 שורות

    const handleDateRangeSubmit = () => {
        const filteredReports = reports.filter((report) => {
            try {
                const reportDateParts = report.date?.split('.');
                if (!reportDateParts || reportDateParts.length !== 3) {
                    return false;
                }
                const reportDate = new Date(`${reportDateParts[2]}-${reportDateParts[1]}-${reportDateParts[0]}`);
                if (isNaN(reportDate.getTime())) {
                    return false;
                }
                return reportDate >= startDate && reportDate <= endDate;
            } catch (error) {
                return false;
            }
        });
        setDisplayedReports(filteredReports);
        setDisplayMode("filtered");
    };

    return (
        <>
            <div className="editorWorkPanel">
                <div className="row editorWorkHeadline">
                    <img src="/Monica_Logo@0.25x.png" alt="Logo" style={{ height: '60px', width: '200px' }} />
                    <h1>דיווח עבודה</h1>
                </div>
                <div className="row mt-2">
                    <div>
                        <h2 className="mt-2">הוספת דיווח</h2>
                        <form onSubmit={formik.handleSubmit}>
                            <table className="table table-striped mt-4">
                                <thead>
                                    <tr className="table-info">
                                        <th scope="col">פרויקט</th>
                                        <th scope="col">תאריך</th>
                                        <th scope="col">פרק</th>
                                        <th scope="col">שעת התחלה</th>
                                        <th scope="col">שעת סיום</th>
                                        <th scope="col">סה״כ</th>
                                        <th scope="col">על מה עבדתי היום?</th>
                                        <th scope="col"></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td> {user.secProject ? (
                                            <select
                                                className="episodeSel custom-select mt-1"
                                                id="projectSel"
                                                name="projectName"
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                                value={formik.values.projectName || ""}
                                            >
                                                <option value={user.projectName}>{user.projectName}</option>
                                                <option value={user.secProject}>{user.secProject}</option>
                                            </select>
                                        ) : (
                                            user.projectName
                                        )} </td>
                                        <td>
                                            <input
                                                type="date"
                                                value={selectedDate.toISOString().split("T")[0]}
                                                onChange={(e) => setSelectedDate(new Date(e.target.value))}
                                            />
                                        </td>
                                        <td>
                                            <select
                                                className="episodeSel custom-select mt-1"
                                                id="episodeSel"
                                                name="episode"
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                                value={formik.values.episode || ""}
                                            >
                                                <option value="">בחר/י</option>
                                                <option value="כללי">כללי</option>
                                                <option value="חופש">חופש</option>
                                                <option value="מחלה">מחלה</option>
                                                {Array.from({ length: 30 }, (_, i) => i + 1).map((num) => (
                                                    <option key={num.toString()} value={num.toString()}>
                                                        {num}
                                                    </option>
                                                ))}
                                            </select>
                                        </td>
                                        <td>
                                            {formik.values.episode === "חופש" || formik.values.episode === "מחלה" ? (
                                                <input
                                                    type="text"
                                                    className="input-sm w-75"
                                                    id="floatingStart"
                                                    name="start"
                                                    value={0}
                                                    disabled={true}
                                                />
                                            ) : (
                                                <input
                                                    type="text"
                                                    className="input-sm w-75"
                                                    id="floatingStart"
                                                    name="start"
                                                    value={formik.values.start}
                                                    onChange={(e) => {
                                                        formik.handleChange(e);
                                                        const startValue = e.target.value || "";
                                                        const endValue = formik.values.end || "";
                                                        updateTotalTime(startValue, endValue);
                                                    }}
                                                    onBlur={formik.handleBlur}
                                                />
                                            )}
                                        </td>
                                        <td>
                                            {formik.values.episode === "חופש" || formik.values.episode === "מחלה" ? (
                                                <input
                                                    type="text"
                                                    className="input-sm w-75"
                                                    id="floatingEnd"
                                                    name="end"
                                                    value={0}
                                                    disabled={true}
                                                />
                                            ) : (
                                                <input
                                                    type="text"
                                                    className="input-sm w-75"
                                                    id="floatingEnd"
                                                    name="end"
                                                    value={formik.values.end}
                                                    onChange={(e) => {
                                                        formik.handleChange(e);
                                                        const startValue = formik.values.start || "";
                                                        const endValue = e.target.value || "";
                                                        updateTotalTime(startValue, endValue);
                                                    }}
                                                    onBlur={formik.handleBlur}
                                                />
                                            )}
                                        </td>
                                        <td>
                                            <input
                                                type="text"
                                                disabled={true}
                                                className="input-sm w-75"
                                                id="floatingTotal"
                                                name="total"
                                                value={`${timeDifference.hours}:${timeDifference.minutes}`}
                                            />
                                        </td>
                                        <td>
                                            <input
                                                type="text"
                                                className="input-sm w-75"
                                                id="floatingInput"
                                                name="notes"
                                                value={formik.values.notes}
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                            />
                                        </td>
                                        <td>
                                            <button
                                                type="submit"
                                                disabled={!formik.isValid || !formik.dirty}
                                                className="btn btn-info"
                                                onClick={() => {
                                                    user.dailyReport = true;
                                                    updateUser(user);
                                                }}
                                            >
                                                שמור
                                            </button>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </form>
                    </div>
                </div>
                <div className="row mx-auto mt-5">
                    <div className="col-1 m-2">
                        <label htmlFor="start">תאריך התחלה:</label>
                        <input className=""
                            type="date"
                            id="start"
                            name="start"
                            value={startDate.toISOString().split("T")[0]}
                            onChange={(e) => setStartDate(new Date(e.target.value))}
                        />
                    </div>
                    <div className="col-1 m-2">
                        <label htmlFor="end">תאריך סיום:</label>
                        <input
                            type="date"
                            id="end"
                            name="end"
                            value={endDate.toISOString().split("T")[0]}
                            onChange={(e) => setEndDate(new Date(e.target.value))}
                        />
                    </div>
                    <div className="col-1 mt-4">
                        <button
                            type="button"
                            className="btn btn-primary mx-1"
                            onClick={() => {
                                handleDateRangeSubmit();
                                setDisplayMode("filtered");
                                setChange(!change);
                            }}
                        >
                            בחר
                        </button>
                        <button
                            type="button"
                            className="btn btn-primary"
                            onClick={() => {
                                setDisplayMode("default");
                                setChange(!change)
                            }}
                        >
                            נקה
                        </button>
                    </div>
                    <div className="col-5 mx-4 mt-3">
                        <h2 className="">דיווחים</h2>
                    </div>
                </div>
                <table className="table table-striped mt-3">
                    <thead>
                        <tr className="table-primary">
                            <th scope="col">פרויקט</th>
                            <th scope="col">תאריך</th>
                            <th scope="col">פרק</th>
                            <th scope="col">שעת התחלה</th>
                            <th scope="col">שעת סיום</th>
                            <th scope="col">סה״כ</th>
                            <th scope="col">על מה עבדתי היום?</th>
                            <th scope="col"></th>
                            <th scope="col"></th>
                        </tr>
                    </thead>
                    <tbody>
                        {displayMode === "default" ? (
                            sortedReports.map((report: Report) => (

                                <tr key={report._id}>
                                    <td>{report.projectName}</td>
                                    <td>{report.date}</td>
                                    <td>{report.episode}</td>
                                    <td>{report.start}</td>
                                    <td>{report.end}</td>
                                    <td>{isNaN(parseFloat(report.total || "")) ? 0 : report.total}</td>
                                    <td>{report.notes}</td>
                                    <td>
                                        <button className="btn btn-warning btn-sm" onClick={() => startEditingReport(report)}>
                                            ערוך
                                        </button>
                                    </td>
                                    <td>
                                        <button
                                            className="btn btn-secondary btn-sm"
                                            onClick={() => {
                                                deleteReport(report._id as string);
                                                if (project && (report.episode !== "חופש" && report.episode !== "מחלה" && report.episode !== "כללי")) {
                                                    project.totalEditShifts = (project.totalEditShifts || 0) - 1;
                                                    updateProject(project as Project)
                                                }
                                                setChange(!change);
                                            }}
                                        >
                                            הסר
                                        </button>
                                    </td>
                                </tr>
                            ))
                        ) : (
                            displayedReports.map((report: Report) => (
                                <tr key={report._id}>
                                    <td>{report.projectName}</td>
                                    <td>{report.date}</td>
                                    <td>{report.episode}</td>
                                    <td>{report.start}</td>
                                    <td>{report.end}</td>
                                    <td>{isNaN(parseFloat(report.total || "")) ? 0 : report.total}</td>
                                    <td>{report.notes}</td>
                                    <td>
                                        <button className="btn btn-warning btn-sm" onClick={() => startEditingReport(report)}>
                                            ערוך
                                        </button>
                                    </td>
                                    <td>
                                        <button
                                            className="btn btn-secondary btn-sm"
                                            onClick={() => {
                                                deleteReport(report._id as string);
                                                setChange(!change);
                                            }}
                                        >
                                            הסר
                                        </button>
                                    </td>
                                </tr>
                            ))
                        )}
                    </tbody>
                </table>
                <div className="row empsSalRepOptions mx-auto col-10 col-md-8 col-lg-6 mt-5">
                    <p
                        onClick={() => navigate(-1)}
                        style={{
                            color: "blue",
                            textDecoration: "underline",
                            cursor: "pointer",
                        }}
                    >
                        חזור
                    </p>
                    <p style={{ color: 'lightgray' }}>Almog Liebermensch 2024 ©</p>
                </div>
            </div >
        </>
    );
};

export default EditorWorkReport;

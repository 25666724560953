import axios from "axios";
import Budget from "../interfaces/Budget";

const api: string = process.env.REACT_APP_API + "/budgets" || "";

// add new Report
export function addInvoice(newBudget: Budget) {
    return axios.post(`${api}`, newBudget, { headers: { Authorization: JSON.parse(sessionStorage.getItem("userData") as string).token } });
}

// get Reports by name 
export function getBudgetsByProject(projectName: string) {
    return axios.get(`${api}/${projectName}`, { headers: { Authorization: JSON.parse(sessionStorage.getItem("userData") as string).token } })
}

// delete Report
// export function deleteReport(id: string) {
//     return axios.delete(`${api}/${id}`, { headers: { Authorization: JSON.parse(sessionStorage.getItem("userData") as string).token } });
// }

// update Budget
export function updateBudget(newBudget: Budget) {
    return axios.put(`${api}/${newBudget._id}`, newBudget);
}
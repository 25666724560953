import React, { FunctionComponent, useEffect, useState } from "react";
import Manager from "../interfaces/Manager";
import Project from "../interfaces/Project";
import { getPostManagers, getProjectName, updateManager } from "../services/managerServices";
import { getProjectByName, updateProject } from "../services/projectServices";

interface ListOfManagersProps {
    refresh: Function;
    onAddClick: Function;
}

const ListOfManagers: FunctionComponent<ListOfManagersProps> = ({ refresh, onAddClick }) => {
    const [project, setProject] = useState<Project | null>(null);
    const [projectName, setProjectName] = useState<string>("");
    const [managers, setManagers] = useState<Manager[]>([]);
    const [change, setChange] = useState<boolean>(false);
    const [selectedManager, setSelectedManager] = useState<Manager | null>(null);
    const [searchName, setSearchName] = useState<string>("");

    useEffect(() => {
        getPostManagers()
            .then((res) => setManagers(res.data))
            .catch((err) => console.log(err));
    }, [change]);

    const handleAddButtonClick = (manager: Manager) => {
        setSelectedManager(manager);
        onAddClick(manager);
    };

    const formatDate = (dateString: string) => {
        const date = new Date(dateString);
        const day = date.getDate();
        const month = date.getMonth() + 1;
        const year = date.getFullYear();

        const formattedDate = `${day}/${month}/${year}`;
        return formattedDate;
    };

    const handleSearchByName = () => {
        // Perform the search by name logic here
        setChange(!change); // Trigger a re-render to update the table
    };

    return (
        <div className="row w-100 mx-auto">
            <div className="col-2">
                <input
                    type="text"
                    className="form-control"
                    placeholder="חיפוש לפי שם"
                    value={searchName}
                    onChange={(e) => setSearchName(e.target.value)}
                />
            </div>
            <div className="table-responsive" style={{ maxHeight: "300px", overflowY: "auto" }}>
                {managers.length ? (
                    <table className="table table-hover mt-2">
                        <thead className="table-success" style={{ position: "sticky", top: 0, zIndex: 1 }}>
                            <tr>
                                <th scope="col">שם מלא</th>
                                <th scope="col">תפקיד</th>
                                <th scope="col">טלפון</th>
                                <th scope="col">ז'אנר</th>
                                <th scope="col">שנות וותק</th>
                                <th scope="col">זמינות</th>
                                <th scope="col">הערות</th>
                                <th scope="col"></th>
                            </tr>
                        </thead>
                        <tbody>
                            {managers
                                ?.filter((manager) => manager.status === "פנוי/ה" && (searchName === "" || (manager.name && manager.name.includes(searchName))))
                                .map((manager: Manager) => (
                                    <tr key={manager.id}>
                                        <td>{manager.name}</td>
                                        <td>{manager.position}</td>
                                        <td>{manager.phone}</td>
                                        <td>{manager.genre}</td>
                                        <td>{manager.experience}</td>
                                        <td>{formatDate(manager.avilabale as string)}</td>
                                        <td>{manager.freeText}</td>
                                        <td>
                                            <button
                                                className="btn btn-success btn-sm"
                                                onClick={() => {
                                                    handleAddButtonClick(manager);
                                                }}
                                            >
                                                הוסף
                                            </button>
                                        </td>
                                    </tr>
                                ))}
                        </tbody>
                    </table>
                ) : (
                    <p> </p>
                )}
            </div>
        </div>
    );
};

export default ListOfManagers;

import { FunctionComponent, useState } from "react";
import { ViewMode } from "react-gantt-chart";

interface ViewSwitcherProps {
    isChecked: boolean;
    onViewListChange: (isChecked: boolean) => void;
    onViewModeChange: (viewMode: ViewMode) => void;
}

const ViewSwitcher: FunctionComponent<ViewSwitcherProps> = (props) => {
    // *** PROPS ***
    const { onViewModeChange, onViewListChange, isChecked } = props;
    const [currentView, setCurrentView] = useState<ViewMode>(ViewMode.Month);

    return (
        <div className="ViewContainer mt-3">
            <button
                className={`btn btn-outline-primary btn-sm mx-1 mb-4 ${currentView === ViewMode.Day ? 'active' : ''}`}
                onClick={() => {
                    setCurrentView(ViewMode.Day);
                    onViewModeChange(ViewMode.Day);
                }}
            >
                יומית
            </button>
            <button
                className={`btn btn-outline-primary btn-sm mx-1 mb-4 ${currentView === ViewMode.Week ? 'active' : ''}`}
                onClick={() => {
                    setCurrentView(ViewMode.Week);
                    onViewModeChange(ViewMode.Week);
                }}
            >
                שבועית
            </button>
            <button
                className={`btn btn-outline-primary btn-sm mx-1 mb-4 ${currentView === ViewMode.Month ? 'active' : ''}`}
                onClick={() => {
                    setCurrentView(ViewMode.Month);
                    onViewModeChange(ViewMode.Month);
                }}
            >
                חודשית
            </button>
            {/* <button
                className="btn btn-outline-primary btn-sm mx-1"
                onClick={() => onViewModeChange(ViewMode.HalfDay)}
            >
                Half of Day
            </button> */}
            {/* <button
                className="btn btn-outline-primary btn-sm mx-1"
                onClick={() => onViewModeChange(ViewMode.QuarterDay)}
            >
                Quarter of Day
            </button> */}
            {/* <div className="Switch">
                <label className="Switch_Toggle">
                    <input className="mx-2 mt-4"
                        type="checkbox"
                        defaultChecked={isChecked}
                        onClick={() => onViewListChange(!isChecked)}
                    />Show Task List
                    <span className="Slider" />
                </label>
            </div> */}
        </div>
    );
}

export default ViewSwitcher;
import axios from "axios";
import Invoice from "../interfaces/Invoice";

const api: string = process.env.REACT_APP_API + "/invoices" || "";

// add new Report
export function addInvoice(newInvoice: Invoice) {
    return axios.post(`${api}`, newInvoice, { headers: { Authorization: JSON.parse(sessionStorage.getItem("userData") as string).token } });
}

// get Reports by name 
export function getInvoicesByName(name: string) {
    return axios.get(`${api}/${name}`, { headers: { Authorization: JSON.parse(sessionStorage.getItem("userData") as string).token } })
}

// delete Invoice
export function deleteInvoice(id: string) {
    return axios.delete(`${api}/${id}`, { headers: { Authorization: JSON.parse(sessionStorage.getItem("userData") as string).token } });
}

// update Report
export function updateInvoice(newInvoice: Invoice) {
    return axios.put(`${api}/${newInvoice._id}`, newInvoice);
}
import { FunctionComponent, useEffect, useState } from "react";
import Manager from "../interfaces/Manager";
import { getPostManagers } from "../services/managerServices";
import { getProjectByName } from "../services/projectServices";

interface ListOfManagerByMonthProps {
    refresh: Function;
    onAddClick: Function;

}

const ListOfManagerByMonth: FunctionComponent<ListOfManagerByMonthProps> = ({ refresh, onAddClick }) => {
    let [managers, setManagers] = useState<Manager[]>([]);
    const [searchDate, setSearchDate] = useState<string>("");
    const [searchValue, setSearchValue] = useState<string>("");
    const [searchName, setSearchName] = useState<string>("");
    const [selectedManager, setSelectedManager] = useState<Manager | null>(null);
    const [nextProjectDates, setNextProjectDates] = useState<{ [key: string]: string | undefined }>({});

    const handleSearchButtonClick = () => {
        setSearchValue(searchDate);
    };

    const handleNameSearch = () => {
        setSearchValue(""); // איפוס ערך חיפוש לפי תאריך
        setSearchName(searchName.trim()); // מחיקת רווחים מיותרים
    };

    const filteredManagers = managers.filter((manager) => {
        if (!searchValue) return true;
        return new Date(manager.avilabale as string) <= new Date(searchValue);
    });

    const filteredManagersByName = filteredManagers.filter((manager) => {
        if (!searchName) return true;
        return manager.name && manager.name.toLowerCase().includes(searchName.toLowerCase());
    });

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await getPostManagers();
                setManagers(response.data);

                const dates: { [key: string]: string } = {};
                for (const manager of response.data) {
                    try {
                        const project = await getProjectByName(manager.nextProject);
                        if (project.data.startDate) {
                            dates[manager.id] = formatDate(project.data.startDate);
                        } else {
                            dates[manager.id] = "אין";
                        }
                    } catch (error) {
                        console.log(error);
                    }
                }
                setNextProjectDates(dates);
            } catch (error) {
                console.log(error);
            }
        };
        fetchData();
    }, []);

    const handleAddButtonClick = (manager: Manager) => {
        setSelectedManager(manager);
        onAddClick(manager); // הוספת שורה זו
    };

    const formatDate = (dateString: string) => {
        const date = new Date(dateString);
        const day = date.getDate();
        const month = date.getMonth() + 1; // החודשים מתחילים מ-0
        const year = date.getFullYear();

        // צור מחרוזת בפורמט DD/MM/YYYY
        const formattedDate = `${day}/${month}/${year}`;

        return formattedDate;
    };

    return (<>
        <div className="row">
            <input
                className="col-2 mx-4"
                type="text"
                value={searchName}
                onChange={(e) => setSearchName(e.target.value)}
                placeholder="חיפוש לפי שם"
            />
            <input
                className="col-1 mx-3"
                type="date"
                value={searchDate}
                onChange={(e) => setSearchDate(e.target.value)}
                placeholder="חיפוש לפי תאריך"
            />
            <button className="btn btn-warning col-1" onClick={handleSearchButtonClick}>
                חפש לפי תאריך
            </button>
        </div>
        <div className="row w-100 mx-auto">
            <div className="table-responsive" style={{ maxHeight: "300px", overflowY: "auto" }}>
                {filteredManagersByName.length ? (
                    <table className="table table-hover mt-2">
                        <thead className="table-warning" style={{ position: "sticky", top: 0, zIndex: 1 }}>
                            <tr>
                                <th scope="col">שם מלא</th>
                                <th scope="col">תפקיד</th>
                                <th scope="col">טלפון</th>
                                <th scope="col">פרויקט נוכחי</th>
                                <th scope="col">סיום פרויקט</th>
                                <th scope="col">פרויקט הבא</th>
                                <th scope="col">ז׳אנר</th>
                                <th scope="col">שנות וותק</th>
                                <th scope="col">הערות</th>
                                <th scope="col"></th>
                            </tr>
                        </thead>
                        <tbody>
                            {filteredManagersByName?.map((manager: Manager) => (
                                <tr key={manager.id}>
                                    <td>{manager.name}</td>
                                    <td>{manager.position}</td>
                                    <td>{manager.phone}</td>
                                    <td>{manager.projectName}</td>
                                    <td>{formatDate(manager.avilabale as string)}</td>
                                    <td>{manager.id && nextProjectDates[manager.id] ? nextProjectDates[manager.id] : 'אין'}</td>
                                    <td>{manager.genre}</td>
                                    <td>{manager.experience}</td>
                                    <td>{manager.freeText}</td>
                                    <td>
                                        <button className="btn btn-warning btn-sm" onClick={() => handleAddButtonClick(manager)}>
                                            הוסף
                                        </button>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                ) : (
                    <p>אין תוצאות</p>
                )}
            </div>
        </div>
    </>
    );
}

export default ListOfManagerByMonth;
import { FunctionComponent, useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import User from "../interfaces/User";
import Project from "../interfaces/Project";
import { getProjectName, getUsersbyProject } from "../services/userServices";
import { getReportsByName } from "../services/reportServices";
import Report from "../interfaces/Report";
import { getProjectByName } from "../services/projectServices";
import { getManagerProfile } from "../services/managerServices";
import Manager from "../interfaces/Manager";

interface EditorScreenProps {

}

const EditorScreen: FunctionComponent<EditorScreenProps> = () => {
    const navigate = useNavigate();
    let [project, setProject] = useState<Project | null>(null);
    let [manager, setManager] = useState<Manager | null>(null);
    let [projectName, setProjectName] = useState<string>("");
    let [users, setUsers] = useState<User[]>([]);
    let [change, setChange] = useState<boolean>(false);
    let [reports, setReports] = useState<Report[]>([]);
    const [startDate, setStartDate] = useState(new Date());
    const [endDate, setEndDate] = useState(new Date());
    const [displayMode, setDisplayMode] = useState<string>("default");
    const [displayedReports, setDisplayedReports] = useState<Report[]>([]);
    const [selectedUser, setSelectedUser] = useState<User | null>(null);

    const { projectNameParams, editorName } = useParams();

    useEffect(() => {
        async function fetchData() {
            const managerResponse = await getManagerProfile();
            setManager(managerResponse.data);
            try {
                if (projectNameParams) {
                    setProjectName(projectNameParams);
                    const usersData = await getUsersbyProject(projectNameParams as string);
                    setUsers(usersData.data);
                }
                else {
                    const projectName = await getProjectName();
                    setProjectName(projectName);

                    const usersData = await getUsersbyProject(projectName as string);
                    setUsers(usersData.data);
                }
                handleUserClick(editorName as string);

            } catch (error) {
                console.error(error);
            }
        };
        fetchData();
    }, [change, projectName]);

    const sortedReports = reports
        .sort((a, b) => new Date(b.date as string).getTime() - new Date(a.date as string).getTime())
        .slice(0, 7); // להציג רק 7 שורות
    console.log(sortedReports);

    const handleUserClick = async (userName: string) => {
        try {
            const userReports = await getReportsByName(userName);
            setReports(userReports.data.reverse());
            setDisplayMode("default");

            const selected = users.find((user) => user.name === userName);
            setSelectedUser(selected || null);
        } catch (error) {
            console.error(error);
        }
    };

    const handleDateRangeSubmit = () => {
        const filteredReports = reports.filter((report) => {
            try {
                const reportDateParts = report.date?.split('.');
                if (!reportDateParts || reportDateParts.length !== 3) {
                    return false;
                }
                const reportDate = new Date(`${reportDateParts[2]}-${reportDateParts[1]}-${reportDateParts[0]}`);
                if (isNaN(reportDate.getTime())) {
                    return false;
                }
                return reportDate >= startDate && reportDate <= endDate;
            } catch (error) {
                return false;
            }
        });
        setDisplayedReports(filteredReports);
        setDisplayMode("filtered");
    };

    return (<>
        <style>
            {`
.employee-list {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  gap: 10px 20px;
  max-height: 200px;
  overflow-y: auto;
}

.employee-item {
  background-color: #f8f9fa;
  border: 1px solid #dee2e6;
  padding: 10px;
  border-radius: 7px;
  transition: background-color 0.3s ease;
}

.employee-item:hover {
  background-color: #e2e6ea;
}
        `}
        </style>
        <div className="mainDbPanel">
            <div className="row mainDbHeadline">
                <img src="/Monica_Logo@0.25x.png" alt="Logo" style={{ height: '60px', width: '200px' }} />
                <h1>מסך עובדים</h1>
            </div>
            <div className="mx-auto col-10 col-md-8 col-lg-6 m-4">
                <div className="userInfo">
                    <div className="row mb-5 mt-5">
                        <div className="col-10 mx-auto">
                            {/* <h3>זמינות שבועית:</h3> */}
                            <div className="table-responsive">
                                <table className="text-center">
                                    <div className="employee-list">
                                        {users?.length ? (
                                            users.map((user: User, index: number) => (
                                                <Link
                                                    to={`/editorScreen/${user.name}`}
                                                    onClick={() => handleUserClick(user.name as string)}
                                                    className="table-link"
                                                > <div
                                                    key={user?.id}
                                                    className="employee-item"
                                                >

                                                        {user?.name}
                                                    </div>
                                                </Link>

                                            ))
                                        ) : (
                                            <div className="employee-item">
                                                אין עובדים
                                            </div>
                                        )}
                                    </div>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="row mx-auto mt-5">
                <div className="col-1 m-2">
                    <label htmlFor="start">תאריך התחלה:</label>
                    <input className=""
                        type="date"
                        id="start"
                        name="start"
                        value={startDate.toISOString().split("T")[0]}
                        onChange={(e) => setStartDate(new Date(e.target.value))}
                    />
                </div>
                <div className="col-1 m-2">
                    <label htmlFor="end">תאריך סיום:</label>
                    <input
                        type="date"
                        id="end"
                        name="end"
                        value={endDate.toISOString().split("T")[0]}
                        onChange={(e) => setEndDate(new Date(e.target.value))}
                    />
                </div>
                <div className="col-1 mt-4">
                    <button
                        type="button"
                        className="btn btn-primary mx-1"
                        onClick={() => {
                            handleDateRangeSubmit();
                            setDisplayMode("filtered");
                            setChange(!change);
                        }}
                    >
                        בחר
                    </button>
                    <button
                        type="button"
                        className="btn btn-primary"
                        onClick={() => {
                            setDisplayMode("default");
                            setChange(!change)
                        }}
                    >
                        נקה
                    </button>
                </div>
                <div className="col-5 mx-4 mt-3">
                    <h2 className="">{selectedUser?.name || editorName}</h2>
                </div>
            </div>
            <table className="table table-striped mt-3">
                <thead>
                    <tr className="table-primary">
                        <th scope="col">תאריך</th>
                        <th scope="col">פרויקט</th>
                        <th scope="col">פרק</th>
                        <th scope="col">שעת התחלה</th>
                        <th scope="col">שעת סיום</th>
                        <th scope="col">שעות עבודה</th>
                        <th scope="col">שכר יומי</th>
                        <th scope="col">הערות</th>
                    </tr>
                </thead>
                <tbody>
                    {displayMode === "default" ? (
                        sortedReports.map((report: Report) => (
                            <tr key={report._id}>
                                <td>{report.date}</td>
                                <td>{report.projectName}</td>
                                <td>{report.episode}</td>
                                <td>{report.start}</td>
                                <td>{report.end}</td>
                                <td>{report.total}</td>
                                <td>₪ {report.dailySalary}</td>
                                <td>{report.notes}</td>
                            </tr>
                        ))
                    ) : (
                        displayedReports.map((report: Report) => (
                            <tr key={report._id}>
                                <td>{report.date}</td>
                                <td>{report.projectName}</td>
                                <td>{report.episode}</td>
                                <td>{report.start}</td>
                                <td>{report.end}</td>
                                <td>{report.total}</td>
                                <td>₪ {report.dailySalary}</td>
                                <td>{report.notes}</td>
                            </tr>
                        ))
                    )}
                </tbody>
            </table>
            <div className="row empsSalRepOptions mx-auto col-10 col-md-8 col-lg-6 mt-5">
                {manager?.position === "מפיקה ראשית" &&
                    <Link
                        to={`/projectDashboard/${projectName}`}
                    >חזור</Link>
                }
                {manager?.position !== "מפיקה ראשית" &&
                    <p onClick={() => navigate("/postDashboard")} style={{
                        color: 'blue',
                        textDecoration: 'underline',
                        cursor: 'pointer',
                    }}>חזור</p>}
                <p style={{ color: 'lightgray' }}>Almog Liebermensch 2024 ©</p>
            </div>
        </div >
    </>);
}

export default EditorScreen;
import axios from "axios";
import EpisodeReport from "../interfaces/EpisodeReport";

const api: string = process.env.REACT_APP_API + "/episodeReports" || "";

// add new Report
export function addEpisodeReport(newReport: EpisodeReport) {
    return axios.post(`${api}`, newReport, { headers: { Authorization: JSON.parse(sessionStorage.getItem("userData") as string).token } });
}

// get Reports by name and number
export function getEpisodeReportsByEpisode(name: string, episode: number) {
    return axios.get(`${api}/${name}/${episode}`, { headers: { Authorization: JSON.parse(sessionStorage.getItem("userData") as string).token } })
}

// delete Report
export function deleteReport(id: string) {
    return axios.delete(`${api}/${id}`, { headers: { Authorization: JSON.parse(sessionStorage.getItem("userData") as string).token } });
}

// update Report
export function updateEpisodeReport(newDetails: EpisodeReport) {
    return axios.put(`${api}/${newDetails.episode}`, newDetails);
}
import axios from "axios";
import Schedule from "../interfaces/Schedule";


const api: string = process.env.REACT_APP_API || "";


// get Schedule by name
export function getScheduleByName(name: string) {
    return axios.get(`${api}/schedules/${name}`, { headers: { Authorization: JSON.parse(sessionStorage.getItem("userData") as string).token } })
}

// update Schedule
export function updateSchedule(newDetails: Schedule) {
    return axios.put(`${api}/schedules/${newDetails._id}`, newDetails);
}

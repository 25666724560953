import { FunctionComponent, useEffect, useState } from "react";
import Project from "../interfaces/Project";
import { getProjectName } from "../services/userServices";
import { addCustomItemToProject, getProjectByName } from "../services/projectServices";
import { useNavigate } from "react-router-dom";
import Invoice from "../interfaces/Invoice";
import { addInvoice, getInvoicesByName } from "../services/invoiceServices";
import { getBudgetsByProject, updateBudget } from "../services/budgetServices";
import Budget from "../interfaces/Budget";
import { successMsg } from "../services/feedbacks";
import Manager from "../interfaces/Manager";
import { getManagerProfile } from "../services/managerServices";

interface BudgetBuildingProps {

}
interface RowData {
    cost: number;
    amount: number;
    units: number;
    total: number;
}
interface CustomItem {
    code: number;
    description: string;
}
const selectionArray = [
    { code: 1101, description: "ע' מפיקת פוסט" },
    { code: 1102, description: "דגימה+עוזר עריכה+סידור" },
    { code: 1103, description: "השכרת שטח אחסון" },
    { code: 1104, description: "גיבוי חומרי גלם" },
    { code: 1105, description: "קלטות lto" },
    { code: 1106, description: "חדר אופליין - ויטיארים" },
    { code: 1107, description: "עורך אופליין- ויטיארים" },
    { code: 1108, description: "עורך אופליין- מוקלטות" },
    { code: 1109, description: "קריינות" },
    { code: 1110, description: "דיגיטציה אונליין" },
    { code: 1111, description: "חדר דיגיטציה on line" },
    { code: 1112, description: "עורך אונליין" },
    { code: 1113, description: "חדר אונליין" },
    { code: 1114, description: "סאונד+חדר" },
    { code: 1115, description: "תרגום" },
    { code: 1116, description: "אפטר אפקט" },
    { code: 1117, description: "ייעוץ מוזיקלי" },
    { code: 1118, description: "אישורי אקום" },
    { code: 1119, description: 'ק"ק' },
    { code: 1120, description: "חומרי ארכיון" },
];
const BudgetBuilding: FunctionComponent<BudgetBuildingProps> = () => {
    const navigate = useNavigate();
    let [project, setProject] = useState<Project>({
        name: "",
        mainProducer: "",
    });
    const [projectData, setProjectData] = useState<Project | null>(null);
    let [change, setChange] = useState<boolean>(false);
    let [projectName, setProjectName] = useState<string>("");
    let [invoices, setInvoices] = useState<Invoice[]>([]);
    let [budgets, setBudgets] = useState<Budget[]>([]);
    let [manager, setManager] = useState<Manager | null>(null);
    const [selectedCode, setSelectedCode] = useState<number | null>(null);
    const [selectedDescription, setSelectedDescription] = useState<string>("");
    const [selectedNote, setSelectedNote] = useState<string>("");
    const [selectedPeriods, setSelectedPeriods] = useState<{ [key: string]: string }>({});

    const inputStyles = {
        fontSize: '14px',
        border: '1px solid rgba(0, 0, 0, 0.3)',
        backgroundColor: 'rgba(255, 255, 255, 0.8)',
    };
    useEffect(() => {
        const fetchData = async () => {
            try {
                const name = await getProjectName();
                setProjectName(name);

                const projectResponse = await getProjectByName(name);
                console.log(projectResponse.data);

                const fetchedProjectData = projectResponse.data;
                console.log(fetchedProjectData);

                setProjectData(fetchedProjectData);

                const maxCode = getMaxCode();
                setSelectedCode(maxCode + 1);
                const invoicesResponse = await getInvoicesByName(fetchedProjectData?.postName);
                const fetchedInvoices = invoicesResponse.data;

                const budgetResponse = await getBudgetsByProject(fetchedProjectData?.name);
                const fetchedBudget = budgetResponse.data;
                const managerResponse = await getManagerProfile();
                setManager(managerResponse.data);

                setInvoices(fetchedInvoices);
                setBudgets(fetchedBudget);
                const initialSelectedPeriods: { [key: string]: string } = fetchedBudget.reduce((acc: any, budget: any) => {
                    return { ...acc, [budget.invoiceId]: budget.period || "כללי" };
                }, {});

                setSelectedPeriods(initialSelectedPeriods);
                console.log(fetchedProjectData);
                console.log(fetchedProjectData?.budgetSoFar);

            } catch (error) {
                console.error(error);
            }
        };
        fetchData();
    }, [change]);

    useEffect(() => {
        if (projectData) {
            setProject({ ...projectData });
        }
    }, [projectData]);

    let refresh = () => {
        setChange(!change);
        setSelectedCode(getMaxCode() + 1);
        setSelectedDescription("");
        setSelectedNote("");
    }
    const sumBudgetSoFar = function (totalEditShifts: number) {
        return totalEditShifts * 1800;
    };

    const handleCostChangeForRow = (invoiceId: string) => (e: React.ChangeEvent<HTMLInputElement>) => {
        const newCost = parseFloat(e.target.value) || 0;
        setBudgets((prevBudgets) => {
            const updatedBudgets = prevBudgets.map((budget) =>
                budget.invoiceId === invoiceId ? { ...budget, cost: newCost } : budget
            );
            updateTotal(invoiceId, updatedBudgets);
            return updatedBudgets;
        });
    };

    const handleAmountChangeForRow = (invoiceId: string) => (e: React.ChangeEvent<HTMLInputElement>) => {
        const newAmount = parseFloat(e.target.value) || 0;
        setBudgets((prevBudgets) => {
            const updatedBudgets = prevBudgets.map((budget) =>
                budget.invoiceId === invoiceId ? { ...budget, amount: newAmount } : budget
            );
            updateTotal(invoiceId, updatedBudgets);
            return updatedBudgets;
        });
    };

    const handleUnitsChangeForRow = (invoiceId: string) => (e: React.ChangeEvent<HTMLInputElement>) => {
        const newUnits = parseFloat(e.target.value) || 0;
        setBudgets((prevBudgets) => {
            const updatedBudgets = prevBudgets.map((budget) =>
                budget.invoiceId === invoiceId ? { ...budget, units: newUnits } : budget
            );
            updateTotal(invoiceId, updatedBudgets);
            return updatedBudgets;
        });
    };

    const updateTotal = (invoiceId: string, updatedBudgets: Budget[]) => {
        const budget = updatedBudgets.find((budget) => budget.invoiceId === invoiceId);

        if (budget?.cost !== undefined && budget?.amount !== undefined && budget?.units !== undefined) {
            const newTotal = budget.cost * budget.amount * budget.units;
            setBudgets((prevBudgets) => {
                const updatedBudgets = prevBudgets.map((b) =>
                    b.invoiceId === invoiceId ? { ...b, total: newTotal } : b
                );
                updateBudgetOnServer(updatedBudgets.find(budget => budget.invoiceId === invoiceId) as Budget);
                return updatedBudgets;
            });
        }
    };

    const handlePeriodChange = (invoiceId: string) => (e: React.ChangeEvent<HTMLSelectElement>) => {
        const newPeriod = e.target.value;
        setSelectedPeriods((prevSelectedPeriods) => ({ ...prevSelectedPeriods, [invoiceId]: newPeriod }));
        setBudgets((prevBudgets) => {
            const updatedBudgets = prevBudgets.map((budget) =>
                budget.invoiceId === invoiceId ? { ...budget, period: newPeriod } : budget
            );
            updateTotal(invoiceId, updatedBudgets);
            updateBudgetOnServer(updatedBudgets.find((budget) => budget.invoiceId === invoiceId) as Budget);
            return updatedBudgets;
        });
    };

    const handleActualChangeForRow = (invoiceId: string) => (e: React.ChangeEvent<HTMLInputElement>) => {
        const newActual = parseFloat(e.target.value) || 0;
        setBudgets((prevBudgets) => {
            const updatedBudgets = prevBudgets.map((budget) =>
                budget.invoiceId === invoiceId ? { ...budget, actual: newActual } : budget
            );
            updateTotal(invoiceId, updatedBudgets);

            // Calculate and update whatsLeft and flow
            const budget = updatedBudgets.find((b) => b.invoiceId === invoiceId);
            if (budget?.total !== undefined) {
                const whatsLeft = budget.total - newActual;
                const flow = budget.total - newActual;
                setBudgets((prevBudgets) => {
                    const updatedBudgets = prevBudgets.map((b) =>
                        b.invoiceId === invoiceId ? { ...b, whatsLeft, flow } : b
                    );
                    updateBudgetOnServer(updatedBudgets.find((b) => b.invoiceId === invoiceId) as Budget);
                    return updatedBudgets;
                });
            }

            return updatedBudgets;
        });
    };

    const handleMonthChanges = (month: number[], invoiceId: string, monthName: string) => {
        console.log(month[0]);
        console.log(month[1]);

        const monthCopy = [...month];
        const difference = monthCopy[0] - monthCopy[1];
        monthCopy[2] = difference;

        // העדכון המקומי של החודש במציאות
        setBudgets((prevBudgets) => {
            const updatedBudgets = prevBudgets.map((budget) =>
                budget._id === invoiceId ? { ...budget, [monthName]: monthCopy } : budget
            );

            return updatedBudgets;
        });

        // העדכון בשרת - יש לשנות את הקריאה לפונקציה המתאימה בהתאם
        updateServerMonthData(invoiceId, monthName, monthCopy);
    };

    // פונקציה המעדכנת את המערך בשרת
    const updateServerMonthData = async (invoiceId: string, monthName: string, updatedMonth: number[]) => {
        try {
            console.log(`Updating server data for month ${monthName} on invoice ${invoiceId}:`, updatedMonth);

            // כאן יש לקרוא לפונקציה שתעדכן את המערך בשרת
            // זה יכול להיות לדוגמה updateMonthData(invoiceId, monthName, updatedMonth);

            console.log(`Server data updated successfully.`);
        } catch (error) {
            console.error(`Error updating server data for month ${monthName} on invoice ${invoiceId}: ${error}`);
        }
    };

    const updateBudgetOnServer = async (updatedBudget: Budget | undefined) => {
        try {
            console.log('Updated Budget:', updatedBudget); // Log the updatedBudget object
            if (updatedBudget && updatedBudget._id) {
                await updateBudget(updatedBudget);
                console.log(`Budget updated successfully on the server.`);
            } else {
                console.error(`Error updating budget on the server: _id is undefined`);
            }
        } catch (error) {
            console.error(`Error updating budget on the server: ${error}`);
        }
    };

    const addCustomItem = async () => {
        const code = parseInt((document.getElementById("floatingNumber") as HTMLInputElement).value);
        const description = (document.getElementById("floatingDescription") as HTMLInputElement).value;
        if (!code || !description) {
            // כאן ניתן להוסיף הודעת שגיאה או טיפול נוסף בהתאם לדרישות
            return;
        }
        // בדיקה האם המספר קוד כבר קיים ברשימת הקודים
        const isCodeExists = selectionArray.some(item => item.code === code) || (invoices.some((item: { code?: number }) => item?.code === code));

        if (isCodeExists) {
            // מספר הקוד כבר קיים, יש להוסיף טיפול נוסף בהתאם
            alert("מספר הקוד כבר קיים");
            return;
        }
        try {
            await addCustomItemToProject(projectName, { code, description });
        } catch (error) {
            console.error(error);
            // יש להוסיף טיפול נוסף בהתאם לשגיאה שקרתה בזמן הבקשה
        }
        newInvoiceNumber(code);
        refresh();
    };

    const newInvoiceNumber = async (code: number) => {
        const newInvoice = {
            managerName: manager?.name as string, code: code, description: selectedDescription as string, notes: selectedNote as string
        };
        addInvoice({ ...newInvoice })
            .then((res) => {
                refresh();
                successMsg("חשבונית נוספה בהצלחה");
            })
            .catch((err) => console.log(err));
    }

    const NextSequentialNumber = () => {
        const maxInvoiceCode = getMaxCode();
        const nextNumber = Math.max(maxInvoiceCode, 1120) + 1;
        return nextNumber;
    };

    const getMaxCode = () => {
        const existingCodes = invoices.map((invoice) => invoice.code);
        const maxCode = Math.max(...existingCodes);
        return maxCode;
    };

    const newDescription = (event: React.ChangeEvent<HTMLSelectElement>) => {
        const description = event.target.value;
        setSelectedDescription(description)
    }
    const newNote = (event: React.ChangeEvent<HTMLSelectElement>) => {
        const note = event.target.value;
        setSelectedNote(note)
    }


    return (
        <div className="editorWorkPanel">
            <div className="row editorWorkHeadline mb-2">
                <img src="/Monica_Logo@0.25x.png" alt="Logo" style={{ height: '60px', width: '200px' }} />
                <h1>בניית תקציב</h1>
            </div>
            <h1>{projectName}</h1>
            <div className="mx-auto col-12 col-md-8 col-lg-6 mt-2">
                <div className="form-floating row mb-4">
                    <div className="col-2 pt-1">
                        <label htmlFor="floatingEmail">הוספת סעיף חדש:</label>
                    </div>
                    <div className="col-2">
                        <input
                            type="number"
                            className="form-control"
                            id="floatingNumber"
                            name="code"
                            placeholder="מס׳ סעיף"
                            value={NextSequentialNumber()}
                        />
                    </div>
                    <div className="col-3">
                        <input type="text" className="form-control" id="floatingDescription" name="text" placeholder="שם סעיף" onChange={(event: React.ChangeEvent<any>) => newDescription(event)} />
                    </div>
                    <div className="col-3">
                        <input type="text" className="form-control" id="floatingNotes" name="text" placeholder="הערות" onChange={(event: React.ChangeEvent<any>) => newNote(event)} />
                    </div>
                    <div className="col-2">
                        <button
                            className="btn btn-info btn-sm" onClick={addCustomItem}>
                            הוספה
                        </button>
                    </div>
                </div>
            </div>
            <div className="row mt-4">
                <div className="col-3"><h3>סה״כ תקציב: {project?.budget}</h3></div>
                <div className="col-3"><h3>בפועל: {sumBudgetSoFar(project?.totalEditShifts as number)}</h3></div>
                <div className="col-3"><h3>יתרה: {project && project.budget && project.totalEditShifts ? (project.budget as number - sumBudgetSoFar(project?.totalEditShifts as number)) : 0}</h3>
                </div>
                <div className="col-3"><h3>מצב תזרום:</h3></div>
            </div>
            <div className="row mt-2">
                <div className="row" style={{ overflowX: "auto" }}>
                    <table className="table table-striped mt-3">
                        <thead>
                            <tr className="table-secondary">
                                <th></th>
                                <th style={{ position: 'sticky', right: '0px' }} scope="col">פוסט אולפן</th>
                                <th></th>
                                <th></th>
                                <th></th>
                                <th></th>
                                <th></th>
                                <th></th>
                                <th></th>
                                <th></th>
                                <th></th>
                                <th></th>
                                <th className="text-center" scope="col">ינואר 24</th>
                                <th className="separator"></th>
                                <th></th>
                                <th className="text-center" scope="col">פברואר 24</th>
                                <th className="separator"></th>
                                <th></th>
                                <th className="text-center" scope="col">מרץ 24</th>
                                <th className="separator"></th>
                                <th></th>
                                <th className="text-center" scope="col">אפריל 24</th>
                                <th className="separator"></th>
                                <th></th>
                                <th className="text-center" scope="col">מאי 24</th>
                                <th className="separator"></th>
                                <th></th>
                                <th className="text-center" scope="col">יוני 24</th>
                                <th className="separator"></th>
                                <th></th>
                                <th className="text-center" scope="col">יולי 24</th>
                                <th className="separator"></th>
                                <th></th>
                                <th className="text-center" scope="col">אוגוסט 24</th>
                                <th className="separator"></th>
                                <th></th>
                                <th className="text-center" scope="col">ספטמבר 24</th>
                                <th className="separator"></th>
                                <th></th>
                                <th className="text-center" scope="col">אוקטובר 24</th>
                                <th className="separator"></th>
                                <th></th>
                                <th className="text-center" scope="col">נובמבר 24</th>
                                <th className="separator"></th>
                                <th></th>
                                <th className="text-center" scope="col">דצמבר 24</th>
                                <th></th>
                            </tr>
                        </thead>
                        <thead>
                            <tr className="table-info">
                                <th style={{ position: 'sticky', right: '0px' }} scope="col">סעיף</th>
                                <th style={{ position: 'sticky', right: '53px' }} scope="col">שם סעיף</th>
                                <th scope="col" style={{ width: '10vw' }}>הערות</th>
                                <th scope="col">עלות</th>
                                <th scope="col">כמות</th>
                                <th scope="col">תקופה</th>
                                <th scope="col">יח׳</th>
                                <th scope="col">סה״כ מתוקצב</th>
                                <th scope="col">בפועל</th>
                                <th scope="col">יתרה בסעיף</th>
                                <th scope="col">תזרים</th>
                                <th className="text-center" scope="col">תקציב</th>
                                <th className="text-center" scope="col">בפועל</th>
                                <th className="separator text-center" scope="col">יתרה</th>
                                <th className="text-center" scope="col">תקציב</th>
                                <th className="text-center" scope="col">בפועל</th>
                                <th className="separator text-center" scope="col">יתרה</th>
                                <th className="text-center" scope="col">תקציב</th>
                                <th className="text-center" scope="col">בפועל</th>
                                <th className="separator text-center" scope="col">יתרה</th>
                                <th className="text-center" scope="col">תקציב</th>
                                <th className="text-center" scope="col">בפועל</th>
                                <th className="separator text-center" scope="col">יתרה</th>
                                <th className="text-center" scope="col">תקציב</th>
                                <th className="text-center" scope="col">בפועל</th>
                                <th className="separator text-center" scope="col">יתרה</th>
                                <th className="text-center" scope="col">תקציב</th>
                                <th className="text-center" scope="col">בפועל</th>
                                <th className="separator text-center" scope="col">יתרה</th>
                                <th className="text-center" scope="col">תקציב</th>
                                <th className="text-center" scope="col">בפועל</th>
                                <th className="separator text-center" scope="col">יתרה</th>
                                <th className="text-center" scope="col">תקציב</th>
                                <th className="text-center" scope="col">בפועל</th>
                                <th className="separator text-center" scope="col">יתרה</th>
                                <th className="text-center" scope="col">תקציב</th>
                                <th className="text-center" scope="col">בפועל</th>
                                <th className="separator text-center" scope="col">יתרה</th>
                                <th className="text-center" scope="col">תקציב</th>
                                <th className="text-center" scope="col">בפועל</th>
                                <th className="separator text-center" scope="col">יתרה</th>
                                <th className="text-center" scope="col">תקציב</th>
                                <th className="text-center" scope="col">בפועל</th>
                                <th className="separator text-center" scope="col">יתרה</th>
                                <th className="text-center" scope="col">תקציב</th>
                                <th className="text-center" scope="col">בפועל</th>
                                <th className="separator text-center" scope="col">יתרה</th>
                            </tr>
                        </thead>
                        <tbody>
                            {invoices.sort((a, b) => a.code - b.code).map((invoice, index) => {
                                const budget = budgets.find(budget => budget.invoiceId === invoice._id);
                                return (
                                    <tr key={index}>
                                        <td style={{ position: 'sticky', right: '0px' }}>{invoice.code}</td>
                                        <td style={{ position: 'sticky', right: '53px', whiteSpace: 'nowrap' }}>{invoice.description}</td>
                                        <td>{invoice.notes}</td>
                                        <td> <input type="number" className="input-sm" id={`floatingCost-${index}`} name="cost"
                                            style={inputStyles} value={budget?.cost} onChange={handleCostChangeForRow(invoice._id as string)} /></td>
                                        <td> <input type="number" className="input-sm" id={`floatingAmount-${index}`} name="amount"
                                            style={inputStyles} value={budget?.amount} onChange={handleAmountChangeForRow(invoice._id as string)} /> </td>
                                        <td>  <select
                                            value={selectedPeriods[invoice._id as string] || ""}
                                            onChange={handlePeriodChange(invoice._id as string)}
                                            className="input-sm"
                                            style={inputStyles}
                                        >
                                            <option value="כללי">כללי</option>
                                            <option value="גלובלי">גלובלי</option>
                                            <option value="יחידה">יחידה</option>
                                            <option value="חודש">חודש</option>
                                            <option value="פרק">פרק</option>
                                            <option value="משמרת">משמרת</option>
                                            <option value="אחר">אחר</option>
                                        </select> </td>
                                        <td><input
                                            type="number"
                                            className="input-sm"
                                            id={`floatingUnits-${index}`}
                                            name="units"
                                            style={inputStyles}
                                            value={budget?.units}
                                            onChange={handleUnitsChangeForRow(invoice._id as string)}
                                        /></td>
                                        <td> <input type="number" className="input-sm" id="floatingTotal" name="total" style={inputStyles} value={budget?.total} readOnly /></td>
                                        <td> <input
                                            type="number"
                                            className="input-sm"
                                            id={`floatingActual-${index}`}
                                            name="actual"
                                            style={inputStyles}
                                            value={budget?.actual}
                                            onChange={handleActualChangeForRow(invoice._id as string)}
                                        /></td>
                                        <td style={budget && budget.whatsLeft !== undefined && budget.whatsLeft < 0 ? { direction: 'ltr' } : {}}>
                                            <input
                                                type="number"
                                                className="input-sm"
                                                id="floatingBalance"
                                                name="balance"
                                                style={inputStyles}
                                                value={budget?.whatsLeft || 0}
                                                readOnly
                                            />
                                        </td>
                                        <td className="separator" style={budget && budget.flow !== undefined && budget.flow < 0 ? { direction: 'ltr' } : {}}>
                                            <input
                                                type="number"
                                                className="input-sm"
                                                id="floatingFlow"
                                                name="flow"
                                                style={inputStyles}
                                                value={budget?.flow || 0}
                                                readOnly
                                            />
                                        </td>
                                        <td><input type="number" className="input-sm" style={inputStyles} value={budget?.january ? budget.january[0] : undefined} onChange={(e) => handleMonthChanges(budget?.january || [], budget?._id as string, "january")} /></td>
                                        <td><input type="number" className="input-sm" style={inputStyles} value={budget?.january ? budget.january[1] : undefined} onChange={(e) => handleMonthChanges(budget?.january || [], budget?._id as string, "january")} /></td>
                                        <td className="separator"><input type="number" className="input-sm" style={inputStyles} value={budget?.january ? budget.january[2] : 0} readOnly /></td>
                                        <td><input type="number" className="input-sm" style={inputStyles} />{budget?.february}</td>
                                        <td><input type="number" className="input-sm" style={inputStyles} />{budget?.february}</td>
                                        <td className="separator"><input type="number" className="input-sm" style={inputStyles} />{budget?.february}</td>
                                        <td><input type="number" className="input-sm" style={inputStyles} />{budget?.march}</td>
                                        <td><input type="number" className="input-sm" style={inputStyles} />{budget?.march}</td>
                                        <td className="separator"><input type="number" className="input-sm" style={inputStyles} />{budget?.march}</td>
                                        <td><input type="number" className="input-sm" style={inputStyles} />{budget?.april}</td>
                                        <td><input type="number" className="input-sm" style={inputStyles} />{budget?.april}</td>
                                        <td className="separator"><input type="number" className="input-sm" style={inputStyles} />{budget?.april}</td>
                                        <td><input type="number" className="input-sm" style={inputStyles} />{budget?.may}</td>
                                        <td><input type="number" className="input-sm" style={inputStyles} />{budget?.may}</td>
                                        <td className="separator"><input type="number" className="input-sm" style={inputStyles} />{budget?.may}</td>
                                        <td><input type="number" className="input-sm" style={inputStyles} />{budget?.june}</td>
                                        <td><input type="number" className="input-sm" style={inputStyles} />{budget?.june}</td>
                                        <td className="separator"><input type="number" className="input-sm" style={inputStyles} />{budget?.june}</td>
                                        <td><input type="number" className="input-sm" style={inputStyles} />{budget?.july}</td>
                                        <td><input type="number" className="input-sm" style={inputStyles} />{budget?.july}</td>
                                        <td className="separator"><input type="number" className="input-sm" style={inputStyles} />{budget?.july}</td>
                                        <td><input type="number" className="input-sm" style={inputStyles} />{budget?.august}</td>
                                        <td><input type="number" className="input-sm" style={inputStyles} />{budget?.august}</td>
                                        <td className="separator"><input type="number" className="input-sm" style={inputStyles} />{budget?.august}</td>
                                        <td><input type="number" className="input-sm" style={inputStyles} />{budget?.september}</td>
                                        <td><input type="number" className="input-sm" style={inputStyles} />{budget?.september}</td>
                                        <td className="separator"><input type="number" className="input-sm" style={inputStyles} />{budget?.september}</td>
                                        <td><input type="number" className="input-sm" style={inputStyles} />{budget?.october}</td>
                                        <td><input type="number" className="input-sm" style={inputStyles} />{budget?.october}</td>
                                        <td className="separator"><input type="number" className="input-sm" style={inputStyles} />{budget?.october}</td>
                                        <td><input type="number" className="input-sm" style={inputStyles} />{budget?.november}</td>
                                        <td><input type="number" className="input-sm" style={inputStyles} />{budget?.november}</td>
                                        <td className="separator"><input type="number" className="input-sm" style={inputStyles} />{budget?.november}</td>
                                        <td><input type="number" className="input-sm" style={inputStyles} />{budget?.december}</td>
                                        <td><input type="number" className="input-sm" style={inputStyles} />{budget?.december}</td>
                                        <td><input type="number" className="input-sm" style={inputStyles} />{budget?.december}</td>
                                    </tr>
                                );
                            })}
                        </tbody>
                    </table>
                </div>
            </div>
            <div className="row empsSalRepOptions mx-auto col-10 col-md-8 col-lg-6">
                <p onClick={() => navigate(-1)} style={{
                    color: 'blue',
                    textDecoration: 'underline',
                    cursor: 'pointer',
                }}>חזור</p>
                <p style={{ color: 'lightgray' }}>Almog Liebermensch 2024 ©</p>
            </div>
        </div >
    );
}

export default BudgetBuilding;